@keyframes pulse_animation {
    0% { transform: scale(1); }
    25% { transform: scale(1.05); }
    50% { transform: scale(1); }
    75% { transform: scale(1.05); }
    100% { transform: scale(1); }
}

.pulses {
    @include flex-column;
    margin: 50px auto;
    flex-direction: column-reverse;

    @include breakpoint('md') {
        @include flex-between;
        max-width: 1100px;
        padding: 0 20px;
        margin: 40px auto;
        flex-direction: row;
    }

    &-main {
        padding: 0 10px;

        @include breakpoint('md') {
            width: calc(100% - 250px);
        }

        &-header {
            padding-top: 20px;
            margin: 0;
            border-top: 3px solid $sprout-blue;

            @include breakpoint('md') {
                @include flex-between-center;
                margin-top: 25px;
                border: none;
            }

            h2 {
                font-family: $fontweight-semibold;
                margin: 0;
            }
        }

        &-body {
            @include flex-between;
            margin-top: 20px;

            &-content {
                width: 100%;

                &-weeks {
                    @include flex-column;
                }

                &-trends {
                    @include flex-center-all;

                    &-user {
                        width: 300px;
                    }
                }

                &-analytics {
                    section {
                        margin-bottom: 20px;
                    }
                }

                &-table {
                    width: 100%;
                    text-align: left;
                    border-collapse: collapse;
                    border: 1px solid $sprout-dark-gray;
                    margin: 20px 0;
                    color: $sprout-font-gray;

                    th {
                        background-color: $sprout-blue;
                        color: white;
                    }

                    tr:nth-child(odd) {
                        background-color: white;
                    }

                    th,
                    td {
                        padding: 10px 5px;
                    }

                    button {
                        background-color: $sprout-gold;
                        color: white;
                        border: none;
                        height: 40px;
                        padding: 0 5px;
                        cursor: pointer;

                        &:hover {
                            background-color: $sprout-dark-gold;
                        }
                    }
                }
            }
        }
    }

    &-side {
        width: 100%;
        margin-top: 0px;
        align-items: center;
        @include flex-column;

        @include breakpoint('md') {
            width: 200px;
            margin-top: 40px;
        }

        &-panels {
            width: 90%;

            &-option {
                margin: 20px 0;
                background-color: $sprout-gray-2;

                header {
                    font-family: $fontweight-semibold;
                    background-color: $sprout-blue;
                    color: white;
                    padding: 6px 5px;
                }

                &-content {
                    @include flex-column;
                    align-items: center;
                    padding: 10px 5px;

                    select {
                        width: 175px;
                        margin-bottom: 5px;
                        padding: 2px;
                    }

                    button {
                        width: 140px;

                        a {
                            text-decoration: none;
                            color: white;
                        }
                    }

                    p {
                        margin: 0 0 8px 0;
                    }

                    &-missing {
                        ul {
                            margin: 0;
                            list-style: none;
                            padding: 0;

                            li {
                                @include flex-between;
                                width: 175px;
                                margin-bottom: 5px;
                                background-color: white;
                                color: $sprout-red;
                                padding: 2px 5px;

                                p {
                                    margin: 0;
                                }
                            }
                        }

                        &-email {
                            color: $sprout-blue;
                            max-width: 25px;
                            padding: 0;
                            background: none;
                            border: none;

                            &:hover {
                                color: goldenrod;
                                cursor: pointer;
                            }

                            &:disabled {
                                color: grey;
                                cursor: default;
                            }
                        }
                    }
                }
            }
        }

        &-link {
            @include flex-center-all;
            color: white;
            background-color: $sprout-blue;
            border-radius: 50%;
            min-height: 50px;
            min-width: 50px;
            font-size: 25px;
            margin: 0 5px;

            &:hover {
                cursor: pointer;
                background-color: goldenrod;
                font-size: 35px;
            }
        }
    }

    &-form {
        width: 100%;
        padding: 0 10px;

        header {
            @include flex-between-center;
            text-align: left;
            padding-bottom: 10px;
            margin: 10px 0;
            border-bottom: 2px solid $sprout-blue;

            h3 {
                margin: 5px 0 2px 0;
            }
        }

        &-survey {
            &-pulse {
                @include flex-column;
                align-items: center;

                h4 {
                    margin: 10px;
                }

                &-question {
                    flex-direction: column;
                    width: 100%;

                    @include breakpoint('md') {
                        @include flex-between-center;
                    }

                    &-scores {
                        margin: 10px 30px;
                        align-items: center;
                        width: 80%;

                        button {
                            // align-items: center;
                            width: 100%;
                            height: 40px;
                            border-radius: 1em;
                            margin: 5px 0;

                            @include breakpoint('md') {
                                width: 50px;
                                height: 50px;
                                border-radius: 50%;
                                border: 1px solid black;
                                margin: 0 3px;
                                cursor: pointer;
                            }

                            &:hover {
                                animation-name: pulse_animation;
                                animation-duration: 2000ms;
                                transform-origin: 50% 50%;
                                animation-iteration-count: infinite;
                                animation-timing-function: linear;
                            }
                        }

                        .score1 {

                            &:hover,
                            &-active {
                                color: #A80000;
                                border-color: #A80000;
                            }

                            &-active {
                                color: white;
                                background-color: #A80000;
                                font-size: 20px;
                            }
                        }

                        .score2 {

                            &:hover,
                            &-active {
                                color: #D22000;
                                border-color: #D22000;
                            }

                            &-active {
                                color: white;
                                background-color: #D22000;
                                font-size: 20px;
                            }
                        }

                        .score3 {

                            &:hover,
                            &-active {
                                color: #E64D00;
                                border-color: #E64D00;
                            }

                            &-active {
                                color: white;
                                background-color: #E64D00;
                                font-size: 20px;
                            }
                        }

                        .score4 {

                            &:hover,
                            &-active {
                                color: #FF6F00;
                                border-color: #FF6F00;
                            }

                            &-active {
                                color: white;
                                background-color: #FF6F00;
                                font-size: 20px;
                            }
                        }

                        .score5 {

                            &:hover,
                            &-active {
                                color: #FF9100;
                                border-color: #FF9100;
                            }

                            &-active {
                                color: white;
                                background-color: #FF9100;
                                font-size: 20px;
                            }
                        }

                        .score6 {

                            &:hover,
                            &-active {
                                color: #FFD500;
                                border-color: #FFD500;
                            }

                            &-active {
                                color: white;
                                background-color: #FFD500;
                                font-size: 20px;
                            }
                        }

                        .score7 {

                            &:hover,
                            &-active {
                                color: #B4E100;
                                border-color: #B4E100;
                            }

                            &-active {
                                color: white;
                                background-color: #B4E100;
                                font-size: 20px;
                            }
                        }

                        .score8 {

                            &:hover,
                            &-active {
                                color: #77D109;
                                border-color: #77D109;
                            }

                            &-active {
                                color: white;
                                background-color: #77D109;
                                font-size: 20px;
                            }
                        }

                        .score9 {

                            &:hover,
                            &-active {
                                color: #43B000;
                                border-color: #43B000;
                            }

                            &-active {
                                color: white;
                                background-color: #43B000;
                                font-size: 20px;
                            }
                        }

                        .score10 {

                            &:hover,
                            &-active {
                                color: #1B9F00;
                                border-color: #1B9F00;
                            }

                            &-active {
                                color: white;
                                background-color: #1B9F00;
                                font-size: 20px;
                            }
                        }
                    }
                }
            }

            &-reason {
                textarea {
                    width: 99.3%;
                    min-height: 75px;
                    border: $sprout-blue 1px solid;
                    font: $fontweight-regular;
                }
            }
        }

        &-submit {
            display: flex;
            align-items: center;
            margin: 10px 0;

            &-success {
                color: green;
            }

            &-failed {
                color: red;
            }

            button {
                border: 1px solid white;
                color: white;
                background-color: $sprout-blue;
                width: 150px;
                height: 30px;
                margin-right: 20px;
                cursor: pointer;

                &:hover:enabled {
                    border: 1px solid $sprout-blue;
                    color: $sprout-blue;
                    background-color: white;
                    cursor: pointer;
                }

                &:disabled {
                    background-color: green;
                    border: 1px solid green;
                }
            }
        }
    }
}

.pulses-card {
    display: flex;

    div {
        @include flex-center-all;
        font-family: $fontweight-bold;
    }

    &-score {
        background-color: goldenrod;
        color: white;
        width: 30px;
        height: 30px;
        font-size: 22px;
    }

    &-review {
        width: 20px;
        height: 20px;
        font-size: 14px;
        margin: 5px 5px 0 0;

        &-check {
            color: validation-font-green;
        }

        &-times {
            color: $sprout-red;
        }
    }
}