.sessions {
    // Sessions List Styles
    &-header {
        @include flex-space-between;
        color: $dark-grey;
        text-align: left;
        width: 85vw;
        max-width: 1366px;
        &.create-edit {
            width: 89vw;
            margin: 10px 0px;
            display: flex;
            justify-content: space-between;
        }
        h3 {
            font-size: 32px;
            margin: 0px;
        }
        &-h3 {
            font-size: 32px;
            margin: 0px;
            color: $dark-grey;
        }
    }
    &-search-wrap {
        display: flex;
        justify-content: space-between;
        width: 85vw;
        max-width: 1366px;
        margin-top: 20px;
    }
    &-search-buttons-wrap {
        margin-top: 20px;
        display: grid;
        grid-template-columns: 2fr 1fr 1.5fr 0.5fr;
        width: 85vw;
        max-width: 1366px;
        a {
            display: flex;
            justify-self: flex-end;
            text-decoration: none;
        }
    }
    &-search-button, &-new-button {
        display: flex;
        align-self: center;
        justify-content: center;
        height: 30px;
        background-color: $primary-color;
        color: white;
        font-size: 16px;
        text-decoration: none;
        outline: none;
        &:hover {
            cursor: pointer;
        }
    }
    &-search-button {
        width: 150px;
        justify-self: center;
    }
    &-search-toggle {
        @include flex-content-center;
    }
    &-new-button {
        width: 100px;
        justify-self: right;
        text-decoration: none;
        border: none;
        align-items: center;
        &.details {
            margin: 0px 5px;
        }
        &.invite {
            width: auto;
            height: auto;
            padding: 8px 10px;
        }
    }
    &-cards-wrap {
        width: 85vw;
        max-width: 1366px;
        margin-top: 15px;
    }
    &-card {
        display: grid;
        grid-template-columns: 10px 1.5fr 1.5fr 1fr 1fr 10px;
        margin: 20px 0px;
        height: 60px;
        width: 100%;
        justify-self: center;
        box-shadow: -5px 5px 16px #00000018;
        text-decoration: none;
        transition: 0.2s ease-in-out;
        section {
            display: flex;
            align-self: center;
            text-align: left;
            margin: 0;
            color: #707070;
            font-family: $whitney-bold;
            grid-column: 2/3;
        }
        div {
            display: flex;
            align-items: center;
            color: #707070;
        }
        &-admin {
            justify-content: flex-end;
        }
        &-admin-icon {
            margin-right: 5px;
            color: $primary-color;
            font-size: 20px;
        }
        &:hover {
            box-shadow: -5px 5px 16px #00000038;
        }
    }

    // Create/Edit Session Styles
    &-create-edit-form {
        width: 85vw;
        max-width: 1366px;
        margin-top: 25px;
        box-shadow: -5px 5px 12px rgba(0, 0, 0, 0.15);
        border: 2px solid $primary-color;
    }
    &-create-back-button {
        display: flex;
        justify-content: space-between;
        width: 85vw;
        max-width: 1366px;
        margin-top: 5px;
    }
    &-title-date {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        background-color: $primary-color;
        color: white;
        min-height: 60px;
    }
    &-title {
        display: flex;
        align-items: center;
        grid-column: 2/3;
        label {
            font-size: 1.3em;
            margin-right: 10px;
            margin-bottom: 2px;
        }
        &-input {
            border: 0;
            border-bottom: 2px solid white;
            color: white;
            background-color: $primary-color;
            width: 100%;
            font-size: 1.5em;
            outline: none;
            font-family: $whitney-bold;
            &::placeholder {
                color: white;
            }
        }
        &.details {
            display: flex;
            justify-content: center;
            font-size: 1.4em;
            font-family: $whitney-bold;
        }
    }
    &-date {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 10px;
        font-size: 1.2em;
        &-input {
            border: 0;
            border-bottom: 2px solid white;
            color: white;
            background-color: $primary-color;
            width: 100%;
            font-size: .9em;
            outline: none;
            font-family: $whitney-bold;
            &::placeholder {
                color: white;
            }
        }
        label {
            margin-right: 5px;
        }
    }
    &-dropdown-inputs {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 10px;
        margin-top: 25px;
        padding: 0px 20px;
        aside {
            display: flex;
            align-items: center;
            width: 100%;
            label {
                margin-right: 10px;
                color: $primary-color;
            }
        }
    }
    &-create-input-wrap {
        width: 100%;
    }
    &-date-inputs-wrap {
        @include flex-space-around;
        margin-top: 25px;
        aside {
            display: flex;
            align-items: center;
            label {
                margin-right: 10px;
                color: $primary-color;
            }
        }
    }
    &-settings-inputs-wrap {
        display: grid;
        grid-template-rows: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
        grid-row-gap: 10px;
        margin-top: 25px;
        aside {
            @include flex-content-center;
        }
    }
    &-options-wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    &-options {
        margin-top: 10px;
        width: 100%;
        label {
            margin: 0px 3px;
        }
        &-label-title {
            color: $primary-color;
        }
    }
    &-create-edit-button {
        margin-top: 20px;
        padding: 5px 10px;
        background-color: $primary-color;
        border: none;
        color: white;
        font-size: 16px;
        outline: none;
        margin-bottom: 4px;
        &:hover {
            cursor: pointer;
        }
    }
    //Sessions Details Styles
    &-details-form {
        width: 85vw;
        max-width: 1366px;
        margin-top: 25px;
        margin-bottom: 15px;
        box-shadow: -5px 5px 12px rgba(0, 0, 0, 0.15);
        border: 2px solid $primary-color;
    }
    &-participant-count {
        display: flex;
        align-items: center;
        font-size: 1.2em;
        padding-left: 10px;
        font-weight: bold;
        div {
            margin-left: 5px;
        }
    }
    &-details-info {
        padding: 12px;
        text-align: left;
        aside {
            margin: 10px 0px;
            span:nth-child(1) {
                font-size: 1.3em;
                color: $primary-color;
                margin-right: 5px;
            }
            span:nth-child(2) {
                font-size: 1.1em;
                color: $dark-grey;
            }
        }
    }
    &-last-modified {
        color: $grey;
        padding-bottom: 2px;
    }
    &-select {
        &-wrap {
            text-align: left;
            margin-left: 25px;
            font-size: 1.3em;
            color: #346596
        }
        min-width: 200px;
        font-size: 14px;
        color: $primary-color;
    }
}