@use "sass:map";

/// Make a context based selector a little more friendly
@mixin when-inside($context) {
    #{$context} & {
        @content;
    }
}

/// Responsive breakpoint manager
@mixin breakpoint($breakpoint) {
    $raw-query: map-get($breakpoints, $breakpoint);
    @if $raw-query {
        $query: if(
            type-of($raw-query) == 'string',
            unquote($raw-query),
            inspect($raw-query)
        );

        @media screen and (min-width: #{$query}) {
            @content;
        }
    } @else {
        @error 'No value found for `#{$breakpoint}`. '
        + 'Please make sure it is defined in `$breakpoints` map.';
    }
}

/// Helper to size an element
@mixin size($width, $height: $width) {
    width: $width;
    height: $height;
}

@mixin size-max($width, $height: $width) {
    max-width: $width;
    max-height: $height;
}

/// Mixin helper to output vendor prefixes
@mixin prefix($property, $value, $prefixes: ()) {
    @each $prefix in $prefixes {
        -#{$prefix}-#{$property}: $value;
    }
    #{$property}: $value;
}

@mixin font-size($fs-map, $fs-breakpoints: $breakpoints) {
    @each $fs-breakpoint, $fs-font-size in $fs-map {
      // No breakpoint, default size
        @if $fs-breakpoint == null {
            @include make-font-size($fs-font-size);
        } @else {
            // If $fs-font-size is a key that exists in
            // $fs-breakpoints, use the value
            @if map-has-key($fs-breakpoints, $fs-breakpoint) {
                $fs-breakpoint: map-get($fs-breakpoints, $fs-breakpoint);
            }
            @media screen and (min-width: #{$fs-breakpoint}) {
                @include make-font-size($fs-font-size);
            }
        }
    }
}

// Utility function for mixin font-size
@mixin make-font-size($fs-font-size) {
    // If $fs-font-size is a list, include
    // both font-size and line-height
    @if type-of($fs-font-size) == "list" {
        font-size: nth($fs-font-size, 1);
        @if (length($fs-font-size) > 1) {
            line-height: nth($fs-font-size, 2);
        }
    }
    @else {
        font-size: $fs-font-size;
    }
}

// flex
@mixin flex-between {
    display: flex;
    justify-content: space-between;
}

@mixin flex-center {
    display: flex;
    justify-content: center;
}

@mixin flex-center-all {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-between-center {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@mixin flex-between-center-column {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
}

@mixin absolute-cover {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}

@mixin flex-align-start-column {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

@mixin flex-align-center-column {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

@mixin flex-start-center {
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

@mixin flex-self-center {
    display: flex;
    justify-self: center;
    align-self: center;
}

@mixin flex-space-around {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

@mixin flex-wrap {
    display: flex;
    flex-wrap: wrap;
}

@mixin flex-column {
    display: flex;
    flex-direction: column;
}

// spacing
@mixin padding-section($top-type) {
    @if $top-type == 'noheader' {
        padding: 50px 10px 20px 10px;
        @include breakpoint('sm'){
            padding: 50px 34px 20px 34px;
        }
        @include breakpoint('md'){
            padding: 50px 34px 34px;
        }
    } @else if($top-type == 'subsection'){
        padding: 0px 10px 20px 10px;
        @include breakpoint('sm'){
            padding: 0px 34px 34px;
        }
    } @else {
        padding: 20px 10px;
        @include breakpoint('sm'){
            padding: 34px;
        }
    }
}

@mixin analytics-card-style {
    grid-row: 10/14;
    background-color: white;
    color: gray;
    box-shadow: -5px 5px 14px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    padding: 20px;
    transition: 0.3s ease-in-out;
    text-decoration: none;
    svg {
        grid-row: 1/3;
    }
    header {
        font-size: 1.7vw;
        grid-row: 3/4;
    }
    p {
        margin: 0;
        font-size: 1vw;
        font-family: Arial, Helvetica, sans-serif;
        grid-row: 4/5;
    }
    &:hover {
        box-shadow: -10px 10px 14px rgba(0, 0, 0, .6);
        cursor: pointer;
    }
}
