//NavBar
.digital-foundations-navbar {
    @include flex-between;
    align-items: center;
    max-width: 700px;
    width: 100%;
    height: 76px;

    &-wrap {
        display: flex;
        justify-content: center;
        width: 100vw;
        background-color: $sprout-blue;
        position: fixed;
        z-index: 2;
    }

    &-item {
        color: white;
        font-size: 16px;
        cursor: pointer;
        text-decoration: none;
        font-family: $fontweight-bold;
        padding: 5px 0;

        &-disabled {
            color: grey;
            font-size: 16px;
            text-decoration: none;
            font-family: $fontweight-medium;
            cursor: default;
        }

        &-active {
            color: $sprout-gold;

            @include breakpoint('md') {
                color: white;
                font-size: 16px;
                font-family: $fontweight-bold;
                text-decoration: none;
                border-bottom: 2px solid white;
            }
        }
    }

    &-admin {
        @include flex-space-around;
        max-width: 700px;
        width: 100%;

        &-wrap {
            @include flex-center;
            background-color: $sprout-dark-gray-3;
            position: absolute;
            height: 50px;
            width: 100vw;
            bottom: -50px;
            left: 0;
            font-size: 16px;
        }
    }
}

//Digital Bursts
.bursts {
    &-home-wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 50px 0px 25px 0px;
    }

    &-wrap {
        margin-top: 50px;

        @include breakpoint('lg') {
            flex-direction: column;
            margin: 100px 0 25px;
            width: 80vw;
        }

        @include breakpoint('xl') {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
            height: 100%;
            width: 70vw;
            max-width: 1120px;
            margin: 100px 0px 50px 0px;
        }

        article,
        section {
            text-align: left;
        }

        article {
            width: 95vw;

            @include breakpoint('lg') {
                width: 80vw;
            }

            div {
                display: flex;
                align-items: center;
            }

            header {
                font-size: 20px;
                color: $sprout-font-gray;
            }

            img {
                margin-left: 15px;
            }

            h1 {
                font-family: $fontweight-bold;
                color: $sprout-dark-gray-4;
                font-size: 37px;
                margin: 15px 0px;
            }

            h2 {
                font-family: $fontweight-bold;
                color: $sprout-blue;
                font-size: 22px;
            }

            p,
            ul,
            ol {
                font-weight: $fontweight-medium;
                color: $sprout-font-gray;
                line-height: 28px;
            }

            li {
                margin: 10px 0px;
            }

            span {
                font-weight: $fontweight-bold;
                color: $sprout-blue;
                margin: 0px 5px 0px 10px;
                height: 100%;
                line-height: 28px;
            }

            p {
                font-weight: $fontweight-medium;
                margin: 0;
            }

            aside {
                display: grid;
                grid-template-columns: .03fr .97fr;
                margin: 15px 0px;
            }

            h3 {
                font-size: 16px;
                color: $fontweight-semibold;
                font-family: $fontweight-semibold;
                font-style: italic;
                font-weight: 300;
            }
        }

        hr {
            border-bottom: solid 2 px $sprout-dark-gray-2;

            @include breakpoint('xl') {
                display: none;
            }
        }

        section {
            width: 95vw;
            align-items: center;
            display: flex;
            flex-direction: column;

            @include breakpoint('lg') {
                width: 80vw;
            }

            @include breakpoint('xl') {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
            }

            aside,
            span {
                font-size: 14px;
                color: $sprout-font-gray;
            }

            aside {
                font-family: $fontweight-semibold;
                margin: 8px 5px 2px 5px;
            }

            span {
                font-family: $fontweight-semibold;
                font-style: italic;
                margin: 5px;
            }
        }
    }

    &-resources-wrap {
        @include flex-between;
        flex-direction: column;
        width: 720px;
        align-items: center;

        @include breakpoint('xl') {
            width: auto;
        }
    }

    &-timeframe {
        font-size: 14px;
    }

    &-activity-list {
        padding-left: 75px;
    }

    &-files {
        display: flex;
        justify-content: space-between;
        width: 350px;
        margin-top: 20px;

        div {
            a {
                @include flex-self-center;
                width: 170px;
                height: 170px;
                background-color: $sprout-gray;
                text-decoration: none;
                color: black;
            }
        }

        &.admin {
            margin-top: 0;
            margin-bottom: 25px;
        }
    }

    &-resources-step {
        font-family: $fontweight-bold;
        color: $sprout-blue;
        margin: 10px 0px 10px 0px;

        &.admin {
            margin-top: 0;
        }
    }

    &-tool-title,
    &-tool-type {
        margin: 5px;
        font-size: 14px;
        color: $sprout-font-gray;
    }

    &-tool-title {
        font-family: $fontweight-semibold;
        margin-bottom: 0px;
    }

    &-tool-type {
        font-family: $fontweight-semibold;
        font-style: italic;
        margin-top: 3px;
    }

    &-error {
        @include flex-self-center;
        height: 100%;
        flex-direction: column;
        width: 50vw;
    }

    &-completionButton {
        background-color: $sprout-blue;
        color: white;
        padding: 8px 12px;
        font-size: 16px;
        cursor: pointer;

        &:disabled {
            background-color: green;
            cursor: unset;
        }
    }

    &-admin-invite-buttons-wrap {
        display: flex;
    }

    &-admin-invite-button {
        background-color: $sprout-blue;
        color: white;
        font-size: 18px;
        font-family: $fontweight-bold;
        border: none;
        padding: 5px 20px;
        margin: 15px 5px 0px 5px;
        outline: none;
        width: 150px;

        &:hover {
            cursor: pointer;
        }
    }
}

.burst-navbar {
    background-color: #a5a5a7;
    flex-direction: column;
    height: auto;

    @include breakpoint('md') {
        display: flex;
        justify-content: space-between;
        max-width: 600px;
        width: 100%;
    }

    @include breakpoint('lg') {
        height: 76px;
    }

    &-admin {
        width: 100vw;
        justify-content: center;

        @include breakpoint('xl') {
            display: flex;
            max-width: 1366px;
            width: 70vw;
            height: 76px;
        }

        h1 {
            font-family: $fontweight-bold;
            font-size: 40px;
            margin: 0;
            letter-spacing: 0;
            color: #FFFFFF;
            align-self: center;
        }
    }

    &-back {
        margin-right: 20px;
        text-decoration: none;
        color: white;
        transition: all .3s;
        -webkit-transition: all .3s;

        &:hover {
            color: $sprout-dark-gold;
        }
    }

    &-tools {
        border: none;
        height: 20px;
        position: absolute;
        right: 15vw;
        margin-top: 80px;

        @include breakpoint('lg') {
            position: relative;
            right: 0;
            margin: 10px 0;
        }

        a {
            background-color: white;
            padding: 6px;
            color: $sprout-blue;

            &:hover {
                color: $sprout-dark-gold;
            }
        }

    }

    button {
        color: white;
        background: none;
        font-size: 14px;
        cursor: pointer;
        outline: none;

        &:disabled {
            color: grey;

            &:hover {
                cursor: default;
            }

            p {
                &:hover {
                    border-bottom: 2px solid transparent;
                    cursor: default;
                }
            }
        }
    }

    &-hamburger {
        align-items: center;
        display: flex;
        justify-content: center;
        width: 100vw;
        background: $sprout-blue;
        height: 40px;
        color: #fff;

        @include breakpoint('lg') {
            display: none;
        }
    }

    &-inactive {
        border: none;
        font-family: $fontweight-semibold;

        p {
            border-bottom: 2px solid transparent;
            margin: 0;
            padding: 8px 0;

            &:hover {
                border-bottom: 2px solid white;
            }
        }
    }

    &-active {
        font-family: $fontweight-bold;
        border: none;

        p {
            border-bottom: 2px solid white;
            margin: 0;
            padding: 8px 0;
        }
    }

    &-wrap {
        display: flex;
        justify-content: center;
        width: 100vw;
        background-color: $sprout-blue;
        position: fixed;
        z-index: 3;
    }
}

.burst-toolpage {
    display: flex;
    flex-direction: column;
    margin: 30px 10px;
    padding: 0 20px;

    section {
        width: auto;
        margin: 10px
    }

    header {
        display: flex;
        font-size: 20px;
        color: $sprout-font-gray;
        margin: 15px 0;
    }

    &-pdfs {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }
}

.bursts-admin {
    &-email-header {
        margin-left: 5px;
        text-align: left;
        color: $sprout-blue;
    }

    &-email-instructions {
        margin-left: 5px;
        text-align: left;
        color: $fontweight-semibold;
    }

    &-email-template {
        width: 96%;
        max-width: 1000px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 10px 0px;

        @include breakpoint('lg') {
            width: 60vw;
        }

        @include breakpoint('lg') {
            width: 90vw;
        }

        h3 {
            text-align: left;
            color: $sprout-blue;
            width: 100%;
            font-size: 20px;
        }

        textarea {
            width: 96%;
            resize: none;
            height: 525px;
            font-size: 16px;
            padding: 10px;
            border-color: $sprout-blue;
            font-family: $typeface-montserrat;
        }

        button {
            background-color: $sprout-blue;
            color: white;
            font-size: 16px;
            margin-top: 10px;
            padding: 4px 10px;
            border-color: $sprout-blue;
            border: none;

            &:hover {
                cursor: pointer;
            }
        }
    }
}

//Invite participants form
.temp-participants-invite {
    @include flex-column;
    align-items: center;
    padding: 20px;

    @include breakpoint('xl') {
        @include flex-center;
        flex-direction: row;
        margin-bottom: 20px;
        padding: 20px;
    }

    &-df {
        margin-top: 120px;
    }

    &-form {
        width: 350px;
        margin-bottom: 20px;
        padding: 20px;
        border: 1px solid $sprout-dark-gray-3;
        box-shadow: 5px 5px 5px $sprout-dark-gray-3;
        background-color: $sprout-gray;

        @include breakpoint('sm') {
            width: 60vw;
        }

        @include breakpoint('md') {
            width: 60vw;
        }

        @include breakpoint('lg') {
            margin-left: 10px;
            width: 600px;
            max-width: 600px;
        }

        label {
            width: auto;
            margin-top: 15px;
            @include flex-between-center;

            input {
                padding-left: 6px;
                width: 180px;
                @include breakpoint('sm') {
                    width: 200px;
                }

                @include breakpoint('md') {
                    width: 300px;
                }

                @include breakpoint('lg') {
                    width: 400px;
                }
            }
        }

        button {
            background-color: $sprout-blue;
            border-color: $sprout-blue;
            border-style: solid;
            color: white;
            margin: 5px;
        }
    }

    &-send {
        margin-top: 10px;
        padding: 5px 10px;
        color: white;
        background-color: $sprout-blue;
        border: none;
    }

    &-list {
        padding: 0 0 0 20px;
        margin: 0;
        width: 100%;
        list-style: none;

        &-card {
            @include flex-between-center;
            border: 1px solid $sprout-dark-gray-3;
            box-shadow: -2px 2px 4px 0px $sprout-dark-gray-3;
            margin-bottom: 10px;
            box-shadow: 5px 5px 5px $sprout-dark-gray-3;
            width: 95%;

            @include breakpoint('lg') {
                width: 600px;
            }

            span {
                @include flex-between;
                width: 100%;
            }

            p {
                margin: 0;
                padding: 10px;
            }

            &-icon {
                color: $sprout-dark-red;
                height: 20px;
                margin: 10px 5px;
                min-width: 24px;
                font-size: 12px;
            }
        }
    }

    .client-admin {
        @include flex-space-around;
        margin: 10px;

        input {
            width: 20px;
        }
    }
}