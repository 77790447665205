.carousel-container {
    width: 100%;
    height: auto;
    padding: 10px 5px;
}

.carousel {
    display: flex;
    justify-content: space-between;
    height: 100%;
    &-arrow {
        font-size: 50px;
        color: $sprout-dark-gray;
    }
    &-gallery {
        width: calc(100% - 50px);
        list-style: none;
        padding: 0 10px;
        margin: 0;
        &-cell {
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            @include breakpoint('sm'){
                justify-content: center;
            }
        }
    }
}
