.features-form {
    @include flex-content-center;
    flex-direction: column;
    width: 400px;
    font-family: $font;
    &-wrapper {
        display: flex;
        justify-content: center;
    }
    &-input {
        color: $primary-color;
        font-weight: 600;
        margin-bottom: 10px;
        padding-left: 5px;
        height: 25px;
        width: 100%;
        font-size: 14px;
        border: 2px solid $primary-color;
        border-radius: 15px;
        outline: none;
        &:focus{
            border: 2px solid $secondary-color;
        }
    }
    &-textarea {
        color: $primary-color;
        font-weight: 600;
        margin-bottom: 10px;
        padding: 5px 0px 0px 5px;
        height: 200px;
        width: 100%;
        font-size: 14px;
        border: 2px solid $primary-color;
        border-radius: 15px;
        outline: none;
        &:focus{
            border: 2px solid $secondary-color;
        }
    }
    &-button {
        background-color: $primary-color;
        color: white;
        font-size: 14px;
        outline: none;
        height: 25px;
        width: 100px;
        border-radius: 15px;
        &:hover {
            cursor: pointer;  
        }
    }
    &-toggle-button {
        background-color: $primary-color;
        color: white;
        font-size: 14px;
        outline: none;
        height: 30px;
        width: 150px;
        border-radius: 15px;
        margin: 10px 0px;
    }
}

.features-list {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 25px;
    width: 100%;
    text-align: left;
    margin-bottom: 100px;
    &-item {
        width: 500px;
        border: 2px solid $primary-color;
        margin-bottom: 10px;
        border-radius: 15px;
    }
    &-title {
        border-bottom: 1px solid $primary-color;
        padding-bottom: 10px;
    }
    &-title, &-description {
        margin: 0px;
        font-weight: 600;
        color: $primary-color;
        margin: 10px;
    }
    &-description {
        font-size: 18px;
    }
    &-name, &-status {
        margin: 0px;
        font-weight: 600;
        color: $primary-color;
        margin: 10px;
        font-size: 15px;
    }
    &-button-wrap {
        display: flex;
        justify-content: center;
        width: 500px;
    }
    &-button {
        background-color: $primary-color;
        color: white;
        font-size: 14px;
        outline: none;
        height: 25px;
        width: 150px;
        border-radius: 15px;
        margin-top: 10px;
        &:hover {
            cursor: pointer;  
        }
    }
    &-date {
        margin: 0px;
        font-weight: 600;
        color: $grey;
        margin: 10px;
        text-align: center;
    }
}

@media (max-width: 520px) {
    .features-list {
        &-item {
            width: 90%;
        }
        &-button-wrap {
            width: 100%;
        }
    }
}

@media (max-width: 420px) {
    .features-form {
        &-input {
            width: 90%;
        }
        &-textarea {
            width: 90%;
        }
    }
}