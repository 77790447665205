// used for both the LMS snapshot and Coaching snapshot
@mixin snapshot-cover-image($image, $margin, $hoverColor) {
    @include size(300px, 384px);
    background-image: $image;
    background-repeat: no-repeat;
    background-size: contain;
    border: 2px solid $sprout-gray-2;
    margin: 10px;
    &:hover {
        border: 2px solid $hoverColor;
    }
    @include breakpoint('md') {
        margin: $margin;
        @include size(350px, 448px);
    }
    @include breakpoint('lg') {
        @include size(485px, 622px);
    }
}

.snapshots {
    padding-bottom: 50px;
    @include breakpoint('sm') {
        margin: 0 24px;
    }
    &-actions {
        display: flex;
        flex-direction: column;
        // Dont force Snapshots and group snapshots to be the same height.
        &:last-child {
            align-items: flex-start;
        }
        button {
            margin: 5px
        }
        @include breakpoint('sm') {
            flex-direction: row;
            section {
                flex: 1;
            }
        }
    }

    section {
        background-color: white;
        padding: 15px;
        margin: 10px;
        text-align: left;
        border-radius: 5px;
        header {
            color: $sprout-dark-blue;
            border-bottom: 1px solid $sprout-dark-blue;
            h2 {
                margin: 0 0 10px 0;
            }
        }
        @include breakpoint('sm') {
            margin-bottom: 20px;
        }
    }

    &-toggle {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 10px 5%;
        button, a {
            flex: 1;
            border: none;
            color: white;
        }
        &-small {
            width: 350px;
            transition: all 300ms;
            display: flex;
            button, a {
                @include size(100%);
            }
            button {
                padding: 10px;
            }
        }
        &-large {
            transition: all 300ms;
            display: flex;
            flex-wrap: wrap;
            font-size: 50px;
            margin-bottom: 50px;
            @include breakpoint('md') {
                flex-wrap: nowrap;
                margin-top: 50px;
            }
        }
        // button specific styles
        &-coaching-small {
            background-color: $sprout-blue;
            &:hover, &.snapshot-toggle-active {
                background-color: $sprout-dark-blue;
            }
        }
        &-coaching-large {
            @include snapshot-cover-image(url('../../../Assets/images/Website/ioc-cover.jpg'),  0 20px 0 0, $sprout-dark-blue);
        }
        &-grow-small {
            background-color: $sprout-gold;
            &:hover, &.snapshot-toggle-active {
                background-color: $sprout-extra-dark-gold;
            }
        }
        &-grow-large {
            @include snapshot-cover-image(url('../../../Assets/images/Website/grow-cover.jpg'),  0 0 0 20px, $sprout-extra-dark-gold);
        }
    }

    &-maps {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 10px;
        header {
            h2 {
                text-align: center;
                margin: 0 0 5px;
            }
        }
        &-view-history {
            font-weight: $fontweight-medium;
            color: $sprout-blue;
            margin-top: 10px;
            cursor: pointer;
            &:visited {
                color: $sprout-dark-blue;
            }
        }
        &-list {
            list-style: none;
            margin: 10px 0;
            padding: 0 10px;
        }
        &-history {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            margin: 10px 0;
            padding: 0;
            width: 100%;
        }
    }
}
