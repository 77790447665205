@keyframes fadeFromTop {
    0% {transform: translateY(-25px) translateX(-25px) rotate(-10deg);}
    100% {opacity: 1}
}

@keyframes fadeFromLeft {
    0% {transform: translateX(-50px)}
    100% {transform: translateX(0px); opacity: 1}
}

.analytics {
    height: 400vh;

    &-home {
        background-color: white;
        z-index: 22222;
        width: 100vw;
    }

    &-welcome {
        @include flex-center-all;
        height: 100vh;
        background: $iod-gradient;
        color: white;
        flex-direction: column;

        h2 {
            margin: 0;
            font-family: Arial, Helvetica, sans-serif;
            font-size: 6vw;
            opacity: 0;
            animation: fadeFromTop 2s;
            animation-fill-mode: forwards;
            backface-visibility: hidden;
        }

        h3 {
            margin: 0;
            font-family: Arial, Helvetica, sans-serif;
            font-size: 5vw;
            opacity: 0;
            animation: fadeFromLeft 2s;
            animation-delay: 1.2s;
            animation-fill-mode: forwards;
            backface-visibility: hidden;
        }

        span {
            color: white;
            font-size: .8vw;
            font-family: Arial, Helvetica, sans-serif;
        }
    }

    &-dashboard {
        height: 100vh;
        nav {
            @include flex-wrap;
            width: 100vw;
            flex-direction: row;
        }
        section {
            @include flex-center-all;
            height: 50vh;
            width: 50vw;
            font-size: 4vw;
            font-family: Arial, Helvetica, sans-serif
        }
        &-link {
            transition: 0.3s ease-in-out;
            &:hover {
                letter-spacing: 3px;
            }
        }
        &-link:nth-child(1) {
            background: $iod-gradient;
        }
        &-link:nth-child(2) {
            background: $ioc-gradient;
        }
        &-link:nth-child(3) {
            background: $iob-gradient;
        }
        &-link:nth-child(4) {
            background: $iof-gradient;
        }
        a {
            text-decoration: none;
            color: white;
        }
    }

    &-back-button {
        width: 2.7vw;
        height: 2.7vw;
        top: 10px;
        left: 10px;
        font-size: 1.7vw;
        border: none;
        border-radius: 50%;
        position: fixed;
        cursor: pointer;
        outline: none;
        transition: 0.3s ease-in-out;
        background: white;
        box-shadow: -5px 5px 14px rgba(0, 0, 0, 0.18);
        color: #2E5597;
        &.InsideOut {
            color: #2E5597;
        }
        &.Coaching {
            color: #C60303
        }
        &.Breakthrough {
            color: #369802;
        }
        &.IOCDF {
            color: #860FA2;
        }
        &:hover {
            font-size: 1.8vw;
        }
    }

    &-layout {
        color: white;
        text-align: left;
        position: relative;
        height: 100vh;
        overflow: hidden;
        font-family: Georgia, 'Times New Roman', Times, serif;
        &-bg {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            background: $iod-gradient;
            width: 100%;
            height: 100%;
            transform: skewY(12deg);
            transform-origin: bottom right;
            z-index: -1;
            &.InsideOut {
                background: $iod-gradient;
            }
            &.Coaching {
                background: $ioc-gradient;
            }
            &.Breakthrough {
                background: $iob-gradient;
            }
            &.IOCDF {
                background: $iof-gradient;
            }
        }
        &-circle {
            &-large {
                position: fixed;
                top: 5vh;
                left: 60vw;
                background: $iod-gradient;
                width: 18vw;
                height: 18vw;
                border-radius: 50%;
                z-index: -20;
                &.InsideOut {
                    background: #2E5597;
                }
                &.Coaching {
                    background: #C60303;
                }
                &.Breakthrough {
                    background: #369802;
                }
                &.IOCDF {
                    background: #860FA2;
                }
            }
            &-medium {
                position: fixed;
                top: 25vh;
                left: 58vw;
                background: $iod-gradient;
                width: 8vw;
                height: 8vw;
                border-radius: 50%;
                z-index: -19;
                &.InsideOut {
                    background: #1698AA;
                }
                &.Coaching {
                    background: #EFC40F;
                }
                &.Breakthrough {
                    background: #8DC90B;
                }
                &.IOCDF {
                    background: #A90D6E;
                }
            }
            &-small {
                position: fixed;
                top: 21vh;
                left: 57vw;
                background: $iod-gradient;
                width: 5vw;
                height: 5vw;
                border-radius: 50%;
                z-index: -21;
                &.InsideOut {
                    background: #00D8BCA6;
                }
                &.Coaching {
                    background: #DB7003A6;
                }
                &.Breakthrough {
                    background: #CCD613A6;
                }
                &.IOCDF {
                    background: #C90C3FA6;
                }
            }
        }
    }

    &-content {
        display: grid;
        grid-template-columns: repeat(14, 1fr);
        grid-template-rows: .5fr repeat(12, 1fr) .5fr;
        grid-column-gap: 4vw;
        height: 100vh;
        h1 {
            margin: 0px;
            font-size: 3vw;
            grid-column: 2/7;
            grid-row: 2/3;
            font-weight: lighter;
            color: white;
        }
        h3 {
            @include flex-self-center;
            margin: 0px;
            grid-column: 2/6;
            grid-row: 4/8;
            font-size: 2vw;
            font-weight: lighter;
            color: white;
        }
        img {
            grid-row: 4/8;
            grid-column: 8/14;
            width: 100%;
            height: auto;
        }
        &-card {
            aside {
                display: grid;
                grid-template-rows: repeat(5, 1fr);
                height: 100%;
            }
            @include analytics-card-style;
            &.first {
                grid-column: 2/6;
            }
            &.second {
                grid-column: 6/10;
            }
            &.third {
                grid-column: 10/14;
            }
        }
    }

    &-card-icon {
        font-size: 5vw;
    }

    &-charts {
        @include flex-center-all;
        flex-direction: column;
        height: 100vh;
        z-index: 22222;
        &-header {
            font-family: Georgia, 'Times New Roman', Times, serif;
            font-size: 2.6vw;
            text-align: left;
            width: 66vw;
        }
    }

    &-chart {
        width: 50vw;
        box-shadow: -5px 5px 14px rgba(0, 0, 0, 0.18);
        border-radius: 10px;
        background-color: white;
        &-component {
            display: flex;
            align-items: center;
        }
        &-description {
            color: #989898;
            width: 15vw;
            min-height: 28vh;
            box-shadow: -5px 5px 14px rgba(0, 0, 0, 0.3);
            border-radius: 10px;
            margin: 0px 10px;
            background-color: white;
            header {
                font-size: 1.6vw;
                margin-top: 10px;
                font-family: Georgia, 'Times New Roman', Times, serif;
            }
            div {
                margin: 10px 15px;
                font-size: 1vw;
                text-align: left;
                font-family: Arial, Helvetica, sans-serif;
                ul {
                    list-style-type: none;
                    margin: 0;
                    padding: 0;
                    color: #860FA2;
                    li {
                        display: flex;
                        align-items: center;
                        margin: 5px 0px;
                    }
                }
            }
        }
    }

    &-select-dataset {
        @include flex-center-all;
        height: 40vh;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 1.5vw;
    }

    &-buttons {
        &-wrap {
            width: 68vw;
            display: grid;
            grid-template-columns: repeat(6, 1fr);
            grid-column-gap: 15px;
            grid-row-gap: 30px;
            margin-top: 50px;
        }
        @include flex-center-all;
        border: none;
        outline: none;
        border-radius: 20px;
        height: 40px;
        font-size: .9vw;
        font-family: Arial, Helvetica, sans-serif;
        background: white;
        &:hover {
            cursor: pointer;
        }
        &.insideout-button {
            color: #2E5597;
            border: 2px solid #2E5597;
            &.selected {
                color: white;
                background: #2E5597;
            }
        }
        &.coaching-button {
            color: #C60303;
            border: 2px solid #C60303;
            &.selected {
                color: white;
                background: #C60303;
            }
        }
        &.breakthrough-button {
            color: #369802;
            border: 2px solid #369802;
            &.selected {
                color: white;
                background: #369802;
            }
        }
        &.iocdf-button {
            color: #860FA2;
            border: 2px solid #860FA2;
            &.selected {
                color: white;
                background: #860FA2;
            }
        }
    }
    &-rd-table {
        display: grid;
        height: 40vh;
        overflow: scroll;
        select {
            display: flex;
            justify-self: flex-end;
            position: sticky;
            top: 0;
            margin: 5px 5px 0px 0px;
            min-width: 300px;
            height: 30px;
            font-size: 20px;
            border: 2px solid $sprout-font-gray;
            color: $sprout-font-gray;
            background: #f7f7f7;
            border-radius: 4px;
            outline: none;
        }
    }
    &-rd-card {
        display: grid;
        grid-template-rows: 30px 1fr 18px;
        height: 120px;
        width: 90%;
        border: 2px solid $sprout-font-gray;
        box-shadow: -3px 3px 10px 0px rgba(0, 0, 0, 0.3);
        border-radius: 8px;
        background: #f7f7f7;
        color: $sprout-font-gray;
        margin: auto;
        margin-top: 10px;
        margin-bottom: 10px;
        header {
            font-size: 24px;
            font-family: $fontweight-bold;
            margin: 2px 0px 0px 5px;
        }
        h3 {
            margin: 2px 5px 0px 0px;
        }
        &-names {
            @include flex-between-center;
        }
        &-data {
            @include flex-space-around;
            font-size: 22px;
        }
    }
}

.InsideOut {
    color: #2E5597;
}
.Coaching {
    color: #C60303;
}
.Breakthrough {
    color: #369802;
}
.IOCDF {
    color: #860FA2;
}