.email-preferences {
    @include flex-content-center;
    flex-direction: column;
    &-container {
        max-width: 400px;
        margin-top: 20px;
    }
    &-button {
        outline: none;
        background-color: $primary-color;
        color: white;
        border: none;
        font-size: 16px;
        padding: 10px;
        margin-top: 10px;
        &:hover {
            cursor: pointer;
        }
    }
}

.checkboxes-wrap {
    width: 100%;
    height: 100%;
}

.checkbox-description {
    color: $grey;
    text-align: left;
    line-height: 22px;
    margin: 10px 0px 15px 25px;
}