.privacy-policy-page {
    text-align: left;
    padding: 20px;
    width: 100vw;

    &-wrap {
        @include flex-column;
        align-items: center;
        margin-bottom: 25px;
        width: 100%
    }

    h1, h3 {
        color: $sprout-blue;
        font-weight: $fontweight-bold;
        margin: 0px;
    }
    h1 {
        font-size: 52px;
    }
    h3 {
        font-size: 36px;
    }
    p, li {
        font-size: 18px;
        font-weight: $fontweight-medium;
        color: $sprout-font-gray;
    }
    p {
        margin-bottom: 30px;
    }
    span {
        text-decoration: underline;
    }
    &-accept-cookie {
        &:hover {
            cursor: pointer;
        }
    }
    header {
        font-size: 18px;
        font-weight: $fontweight-medium;
        color: $sprout-blue;
    }
    table {
        border-collapse: collapse;
        border-spacing: 0;
    }
    th, td {
        font-size: 18px;
        font-weight: $fontweight-regular;
        margin: 5px;
        padding: 5px;
    }
    td {
        vertical-align: top;
    }
    .privacy-policy-indented {
        margin-left: 25px;
    }
    .anchor {
        height: 60px;
        margin: 0px;
        &-link {
            text-decoration: none;
            color: $sprout-blue;
        }
    }
}
