// Sprout
.success-card {
    display: flex;
    flex-direction: column;
    width: 28.2vw;
    text-align: left;
    margin-bottom: 3.5vw;
    margin-right: 30px;

    img {
        width: 100%;
        margin-bottom: 20px;
    }

    strong, p {
        margin: 0 0 16px 0;
    }

    a {
        align-self: flex-start;
    }
}

// ----
.dropdown-card {
    display: flex;
    flex-direction: column;
    background-color: $very-light-grey;
    margin-bottom: 5px;
    
    &-content {
        display: flex;
        justify-content: space-between;
        padding: 5px;
        text-align: left;
        p {
            margin: 5px 0;
        }
        header {
            font-family: $whitney-semibold;
            font-size: 18px;
        }
    }
    &-dropdown {
        background-color: $ultra-light-grey;
        p {
            padding: 5px;
        }
    }
    &-arrow {
        background-color: $ultra-light-grey;
        width: 100%;
        cursor: pointer;
    }
}

// Used on Blogs
// Can be used anywhere you want to use a card as a link and include a Image, Title, and Description
.image-card {
    @include flex-space-between;
    flex-direction: column;
    width: 235px;
    height: 350px;
    background-color: $ultra-light-grey;
    text-decoration: none;
    border: 3px solid white;
    box-shadow: -5px 5px 16px #00000018;
    margin: 10px;
    -webkit-transition: all 1s;
    -moz-transition: all 1s;
    transition: all 1s;
    img {
        width: 100%;
        height: calc(100% - 20px);
        object-fit: cover;
        background-color: white;
    }
    nav {
        @include flex-space-between;
        position: relative;
        bottom: 0;
        background-color: $very-light-grey;
        width: 100%;
        padding: 10px 15px;
        color: $primary-color;
        p {
            margin: 0;
        }
        svg {
            color: grey;
        }
    }
    &-content {
        width: 100%;
        text-align: left;
        height: 130px;
        h4 {
            margin: 10px 20px;
            color: black;
            line-height: 1.2;
            font-size: 20px !important;
        }
        p {
            margin: 0 20px;
            color: grey;
            max-height: 75px;
            overflow: hidden;
        }
    }
    &:hover {
        border: 3px solid $primary-color;
        nav {
            text-decoration: underline;
            svg {
                color: black;
            }
        }
    }
}

@media (max-width: $mid-breakpoint) {
    .success-card {
        width: 200px;
        margin-bottom: 30px;
        margin-right: 30px;
        img {
            width: 100%;
            margin-bottom: 37px;
        }
        strong, p {
            margin: 0 0 16px 0;
        }
        a {
            align-self: flex-start;
        }
    }
}

@media (max-width: $small-breakpoint) {
    .success-card {
        margin-right: 0px;
        img {
            margin-bottom: 15px;
        }
    }
}

