/**
 * Set up a decent box model on the root element
 */

html {
    box-sizing: border-box;
}

/**
* Make all elements from the DOM inherit from the parent box-sizing
* Since `*` has a specificity of 0, it does not override the `html` value
* making all elements inheriting from the root box-sizing value
* See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
*/
*,
*::before,
*::after {
    box-sizing: inherit;
}

main {
    width: 100%;
    max-width: 100%;
}

// .App {
//     // set border on max width
//     @include breakpoint('max') {
//         border-left: 1px solid $sprout-dark-gray-2;
//         border-right: 1px solid $sprout-dark-gray-2;
//     }
// }

/*
Remove built-in form typography styles
*/
input, button, textarea, select {
    font: inherit;
}

/*
Avoid text overflows
*/
p, h1, h2, h3, h4, h5, h6 {
    overflow-wrap: break-word;
}
