.participants-wrap {
    background-color: $sprout-dark-gray-2;
}

.snapshots-wrap {
    background-color: $sprout-gray-2;

}

.preferences-wrap {
    background-color: $sprout-gray-2;

}

.resources-wrap {
    background-color: $sprout-gray-2;
}
