// Fonts
/* font-weight: 300 */
@font-face {
    font-family: 'WhitneyLight';
    src: local('WhitneyLight'), url(../../Assets/fonts/Whitney-Light.woff) format('woff');
}
/* font-weight: 400 */
@font-face {
    font-family: 'WhitneyBook';
    src: local('WhitneyBook'), url(../../Assets/fonts/Whitney-Book.woff) format('woff');
}
/* font-weight: 500 */
@font-face {
    font-family: 'WhitneyMedium';
    src: local('WhitneyMedium'), url(../../Assets/fonts/Whitney-Medium.woff) format('woff');
}
/* font-weight: 600 */
@font-face {
    font-family: 'WhitneySemibold';
    src: local('WhitneySemibold'), url(../../Assets/fonts/Whitney-Semibold.woff) format('woff');
}
/* font-weight: 700 */
@font-face {
    font-family: 'WhitneyBold';
    src: local('WhitneyBold'), url(../../Assets/fonts/Whitney-Bold.woff) format('woff');
}

// Redesign Colors (r- prefix will be removed when we update other communities)
$r-grayscale-1:#414141;
$r-grayscale-2: #6E6E6E;
$r-grayscale-3: #F5F8FA;
$r-primary-1: #0E4687;
$r-primary-2: #2E5597;
$r-primary-3: #4367B4;
$r-primary-4: #B3C2E1;
$r-secondary-1: #75DCCF;
$r-secondary-2: #A7E8E0;
$r-complementary-1:#FFD46A;
$r-complementary-2: #FFDC87;

$validation-font-green: #006100;
$validation-background-green: #C6EFCE;
$validation-font-red: #9C0006;
$validation-background-red: #FFC7CE;

// Colors
$primary-color: #346596;
$secondary-color: #5892c4;
$light-blue: #94B9D9;

$grey: #919194;
$grey-font: #575756;
$dark-grey: #424244;
$light-grey:#A5A5A7;
$very-light-grey: #EAEAEA;
$ultra-light-grey: #f5f5f5;

$green: #2A8637;
$red: #A03123;
$errorRed: #ef493e;
$light-red: #B1564B;
$purple: #863275;
$yellow: #D18A00;
$light-yellow: #D99F2E;
$bright-yellow: #f5C845;
$bright-yellow-alt: #efb933;

// goldenrod = #DAA520;

// Fonts
$whitney-light: "WhitneyLight";
$whitney-book: "WhitneyBook";
$whitney-medium: "WhitneyMedium";
$whitney-semibold: "WhitneySemibold";
$whitney-bold: "WhitneyBold";
// find and replace using typography file
$font: "Montserrat", Helvetica, Arial, sans-serif;

// A2B Resources
$project-planning: #DBAD6A;
$project-execution: #6C5D75;
$project-performance: #5C8668;
$project-closure: #813B47;

$IOD-teal: #8ecdc9;

// Analytics
$iod-gradient: linear-gradient(120deg, #00D8BC, #2E5597);
$ioc-gradient: linear-gradient(120deg, #EABE03, #C60303);
$iob-gradient: linear-gradient(120deg, #A3CE11, #369802);
$iof-gradient: linear-gradient(120deg, #C90C3F, #860FA2);

// Bursts
$bursts-blue: #2E5798;
$bursts-light-grey: #E8E8E8;
$bursts-grey: #7f7f7f;
$bursts-dark-grey: rgb(69, 69, 69);

// Sprout Redesign
$sprout-gold: #fdc743;
$sprout-orange: #fb9b12;
$sprout-red: #ed4944;
$sprout-salmon: #ec5258;
$sprout-dark-red: #b72525;
$sprout-dark-blue: #102d5d;
$sprout-blue: #306095;
$sprout-hyperlink: #0093c9;
$sprout-teal: #83cec7;
$sprout-gray: #f5f5f5;
$sprout-gray-2: #ebebeb;
$sprout-dark-gray: #c6c6c5;
$sprout-dark-gray-2: #d9d9d9;
$sprout-dark-gray-3: #7f7f7f;
$sprout-dark-gray-4: #7f7f7f;
$sprout-font-gray: #3c3c3b;

$blue-semi-circle: #234c80;
$orange-semi-circle: #fa8a0b;
$red-semi-circle: #8a2121;
$teal-semi-circle: #63bab1;

$large-breakpoint: 1600px;
$mid-breakpoint: 900px;
$small-breakpoint: 600px;
