// global-header is used for the base elements of the header
// Logo, Hamburger, and Phone. 
.global-header {
    position: fixed;
    height: $header-height-sm;
    z-index: 5;
    transition: background-color 300ms;
    &-content {
        @include flex-between-center;
        width: 100vw;
        padding: 0 10px;
        &-logo {
            height: $header-height-sm;
            width: auto;
            display: flex;
            align-items: center;
            img {
                height: 50%;
            }
        }
        &-tag {
            display: none;
        }
    }
    &-hamburger {
        position: absolute;
        top: -50px;
        right: 10px;
        color: white;
        font-size: 26px;
    }
    @include breakpoint('md'){
        height: $header-height-lg;
        max-width: $max-width;
        &-content {
            max-width: $max-width;
            height: 100%;
            align-items: center;
            &-logo, img {
                @include size(196px, 40px);
            }
            &-tag {
                position: absolute;
                top: 2px;
                right: 0;
                display: flex;
                background-color: rgba(255, 255, 255, 0.6);
                color: black;
                text-decoration: none;
                border: none;
                p {
                    margin: 0;
                    margin-left: 15px;
                    padding: 2px 30px 2px 5px;
                }
                &:hover {
                    background-color: white;
                    color: $sprout-blue;
                }
            }
        }
    }
    @include breakpoint('xl'){
        &-content {
            padding: 0 34px 0 15px;
        }
    }
}

// global-navbar is used for the styles of the navbar itself in the header
.global-navbar {
    position: absolute;
    top: $header-height-sm;
    left: 0;
    width: 100%;
    .navbar-link, .navbar-dropdown-link {
        text-decoration: none;
        color: black;
        &:hover { color: $sprout-red; }
        &-active { color: $sprout-red; }
    }
    &-list {
        padding: 0;
        list-style: none;
        text-align: left;
        background-color: $sprout-gray;
        margin: 0;
        &-item {
            width: 100%;
            padding: 10px 20px 10px 10px;
            &:not(:first-child){
                border-top: 1px solid rgba(128, 128, 128, 0.185);
            }
            .dropdown-arrow {
                border: none;
                background-color: transparent;
                font-size: .875em;
            }
            .navbar-link, span {
                @include flex-between;
                width: 100%;
            }
        }
    }
    &-dropdown {
        padding: 0;
        text-align: left;
        ul {
            padding: 0;
            list-style: none;
        }
        &-title {
            list-style: none;
            padding: 5px 0 5px 10px;
        }
        &-item {
            padding: 8px 0 8px 20px;
            list-style: none;
        }
    }

    @include breakpoint('md'){
        position: relative;
        top: 0;
        left: auto;
        height: $header-height-lg;
        width: auto;
        &-list {
            @include flex-center-all;
            width: auto;
            padding: 10px 0 0 0;
            height: 100%;
            background-color: transparent;
            &-item {
                @include flex-center-all;
                flex-direction: column;
                width: auto;
                padding: 0 15px;
                height: 100%;
                &:not(:first-child){
                    border-top: none;
                }
                .navbar-link {
                    color: white;
                    &:hover { color: $sprout-red; }
                    &-active { color: $sprout-red; }
                }
            }
        }
        &-dropdown {
            position: absolute;
            top: 0;
            margin-top: 70px;
            background-color: white;
            padding: 0 15px;
            width: auto;
            box-shadow: 2px 5px 10px -2px rgba(0, 0, 0, 0.378);
            ul {
                width: auto;
                padding: 15px 15px;
                li {
                    width: auto;
                    padding: 0;
                }
            }
            &-title {
                padding: 10px 0;
                margin: 0;
                min-width: 150px;
                border-top: 1px solid $sprout-dark-gray;
            }
            &-item {
                padding: 10px 0;
                margin: 0;
            }
        }
    }
}
