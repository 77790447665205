.reset-password-body {
    @include flex-align-center-column;
    width: 100vw;
    height: 100%;
    margin-top: 100px;
}
.iod-horiz-logo {
    width: 400px;
    height: auto;
}
.reset-pass-wrap {
    @include flex-column;
}
.reset-pass-wrap-false {
    display: none;
}
.reset-pass-h2 {
    color: $sprout-blue;
}
.reset-pass-inputs {
@include flex-start-center;
    width: 300px;
    flex-direction: column;
    font-weight: 600;
    color: $sprout-dark-gray-3;
    input {
        width: 288px;
        height: 25px;
        padding: 5px;
        margin-bottom: 20px;
        margin-top: 5px;
        outline: none;
        font-size: 12pt;
        border: solid 1px lightgray;
        font-weight: 300;
        &:focus {
            border: solid 1px $sprout-blue;
        }
    }
}
.reset-pass-buttons {
    @include flex-between-center;
    flex-direction: column;
    width: 300px;
    margin-bottom: 10px;
}
.reset-pass-btn {
    width: 300px;
    height: 40px;
    border: solid 1px $sprout-blue;
    background: $sprout-blue;
    color: white;
    cursor: pointer;
    transition: ease-in-out 0.2s;
    font-size: 11pt;
    outline: none;
    margin-bottom: 10px;
    margin-top: 10px;
    &:hover {
        background: $sprout-light-blue;
        border: solid 1px $sprout-light-blue;
    }
    &:active {
        border: solid 1px white;
    }
}
.reset-confirm {
    @include flex-align-center-column;
    margin-top: 40px;
    h3 {
        margin: 0;
        margin-bottom: 10px;
        color: $sprout-blue;
    }
    p {
        margin: 0;
        font-size: 9pt;
    }
}
.reset-confirm-false {
    display: none;
}
.reset-pass-confirm-checkmark {
    color: $validation-font-green;
    font-size: 36pt;
    margin-bottom: 20px;
}
.reset-pass-error-message{
    color: red;
    margin: 0;
}