// Vertical layouts
.columns {
    // split the component evenly
    display: flex;
    flex-direction: column;
    &.col, .col {
        flex: 1;
    }
    @include breakpoint('md'){
        flex-direction: row;
    }
}

.columns-lg {
    // split the component evenly
    display: flex;
    flex-direction: column;
    &.col, .col {
        flex: 1;
    }
    @include breakpoint('lg'){
        flex-direction: row;
        &.col, .col {
            max-width: 50%;
        }
    }
}

.columns-sidebar {
    // split the component to fit a panel off to the side
    display: flex;
    justify-content: space-between;
    .col:nth-child(1){
        flex: .66;
    }
    .col:nth-last-child(1){
        flex: .33;
    }
}
