.resources-toggles {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px auto 0;
    max-width: 300px;

    select {
        background-color: white;
        padding: 5px 10px;
    }

    @include breakpoint('md'){
        max-width: 620px;
    }
    @include breakpoint('lg'){
        max-width: 940px;
    }
    @include breakpoint('xl'){
        max-width: 1260px;
    }
}

.resources-list {
    padding: 20px 0px 50px;
    max-width: 300px;
    margin: 0 auto;
    &-content {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;
    }

    @include breakpoint('md'){
        max-width: 640px;
    }
    @include breakpoint('lg'){
        max-width: 960px;
    }
    @include breakpoint('xl'){
        max-width: 1280px;
    }
}

