.feedback {
    box-shadow: -5px 5px 12px rgba(0, 0, 0, 0.35);
    width: 60vw;
    max-width: 1366px;
    margin: 25px 0px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    &-wrap {
        @include flex-content-center;
        flex-direction: column;
    }
    &.modal {
        box-shadow: none;
    }
    &-main-header {
        width: 85vw;
        max-width: 1366px;
        color: $primary-color;
        font-size: 2em;
        margin: 25px 0px 0px 0px;
    }
    &-sub-header {
        color: $light-grey;
        margin: 0px 0px 10px 0px;
    }
    &-inputs-wrap {
        @include flex-space-between;
        justify-self: center;
        font-size: 2.5em; 
        width: 50%; 
        height: 60px;
    }
    &-input {
        color: #a5a5a7a1;
        transition: 0.2s ease-in;
        transition: 0.3s ease-in-out;
        width: 20%;
        &:hover {
            cursor: pointer;
            font-size: 1.3em;
        }
        &.sad {
            color: $red;
        }
        &.meh {
            color: $bright-yellow;
        }
        &.happiest {
            color: $green;
        }
    }
    &-optional {
        font-style: italic; 
        font-size: .8em;
    }
    h4 {
        color: #3c3c3c;
        font-size: 1.3em;
        margin: 15px 0px;
    }
    textarea {
        resize: none;
        width: 75%;
        outline: none;
        border: 2px solid black;
        border-radius: 3px;
        font-size: 18px;
        &:focus {
            border: 2px solid $primary-color;
        }
    }
    input {
        margin: 10px 0px;
        border: 2px solid black;
        min-width: 200px;
        width: 50%;
        height: 30px;
        font-size: 18px;
        padding-left: 5px;
        outline: none;
        &:focus {
            border: 2px solid $primary-color;
        }
    }
    button {
        font-size: 18px;
        background-color: $primary-color;
        border-radius: 4px;
        color: white;
        padding: 5px 10px;
        margin-top: 20px;
        outline: none;
        &:hover {
            cursor: pointer;
        }
    }
    &-success {
        color: $green;
    }
    &-error {
        color: $errorRed;
    }
    &-modal-button {
        display: flex;
        justify-content: center;
        button {
            background-color: $primary-color;
            color: white;
            font-size: 20px;
            padding: 4px 10px;
            margin: 10px 0px;
            &:hover {
                cursor: pointer;
            }
        }      
    }
}
@media (max-width: 469px) {
    .feedback {
        width: 90vw;
    }
}