.snapshot-report {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    margin: 50px 0;

    h3, h4 {
        color: $primary-color;
        padding: 5px;
    }

    p {
        padding: 0 5px;
    }

    &-header {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        background-color: $very-light-grey;

        &-title {
            @include flex-content-center;
            height: 100px;
            border-right: 1px solid $primary-color;

            h1 {
                width: 6em;
            }
            svg {
                background-color: $primary-color;
                path {
                    fill: white;
                }
            }
        }
        

        &-info {
            text-align: left;
            padding: 20px;
            width: 65%;
            p {
                margin: 10px 0 0 0;
                font-style: italic;
                font-size: 14px;
            }
            &-name {
                display: flex;
                justify-content: space-between;

                h3 {
                    margin: 0;
                    color: $primary-color;
                }
                p {
                    margin: 0;
                    font-style: normal;
                    font-size: 16px;
                }
            }
        }

        &-summary {
            @include flex-content-center;
            border-left: 1px solid $primary-color;
            border-right: 1px solid $primary-color;
            width: 75%;
            margin: 10px;

            h3, span {
                color: $primary-color;
                margin: 0 5px;
                font-weight: bold;
            }

            p {
                font-size: 14px;
            }
        }
    }

    &-body {
        width: 90%;
        text-align: left;
        
        &-summary {
            display: flex;
            flex-direction: column;
            padding: 5px;
            font-size: 14px;
            border: 1px solid $grey;

            &-info {
                display: flex;
                justify-content: space-between;
                
                &-description {
                    width: 75%;
                }
                &-legend {
                    display: flex;
                    width: 30%;

                    i {
                        width: 15px;
                        height: 15px;
                        background-color: $primary-color;
                        margin: 5px;
                    }
                }
                p {
                    margin: 5px 0;
                }
            }
        }

        &-recommendations {
            margin-bottom: 30px;
            summary {
                @include flex-content-center;
                background-color: $very-light-grey;
                color: $primary-color;
                padding: 20px;
            }
            
            h4 {
                margin: 5px 0;
            }

            &-compare {
                display: flex;
                justify-content: space-evenly;
            }
        }

        section {
            border: 1px solid $grey;
            header {
                background-color: $primary-color;
                color: white;
                padding: 5px;
            }
        }

        article {
            
            header {
                background-color: grey;
                color: white;
                padding: 5px;
            }

            ul {
                p {
                    padding: 0;
                    margin: 3px 0;
                    font-size: 14px;
                }
                li {
                    margin: 10px 0;
                    p {
                        font-style: italic;
                    }
                }
            }
        }
    }

    &-tables {
        table {
            width: 100%;
            border-collapse: collapse;
        }

        &-header {
            display: flex;
            justify-content: flex-end;
            &-content {
                display: flex;
                p {
                    margin: 0;
                }
            }
        }

        &-comparison {
            margin: 20px 0px;
            border: 1px solid $light-grey;

            &-seperator {
                height: 15px;
            }

            &-header {
                background-color: $primary-color;
                color: white;
                th {
                    padding: 5px;
                    font-weight: normal;
                }
            }
            &-goal {
                width: 30vw;
            }
            &-taker {
                color: $primary-color;
                width: 10vw;
                text-align: right;
                margin-right: 20px;
            }
            &-current {
                background-color: $very-light-grey;
                color: $primary-color;
                width: 10vw;
                text-align: center;
            }
            &-last {
                color: $primary-color;
                width: 10vw;
                text-align: center;
            }
        }

        &-assessment {
            border: 1px solid $light-grey;

            tbody:nth-child(even) {
                background-color: #ddebff;
            }

            header {
                display: flex;
                justify-content: flex-end;
                width: 100%;
            }
            table {
                border-collapse: collapse;
                thead {
                    th {
                        background-color: $primary-color;
                        color: white;
                        padding: 5px;
                        font-weight: normal;
                    }
                }
            }
            &-goals {
                padding-left: 5px;
                width: 30vw;
            }
            &-questions {
                width: 45vw;
                padding: 5px 0;
                font-weight: normal;
            }

            &-counts {
                width: 15px;
                text-align: center;
            }

            &-average {
                width: 70px;
                text-align: center;
            }
        }
    }
    .progressBar {
        display: flex;
        width: 280px;
        border: 1px solid $light-grey;
        align-content: center;
        margin: 5px 40px 5px 20px;

        &-header {
            display: flex;
            justify-content: space-between;
            width: 280px;
            font-size: 14px;
            margin: 5px 40px 5px 20px;
        }
        
        &-inner {
            &-others {
                background-color: $primary-color;
            }
            &-self {
                background-color: $light-grey;
            }
            
            &-lastsnapshot {
                display: flex;
                justify-content: flex-end;
                font-style: normal;
                font-weight: bolder;
                &-box {
                    width: 4px;
                    height: 15px;
                    background-color: black;
                }
            }
        }
    }
    .print-snapshot {
        @include flex-content-center;
        flex-direction: column;
        p {
            font-family: $whitney-medium;
        }
        button {
            border: none;
            background-color: #F5B838;
            width: 130px;
            height: 30px;
            color: white;
            font-family: $whitney-book;
            font-size: 16px;
            cursor: pointer;
            &:hover {
                background-color: goldenrod
            }
        }
    }
}

@media (max-width: 650px) {
    .snapshot-report {
        margin-top: 0;
        &-header {
            flex-direction: column;
            &-title {
                border-right: none;
                h1 {
                    margin: 0;
                }
            }
            &-info {
                padding: 10px;
                width: 90%;
            }

            &-summary {
                border-left: none;
                border-right: none;
                width: 90%;
                flex-wrap: wrap;
            }
        }
    
        &-tables {
            table {
                width: 100%;
                border-collapse: collapse;
            }

            &-header {
                display: flex;
                justify-content: flex-end;
                &-content {
                    display: flex;
                    p {
                        margin: 0;
                    }
                }
            }

            &-comparison {
                margin: 20px 0px;
                border: none;
            }

            &-assessment {
                border: none;
            }
        }
    }
}

@media (max-width: 500px) {
    .snapshot-report {
        h3, h4 {
            margin: 0;
        }
        &-body {
            width: 100%;            
            &-summary {
                border: 1px solid $grey;
                &-info {
                    flex-direction: column;
                    &-description {
                        width: 100%;
                    }
                    &-legend {
                        width: 100%;
                    }
                }
            }
            &-recommendations {
                display: flex;
                flex-direction: column;
                align-items: center;
                header {
                    width: 100%;
                }
                &-compare {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    width: 75%;
                }
            }
            article {
                padding: 5px;
                header {
                    width: 97.3%;
                }
            }
        }
    
        &-tables {
            padding: 5px;
            tr, th {
                width: 100%;
            }

            &-header {
                display: none;
            }

            &-comparison {
                margin: 20px 0px;

                table, tr, th, tbody, thead {
                    display: block;
                }

                &-seperator { 
                    display: none;
                }
                &-goal {
                    width: 100vw;
                    height: 40px;
                    text-align: center;
                    font-weight: bold;
                }
                thead {
                    tr {
                        display: flex;
                        width: 100%;
                        border-bottom: 1px solid black;
                        td {
                            display: flex;
                        }
                    }
                }
                
                &-taker {
                    width: 100vw;
                    text-align: center;
                }
                &-current {
                    width: 50vw;
                }
                &-last {
                    width: 50vw;
                }
            }

            &-assessment {
                tr, th, thead {
                    display: block;
                }
                tr {
                    border-bottom: 1px solid $light-grey;
                }
                header {
                    display: flex;
                    justify-content: flex-end;
                    width: 100%;
                }
                table {
                    thead {
                        th {
                            width: 97.3%;
                            margin-top: 15px;
                        }
                    }
                }
                &-goals {
                    font-weight: bold;
                    padding: 10px 0 10px 0;
                    text-align: left;
                    width: 100vw;
                    background-color: $very-light-grey;
                }
                &-questions {
                    width: 100%;
                    padding: 5px 0;
                    margin: 10px 0 0 0;
                }

                &-counts {
                    text-align: center;
                    margin-bottom: 10px;
                    color: $primary-color;
                }

                &-average {
                    color: $primary-color;
                    width: 68%;
                    text-align: left;
                    height: 25px;
                    &:before {
                        content: "Average: "
                    }
                    &:after {
                        margin-left: 15%;
                        content: "Score Count: ";
                    }
                }
            }
        }
        .progressBar {
            display: flex;
            align-content: center;
            margin: 0;
            width: 280px;

            &-wrap {
                display: flex;
                justify-content: center;
                width: 100%;
            }

            &-header {
                display: none;
            }
            &-spacer {
                display: none;
            }
        }
    }
}
