.form-input-list {
    // Used in forms on Registrations
    // HTML is <label><p></p><input /></label>
    label {
        display: flex;
        justify-content: space-between;
        margin: 5px 0;
        input, select {
            width: calc(100% - 30px);
            padding: 2px 5px;
        }
        p {
            margin: 0;
            width: 12.44vw;
        }
    }
    @media (max-width: $mid-breakpoint) {
        label {
            display: flex;
            justify-content: space-between;
            margin: 5px 0;
            input, select {
                width: calc(100% - 30px);
                padding: 2px 5px;
            }
            p {
                width: 120px;
            }
        }
    }
}

.form-checks {
    // Used in forms on Registrations
    // HTML is <label><input />Text</label>
    label {
        line-height: 30px;
    }
    
    &-sub-check {
        margin-left: 20px;
    }
}

.form-single-field {
    display: flex;
    align-items: center;
    input[type=text] {
        width: 60%;
        min-width: 200px;
    }
    button, input[type=checkbox] {
        margin-left: 10px;
    }
}