.carousels {
    &-clients {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin: 50px 35px;
        // height: 15vw;
        // max-height: 210px;

        h2 {
            margin: 0 0 10px 0;
        }

        &-container {
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            height: 10vw;
            max-height: 140px;;

            &-arrow {
                font-size: 50px;
                color: $sprout-dark-gray;
            }

            &-map {
                display: flex;
                justify-content: space-between;
                img {
                    width: 12.45vw;
                    max-width: 174px;
                    padding: 0 20px;
                }
            }
        }
    }

    &-case-studies {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        h2 {
            margin: 0 0 39px 0;
            width: 100%;
            color: white;
        }
        
        &-container {
            text-align: left;
            background-color: $sprout-teal;
            padding: 35px 35px 45px 35px;
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;

            &-arrow {
                font-size: 75px;
                color: white;
            }

            &-map {
                display: flex;
                justify-content: space-evenly;
                width: 100%;
                height: 100%;
                &-card {
                    width: 26%;
                    max-width: 366px;
                    background-color: white;
                    margin: 0 20px;
                    position: relative;
                    &-thumbnail {
                        width: 100%;
                        height: auto;
                    }
                    &-content {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        margin-bottom: 70px;
                        padding: 0 14px;
                        strong {
                            width: 100%;
                            margin: 5px 0;
                            font-weight: bold;
                        }
                        blockquote {
                            margin: 0 0 15px 0;
                        }
                        &-logo {
                            position: absolute;
                            bottom: 15px;
                            width: auto;
                            height: 60px;
                        }
                    }
                }
            }
        }
    }
}

.three-card-carousel {
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: $sprout-orange;
    padding: 4.24vw 34px 4.24vw 34px;
    h2 {
        margin: 0;
        max-width: 65%;
        color: white;
    }
    h6 {
        margin-top: 10px !important;
    }
    p {
        // max-width: 37.08%;
        color: $sprout-font-gray
    }

    &-container {
        display: flex;
        box-sizing: unset;
        width: 100vw;
        max-width: $large-breakpoint;
        height: 52vw;
        max-height: 728px;
        &-items {
            display: flex;
            justify-content: space-evenly;
            width: 100%;
        }
        &-arrow {
            padding: 0 2vw;
            align-self: center;
            font-size: 2.5vw;
            color: white;
        }
        .testimonials-container-card {
            width: 33%;
        }
    }
}

.card-hidden {
    display: none;
}
.card-one {
    order: 1;
}
.card-two {
    order: 2;
}
.card-three {
    order: 3;
}

// Laptop
@media (max-width: $mid-breakpoint) {
    .card-three {
        display: none;
    }

    .carousels {
        &-clients {
            margin: 35px 10px 45px 10px;
            text-align: center;
            height: 200px;
            h4 {
                margin: 0 0 20px 0;
            }
            &-container {
                height: 100px;
                &-arrow {
                    font-size: 40px;
                }
                &-map {
                    img {
                        width: 22vw;
                        padding: 0 2.5vw;
                    }
                }
            }
        }
    
        &-case-studies-container {
            text-align: center;
            padding: 35px 10px 45px 10px;
            &-arrow {
                font-size: 40px;
            }
            &-map {
                text-align: left;
                &-card {
                    width: 245px;
                    min-width: 245px;
                    height: 375px;
                    background-color: white;
                    margin: 0 10px;
                    &-content {
                        &-thumbnail {
                            height: 168.75px;
                        }
                        strong {
                            margin: 10px 14px;
                        }
                    }
                }
            }
        }
    }

    .three-card-carousel {
        padding: 20px 10px;
        h2 {
            max-width: 100%;
        }
        &-container {
            width: 100vw;
            height: 500px;
            &-arrow {
                padding: 0 5px;
                font-size: 20px;
            }
            .testimonials-container-card {
                width: 50%;
            }
        }
    }
}

// Tablet
@media (max-width: $small-breakpoint) {
    .card-two {
        display: none;
    }
    .three-card-carousel {

        &-container {
            height: 500px;
            .testimonials-container-card {
                width: 100%;
            }
        }
    }
}
