.App {
	padding: 0;
	text-align: center;
	display: flex;
	flex-direction: column;
	// space betweeen will only work when there is not enough content to fill the page
	// this ensures that the footer will be at the bottom
	justify-content: space-between;
	align-items: center;
	min-height: 100vh;
	max-width: 1600px;
	margin: 0 auto;
}

html, body {
	width: 100%;
	margin: 0;
	overflow-x: hidden;
}

input, button {
	font-family: "Calibri", Helvetica, Arial, sans-serif;
}

body {
	font-family: "Montserrat", Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
