.live-online-shipping {
    @include flex-content-center;
    flex-direction: column;
    h3 {
        color: $primary-color;
        margin: 10px 0 5px 0;
    }

    h4 {
        color: $primary-color;
        margin: 0 0 10px 0;
    }

    p {
        margin: 0 0 10px 0;
        font-style: italic;
    }

    form {
        display: flex;
        flex-direction: column;
        label {
            display: flex;
            justify-content: space-between;
            margin: 10px 0;
            input, select {
                width: 200px;
                margin-left: 30px;
            }
        }

        button {
            align-self: center;
            padding: 5px;
            width: 60px;
            color: white;
            background-color: $primary-color;
            border: none;
            cursor: pointer;
            margin: 30px 0;
            &:hover {
                color: $primary-color;
                background-color: white;
                border: 1px solid $primary-color;
            }
        }
    }
}

@media (max-width: 650px) {
    .live-online-shipping {
        form {
            width: 90vw;
            label {
                input, select {
                    width: 175px;
                    margin-left: 15px;
                }
            }
        }
    }

}