.form-panel {
    position: fixed;
    z-index: 3;
    top: 0;
    right: 0;
    background-color: rgb(225, 220, 215);
    width: 500px;
    height: 100vh;
    overflow: auto;
    header {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    &-close {
        display: flex;
        justify-self: flex-start;
        background-color: $purple;
        color: white;
        font-size: 30px;
        padding: 8px 12px;
        border: none;
        cursor: pointer;
    }
    &-wrap {
        margin: 25px 50px 25px 50px;
        background-color: white;
        padding: 25px;
        box-shadow: 8px -8px 0px 0px rgba(190, 190, 190, 0.25);
        overflow: scroll;
        form {
            display: flex;
            flex-direction: column;
            align-items: center;
            input {
                margin: 15px;
                padding: 0 0 0 10px;
                width: 230px;
                height: 38px;
                border: 1px solid $light-grey;
                font-size: 16px;
            }
            textarea {
                font-family: $whitney-book;
                resize: none;
                margin: 15px;
                padding: 10px 0 0 10px;
                width: 230px;
                height: 50px;
                border: 1px solid $light-grey;
                font-size: 16px;
            }
            button {
                cursor: pointer;
                background-color: $primary-color;
                color: white;
                padding: 8px 12px;
                border: 1px solid $primary-color;
                font-size: 15px;
                transition: .3s ease-in-out;
                &:hover {
                    background-color: white;
                    color: $primary-color;
                }
            }
        }
        h4 {
            color: $primary-color;
            font-size: 28px;
            font-family: $whitney-semibold;
            font-weight: 300;
            margin: 10px 0px 10px 0px;
            width: 100%;
            text-align: center;
        }
    }
}

@media(max-width: 500px) {
    .form-panel {
        width: 100vw;
    }
}

@media(max-width: 430px) {
    .form-panel-wrap {
        margin: 25px;
    }
    .form-panel-wrap h4 {
        font-size: 24px;
    }
}