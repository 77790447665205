:root,
[data-theme='light'] {
	--mf-primary: #5856d6;
	--mf-body-color: rgba(37, 43, 54, 0.95);
	--mf-body-bg: #fff;
	--mf-secondary-color: rgba(37, 43, 54, 0.681);
	--mf-secondary-bg: #e7eaee;
	--mf-tertiary-color: rgba(37, 43, 54, 0.38);
	--mf-border-color: #dbdfe6;
	--mf-border-width: 1px;
	--mf-border-radius: 0.375rem;
}

.multi-select {
	--multi-select-font-size: 1rem;
	--multi-select-line-height: 1.5;
	--multi-select-color: var(--mf-body-color);
	--multi-select-bg: var(--mf-body-bg);
	--multi-select-box-shadow: inset 0 1px 2px rgba(8, 10, 12, 0.075);
	--multi-select-border-width: var(--mf-border-width);
	--multi-select-border-color: var(--mf-border-color);
	--multi-select-border-radius: var(--mf-border-radius);
	--multi-select-disabled-color: var(--mf-body-color);
	--multi-select-disabled-bg: var(--mf-secondary-bg);
	--multi-select-disabled-border-color: var(--mf-border-color);
	--multi-select-focus-color: var(--mf-body-color);
	--multi-select-focus-bg: var(--mf-body-bg);
	--multi-select-focus-border-color: #acabeb;
	--multi-select-focus-box-shadow: 0 0 0 0.25rem
		rgba(88, 86, 214, 0.25);
	--multi-select-placeholder-color: var(--mf-secondary-color);
	--multi-select-selection-padding-y: 0.375rem;
	--multi-select-selection-padding-x: 0.75rem;
	--multi-select-cleaner-width: 1.5rem;
	--multi-select-cleaner-height: 1.5rem;
	--multi-select-cleaner-padding-y: 0;
	--multi-select-cleaner-padding-x: 0;
	--multi-select-cleaner-icon: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3E%3C/svg%3E");
	--multi-select-cleaner-icon-color: var(--mf-tertiary-color);
	--multi-select-cleaner-icon-hover-color: var(--mf-body-color);
	--multi-select-cleaner-icon-size: 0.625rem;
	--multi-select-indicator-width: 1.5rem;
	--multi-select-indicator-height: 1.5rem;
	--multi-select-indicator-padding-y: 0;
	--multi-select-indicator-padding-x: 0;
	--multi-select-indicator-icon: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath d='M256.045 416.136.717 160.807l29.579-29.579 225.749 225.748 225.749-225.748 29.579 29.579-255.328 255.329z'/%3E%3C/svg%3E");
	--multi-select-indicator-icon-color: var(--mf-tertiary-color);
	--multi-select-indicator-icon-hover-color: var(--mf-body-color);
	--multi-select-indicator-icon-size: 0.75rem;
	--multi-select-select-all-padding-y: 0.5rem;
	--multi-select-select-all-padding-x: 0.75rem;
	--multi-select-select-all-color: var(--mf-body-secondary-color);
	--multi-select-select-all-bg: transparent;
	--multi-select-select-all-border-width: var(--mf-border-width);
	--multi-select-select-all-border-color: var(--mf-border-color);
	--multi-select-select-all-hover-color: var(--mf-body-color);
	--multi-select-select-all-hover-bg: transparent;
	--multi-select-dropdown-min-width: 100%;
	--multi-select-dropdown-bg: var(--mf-body-bg);
	--multi-select-dropdown-border-width: var(--mf-border-width);
	--multi-select-dropdown-border-color: var(--mf-border-color);
	--multi-select-dropdown-border-radius: var(--mf-border-radius);
	--multi-select-dropdown-box-shadow: 0 0.5rem 1rem rgba(8, 10, 12, 0.15);
	--multi-select-options-padding-y: 0.5rem;
	--multi-select-options-padding-x: 0.75rem;
	--multi-select-options-font-size: 1rem;
	--multi-select-options-font-weight: 400;
	--multi-select-options-color: var(--mf-body-color);
	--multi-select-optgroup-label-padding-y: 0.5rem;
	--multi-select-optgroup-label-padding-x: 0.625rem;
	--multi-select-optgroup-label-font-size: 80%;
	--multi-select-optgroup-label-font-weight: 700;
	--multi-select-optgroup-label-color: var(--mf-tertiary-color);
	--multi-select-optgroup-label-text-transform: uppercase;
	--multi-select-option-padding-y: 0.5rem;
	--multi-select-option-padding-x: 1.25rem;
	--multi-select-option-margin-y: 1px;
	--multi-select-option-margin-x: 0;
	--multi-select-option-border-width: var(--mf-border-width);
	--multi-select-option-border-color: transparent;
	--multi-select-option-border-radius: var(--mf-border-radius);
	--multi-select-option-box-shadow: inset 0 1px 2px
		rgba(8, 10, 12, 0.075);
	--multi-select-option-hover-color: var(--mf-body-color);
	--multi-select-option-hover-bg: #f3f4f7;
	--multi-select-option-focus-box-shadow: 0 0 0 0.25rem
		rgba(88, 86, 214, 0.25);
	--multi-select-option-disabled-color: var(--mf-secondary-color);
	--multi-select-option-indicator-width: 1em;
	--multi-select-option-indicator-bg: var(--mf-body-bg);
	--multi-select-option-indicator-border: var(--mf-border-width)
		solid var(--mf-border-color);
	--multi-select-option-indicator-border-radius: 0.25em;
	--multi-select-option-selected-bg: var(--mf-secondary-bg);
	--multi-select-option-selected-indicator-bg: var(--mf-primary);
	--multi-select-option-selected-indicator-bg-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='none' stroke='rgba(255, 255, 255, 0.87)' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3E%3C/svg%3E");
	--multi-select-option-selected-indicator-border-color: var(
		--mf-primary
	);
	--multi-select-tag-padding-y: 0.0625rem;
	--multi-select-tag-padding-x: 0.5rem;
	--multi-select-tag-bg: var(--mf-secondary-bg);
	--multi-select-tag-border-width: var(--mf-border-width);
	--multi-select-tag-border-color: var(--mf-border-color);
	--multi-select-tag-border-radius: 0.25rem;
	--multi-select-tag-delete-width: 0.75rem;
	--multi-select-tag-delete-height: 0.75rem;
	--multi-select-tag-delete-icon: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3E%3C/svg%3E");
	--multi-select-tag-delete-icon-color: var(--mf-secondary-color);
	--multi-select-tag-delete-icon-hover-color: var(--mf-body-color);
	--multi-select-tag-delete-icon-size: 0.5rem;
	--multi-select-selection-tags-gap: 0.25rem;
	--multi-select-selection-tags-padding-y: 0.25rem;
	--multi-select-selection-tags-padding-x: 0.25rem;
	position: relative;
}

.multi-select-input-group {
	align-items: stretch;
	background-clip: padding-box;
	background-color: var(--multi-select-bg);
	border: var(--multi-select-border-width) solid
		var(--multi-select-border-color);
	border-radius: var(--multi-select-border-radius);
	color: var(--multi-select-color);
	display: flex;
	flex-wrap: wrap;
	font-size: var(--multi-select-font-size);
	font-weight: 400;
	line-height: var(--multi-select-line-height);
	width: 100%;
}

.multi-select .multi-select-input-group {
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
	.multi-select .multi-select-input-group {
		transition: none;
	}
}

.multi-select.disabled .multi-select-input-group {
	background-color: var(--multi-select-disabled-bg);
	border-color: var(--mf-border-color);
	color: var(--multi-select-disabled-color);
}

.multi-select.show .multi-select-input-group {
	background-color: var(--multi-select-focus-bg);
	border-color: var(--multi-select-focus-border-color);
	box-shadow: var(--multi-select-focus-box-shadow);
	color: var(--multi-select-focus-color);
	outline: 0;
}

.multi-select-selection {
	display: flex;
	flex: 1 1 auto;
	flex-wrap: wrap;
	min-width: 0;
	padding: var(--multi-select-selection-padding-y)
		var(--multi-select-selection-padding-x);
	position: relative;
	width: 1%;
}

.multi-select-selection-tags {
	align-content: center;
	gap: var(--multi-select-selection-tags-gap);
	padding: var(--multi-select-selection-tags-padding-y)
		var(--multi-select-selection-tags-padding-x);
}

.multi-select-search {
	background: transparent;
	border: 0;
	display: none;
	flex: 1 1 auto;
	max-width: 100%;
	padding: 0;
}

.multi-select-search:focus {
	outline: 0;
}

.multi-select-search::placeholder {
	color: var(--multi-select-placeholder-color);
	opacity: 1;
}

.multi-select-search:placeholder-shown,
.multi-select.show .multi-select-search {
	display: flex;
}

.multi-select-selection-tags .multi-select-search {
	padding-inline-start: calc(
		var(--multi-select-selection-padding-x) - 0.25rem
	);
}

.multi-select-placeholder {
	color: var(--multi-select-placeholder-color);
}

.multi-select-selection-tags .multi-select-placeholder {
	padding: calc(var(--multi-select-selection-padding-y) - 0.25rem)
		calc(var(--multi-select-selection-padding-x) - 0.25rem);
}

.multi-select-buttons {
	align-items: center;
	display: flex;
	min-height: calc(
		var(--multi-select-selection-padding-y) * 2 +
			var(--multi-select-font-size) *
			var(--multi-select-line-height)
	);
	padding: 0 var(--multi-select-selection-padding-y);
}

.multi-select-cleaner,
.multi-select-indicator {
	background-color: transparent;
	background-position: 50%;
	background-repeat: no-repeat;
	border: 0;
	box-sizing: content-box;
	position: relative;
	z-index: 2;
}

.multi-select-cleaner:focus,
.multi-select-indicator:focus {
	border-radius: 0.375rem;
	box-shadow: var(--mf-focus-ring-x, 0) var(--mf-focus-ring-y, 0)
		var(--mf-focus-ring-blur, 0) 0.25rem
		rgba(88, 86, 214, 0.25);
	outline: 0;
	z-index: 5;
}

.multi-select-cleaner:before,
.multi-select-indicator:before {
	content: '';
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
}

.multi-select-cleaner {
	height: var(--multi-select-cleaner-height);
	padding: var(--multi-select-cleaner-padding-y)
		var(--multi-select-cleaner-padding-x);
	width: var(--multi-select-cleaner-width);
}

.multi-select-cleaner:before {
	background-color: var(--multi-select-cleaner-icon-color);
	-webkit-mask: var(--multi-select-cleaner-icon)
		center/var(--multi-select-cleaner-icon-size) no-repeat;
	mask: var(--multi-select-cleaner-icon)
		center/var(--multi-select-cleaner-icon-size) no-repeat;
}

.multi-select-cleaner:focus:before,
.multi-select-cleaner:hover:before {
	background-color: var(--multi-select-cleaner-icon-hover-color);
}

.multi-select-indicator {
	height: var(--multi-select-indicator-height);
	padding: var(--multi-select-indicator-padding-y)
		var(--multi-select-indicator-padding-x);
	transition: transform 0.15s ease-in-out;
	width: var(--multi-select-indicator-width);
}

@media (prefers-reduced-motion: reduce) {
	.multi-select-indicator {
		transition: none;
	}
}

.multi-select-indicator:before {
	background-color: var(--multi-select-indicator-icon-color);
	-webkit-mask: var(--multi-select-indicator-icon)
		center/var(--multi-select-indicator-icon-size) no-repeat;
	mask: var(--multi-select-indicator-icon)
		center/var(--multi-select-indicator-icon-size) no-repeat;
}

.multi-select-indicator:focus:before,
.multi-select-indicator:hover:before {
	background-color: var(--multi-select-indicator-icon-hover-color);
}

.multi-select.show .multi-select-indicator {
	transform: rotate(180deg);
}

.multi-select-tag {
	align-items: center;
	background-color: var(--multi-select-tag-bg);
	border: var(--multi-select-tag-border-width) solid
		var(--multi-select-tag-border-color);
	border-radius: var(--multi-select-tag-border-radius);
	display: flex;
	padding: var(--multi-select-tag-padding-y)
		var(--multi-select-tag-padding-x);
}

.multi-select-tag-delete {
	background-color: transparent;
	border: 0;
	box-sizing: content-box;
	height: var(--multi-select-tag-delete-height);
	margin-inline-start: 0.5rem;
	padding: var(--multi-select-tag-delete-padding-y)
		var(--multi-select-tag-delete-padding-x);
	position: relative;
	width: var(--multi-select-tag-delete-width);
	z-index: 2;
}

.multi-select-tag-delete:before {
	background-color: var(--multi-select-tag-delete-icon-color);
	content: '';
	height: 100%;
	left: 0;
	-webkit-mask: var(--multi-select-tag-delete-icon)
		center/var(--multi-select-tag-delete-icon-size) no-repeat;
	mask: var(--multi-select-tag-delete-icon)
		center/var(--multi-select-tag-delete-icon-size) no-repeat;
	position: absolute;
	top: 0;
	width: 100%;
}

.multi-select-tag-delete:focus:before,
.multi-select-tag-delete:hover:before {
	background-color: var(--multi-select-tag-delete-icon-hover-color);
}

.multi-select-dropdown {
	background-clip: padding-box;
	background-color: var(--multi-select-dropdown-bg);
	border: var(--multi-select-dropdown-border-width) solid
		var(--multi-select-dropdown-border-color);
	border-radius: var(--multi-select-dropdown-border-radius);
	display: none;
	min-width: var(--multi-select-dropdown-min-width);
	z-index: 2;
	inset: '0px auto auto 0px';
  position: absolute;
}

.multi-select.show .multi-select-dropdown {
	display: block;
}

.multi-select-all {
	background-color: var(--multi-select-select-all-bg);
	border: 0;
	border-bottom: var(--multi-select-select-all-border-width) solid
		var(--multi-select-select-all-border-color);
	color: var(--multi-select-select-all-color);
	display: block;
	padding: var(--multi-select-select-all-padding-y)
		var(--multi-select-select-all-padding-x);
	text-align: start;
	width: 100%;
}

.multi-select-all:hover {
	background-color: var(--multi-select-select-all-hover-bg);
	color: var(--multi-select-select-all-hover-color);
}

.multi-select-options {
	color: var(--multi-select-options-color);
	font-size: var(--multi-select-options-font-size);
	font-weight: var(--multi-select-options-font-weight);
	padding: var(--multi-select-options-padding-y)
		var(--multi-select-options-padding-x);
}

.multi-select-option {
	border: var(--multi-select-option-border-width) solid
		var(--multi-select-option-border-color);
	border-radius: var(--multi-select-option-border-radius);
	cursor: pointer;
	margin: var(--multi-select-option-margin-y)
		var(--multi-select-option-margin-x);
	padding: var(--multi-select-option-padding-y)
		var(--multi-select-option-padding-x);
	position: relative;
	z-index: 2;
}

.multi-select-option:focus,
.multi-select-option:hover {
	background-color: var(--multi-select-option-hover-bg);
	color: var(--multi-select-option-hover-color);
	text-decoration: none;
}

.multi-select-option:focus {
	border-color: var(--mf-input-focus-border-color, #acabeb);
	box-shadow: var(--multi-select-focus-box-shadow);
	outline: 0;
	z-index: 5;
}

.multi-select-option.disabled {
	background-color: transparent;
	color: var(--multi-select-option-disabled-color);
	pointer-events: none;
}

.multi-select-option.multi-select-option-with-checkbox {
	padding: 0.5rem 1.25rem;
	padding-left: calc(
		var(--multi-select-option-padding-x) +
			var(--multi-select-option-indicator-width)
	);
}

.multi-select-option.multi-select-option-with-checkbox:before {
	background-color: var(--multi-select-option-indicator-bg);
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: contain;
	border: var(--multi-select-option-indicator-border);
	border-radius: var(--multi-select-option-indicator-border-radius);
	content: '';
	display: block;
	height: var(--multi-select-option-indicator-width);
	left: calc(var(--multi-select-option-padding-x) * 0.5);
	pointer-events: none;
	position: absolute;
	top: 0.7rem;
	width: var(--multi-select-option-indicator-width);
}

.multi-select-option.multi-selected {
	background-color: var(--multi-select-option-selected-bg);
}

.multi-select-option.multi-selected:before {
	background-color: var(--multi-select-option-selected-indicator-bg);
	background-image: var(
		--multi-select-option-selected-indicator-bg-image
	);
	border-color: var(
		--multi-select-option-selected-indicator-border-color
	);
}

.multi-select-options-empty {
	padding: var(--multi-select-option-padding-y)
		var(--multi-select-option-padding-x);
}

.select-module {
	position: relative;
	label {
		display: inline-block;
	}
	button,
	input {
		font-family: inherit;
		font-size: inherit;
		line-height: inherit;
		margin: 0;
		cursor: pointer;
	}
}

@media (min-width: 768px) {
	.select-module {
		--cd-example-padding: 1.5rem;
		border-top-left-radius: var(--mf-border-radius);
		border-top-right-radius: var(--mf-border-radius);
		border-width: 1px;
		margin-left: 0;
		margin-right: 0;
	}
}

.form-label {
	margin-bottom: 0.5rem;
}
