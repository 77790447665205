.card-resources {
    position: relative;
    width: 300px;
    height: 235px;
    background-color: white;
    margin: 10px;
    border-radius: 5px;
    cursor: pointer;
    &:hover {
        box-shadow: 0px 5px 7px 0px #bdbdbd;
        -webkit-box-shadow: 0px 5px 7px 0px #bdbdbd; 
    }
    img {
        position: relative;
        align-self: center;
        object-fit: cover;
        object-position: top;
        margin: 10px 10px 0 10px;
        border-radius: 5px;
        border: 1px solid $sprout-dark-gray;
        @include size(calc(100% - 20px), calc(75% - 10px));
    }
    &-content {
        @include flex-center;
        flex-direction: column;
        align-items: flex-start;
        width: calc(100% - 20px);
        padding: 2px;
        margin: 0 10px;
        text-align: left;
        p, small {
            color: $sprout-font-gray;
            margin: 0;
        }
        p {
            max-width: calc(100% - 75px);
            font-size: .83em;
            font-weight: $fontweight-semibold;
            color: $sprout-dark-blue;
            margin-bottom: 2px;
        }
    }
    &-tag {
        position: absolute;
        bottom: 20px;
        right: -8px;
        width: 93px;
        padding: 5px 10px;
        text-align: left;
        font-weight: $fontweight-medium;
        z-index: 1;
        &:before {
            content:"\A";
            border-style: solid;
            border-width: 4px 4px;
            position: absolute;
            right: 0;
            bottom: -8px;
        }
        // resource specific colors
        &-tool {
            background-color: $sprout-dark-gold;
            color: $sprout-font-gray;
            &:before {
                border-color: $sprout-extra-dark-gold transparent transparent $sprout-extra-dark-gold;
            }
        } 
        &-guide {
            background-color: $sprout-teal;
            color: white;
            &:before {
                border-color: $teal-semi-circle transparent transparent $teal-semi-circle;
            }
        } 
        &-video {
            background-color: $sprout-blue;
            color: white;
            &:before {
                border-color: $sprout-dark-blue transparent transparent $sprout-dark-blue;
            }
        } 
    }
}

.card-snapshots-history {
    margin: 10px;
}

.card-snapshot {
    position: relative;
    background-color: white;
    text-decoration: none;
    list-style: none;
    width: 320px;
    border-radius: 10px;
    box-shadow: 0px 2px 2px 0px #bdbdbd;
    -webkit-box-shadow: 0px 2px 2px 0px #bdbdbd; 
    text-align: left;
    &-status {
        padding: 10px 20px 10px;
        width: 100%;
        background-color: $sprout-blue;
        color: white;
        margin-bottom: 30px;
        border-radius: 10px 10px 0 0;
        &:before {
            content:"\A";
            border-style: solid;
            border-width: 8px 160px;
            position: absolute;
            right: 0;
            top: 35px;
            border-color: $sprout-blue transparent transparent $sprout-blue;
        }
    }
    &-date {
        z-index: 2;
        margin-top: 10px;
        padding: 10px 15px;
        color: black;
    }
    &-buttons {
        display: flex;
        flex-wrap: wrap;
        padding: 10px 15px;
        button, a {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: white;
            color: black;
            text-decoration: none;
            border: 1px solid black;
            border-radius: 15px;
            height: 30px;
            padding: 0 10px;
            margin-right: 5px;
            cursor: pointer;
            &:hover {
                background-color: $sprout-blue;
                color: white;
            }
        }
    }
    &-icon {
        position: absolute;
        top: 8px;
        right: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        background-color: #7dda7d;
        &-inner {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            width: 40px;
            height: 40px;
            background-color: #21a921;
            color: white;
        }
    }
}

.card-group-snapshot {
    background-color: white;
    width: 320px;
    border-radius: 10px;
    box-shadow: 0px 2px 2px 0px #bdbdbd;
    -webkit-box-shadow: 0px 2px 2px 0px #bdbdbd; 
    text-align: left;
    margin-bottom: 15px;
    header {
        padding: 10px 15px 0 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        p, h3 {
            margin: 0;
        }
        span {
            display: flex;
            :nth-child(1){
                color: $sprout-blue;
                margin-right: 3px;
            }
        }
    }
}

.card-participant {
    position: relative;
    background-color: white;
    margin-top: 45px;
    border-radius: 20px;
    &-header {
        padding: 10px 0 5px 0;
        background-color: $slate-gray;
        color: white;
        border-radius: 20px 20px 0 0;
        h2 {
            margin: 5px 0 2px 0;
        }
        h3 {
            margin: 0;
            padding-bottom: 2px;
        }
        &-icon {
            position: absolute;
            top: -25px;
            right: 0;
            left: 0;
            margin: 0 auto;
            background-color: white;
            color: $slate-gray;
            border-radius: 50%;
            width: 30px;
            height: 30px;
            padding: 10px;
            border: 2px solid $slate-gray;
        }
        &-edit {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            button {
                margin-top: 5px;
            }
        }
        &-check {
            position: absolute;
            right: 10px;
            top: 10px;
        }
        @include breakpoint('md'){
            text-align: left;
            padding-bottom: 10px;
            h2 {
                margin-top: 0px
            }
            h2, h3, small {
                margin-left: 20px;
            }
            &-icon {
                top: -15px;
                left: -15px;
                right:  auto;
                margin-left: 0;
                width: 45px;
                height: 45px;
            }
            &-edit {
                flex-direction: row;
                align-items: flex-start;
                justify-content: flex-start;
                button {
                    margin-top: 0px;
                    margin-left: 20px;
                }
            }
            &-check {
                right: 15px;
                top: 12px;
                height: 15px;
                width: 15px;
            }
        }
    }
    &-body {
        display: flex;
        flex-direction: column;
        @include breakpoint('md'){
            flex-direction: row;
            min-height: 100%;
        }
    }
    &-tabs {
        @include flex-between;
        background-color: $slate-gray;
        button {
            @include flex-center-all;
            width: 33.1%;
            border: none;
            outline: none;
            margin-top: 1px;
            padding: 5px;
            svg {
                max-height: 100%;
                max-width: 100%;
            }
        }
        &-option {
            background-color: $sprout-blue;
            color: white;
        }
        &-active {
            background-color: white;
            color: $sprout-blue;
        }
        @include breakpoint('md'){
            flex-direction: column;
            justify-content: flex-start;
            border-radius: 0 0 0 20px;
            background-color: white;
            padding-bottom: 20px;
            button {
                width: 50px;
                border-top: 1px solid white;
                margin-top: 0;
                height: 25px;
                padding: 5px;
            }
            &-option {
                background-color: $slate-gray;
                color: white;
            }
        }
    }
    &-content {
        @include flex-center-all;
        flex-direction: column;
        width: 100%;
        padding: 10px;
        @include breakpoint('md'){
            // justify-content: flex-start;
            padding: 0px 10px 20px 10px; // set padding top and bottom per component
        }
        &-details {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            &-actions {
                display: flex;
                button {
                    margin: 5px;
                }
            }
            &-info {
                display: flex;
                justify-content: space-evenly;
                width: 100%;
                margin: 10px 0;
                span {
                    display: flex;
                    flex-direction: column;
                    p {
                        margin: 0 2px;
                    }
                    hr {
                        margin: 2px 0;
                    }
                }
            }
            @include breakpoint('md'){
                flex-direction: row;
                justify-content: space-between;
                &-info {
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: flex-end;
                    span {
                        flex-direction: row;
                        p {
                            margin: 5px 2px;
                        }
                        hr {
                            display: none;
                        }
                    }
                }
            }
        }
        &-products {
            width: 100%;
            max-width: 300px;
            button {
                margin: 15px 0 10px 0;
            }
            table {
                width: 100%;
                border-collapse: collapse;
                thead {
                    th {
                        padding-bottom: 8px;
                    }
                }
                tbody {
                    text-align: left;
                    tr {
                        padding: 0 5px;
                        td {
                            padding: 6px 5px 4px 5px;
                            input {
                                width: 100%;
                                height: 20px;
                            }
                        }
                        &:nth-child(odd) {
                            background-color: $sprout-gray-2;
                        }
                    }
                }
            }
            @include breakpoint('md'){
                max-width: unset;
                table {
                    display: flex;
                    tr {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        td {
                            width: 100%;
                        }
                    }
                    thead {
                        tr {
                            @include flex-between;
                            flex-direction: column;
                            height: 100%;
                            th {
                                margin-right: 5px;
                            }
                        }
                    }
                    tbody {
                        display: flex;
                        width: 100%;
                        text-align: center;
                    }
                }
            }
        }
        &-snapshots {
            width: 100%;
            display: flex;
            flex-direction: column;
            padding: 10px 0;
            a, p {
                margin: 0 0 5px 0;
            }
            p {
                color: $validation-font-red;
            }
        }
        &-emails {
            display: flex;
            max-width: 100%;
            padding: 10px 0 15px 0;
            select {
                width: calc(100% - 50px);
                background-color: $sprout-gray-2;
            }
            button {
                width: 50px;
                background-color: $sprout-blue;
                color: white;
            }
            select, button {
                border: none;
                padding: 5px;
            }
        }
        &-tags {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 100%;
            height: 100%;
            margin-top: 10px;
            p {
                margin-top: 0;
            }
            ul {
                display: flex;
                flex-wrap: wrap;
                margin: 0 0 10px 0;
                padding: 0;
                list-style: none;
                // tag icons
                li {
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: $sprout-blue;
                    padding: 5px 5px 5px 14px;
                    border-radius: 2px 0 0 2px;
                    height: 24px;
                    margin: 0px 10px 5px 10px;
                    small {
                        margin: 0;
                        color: white;
                    }
                    button {
                        background-color: transparent;
                        border: none;
                        color: white;
                        padding: 0 0 0 8px;
                        font-size: 12px;
                        &:hover {
                            color: $validation-font-red;
                        }
                    }
                    &:before {
                        content:"\A";
                        border-style: solid;
                        border-width: 2px 2px;
                        position: absolute;
                        left: 4px;
                        margin: auto 0;
                        top: 10px;
                        border-color: white;
                        border-radius: 50%;
                        z-index: 1;
                    }
                    &:after {
                        content:"\A";
                        border-style: solid;
                        border-width: 12px 12px;
                        position: absolute;
                        right: -24px;
                        top: 0px;
                        border-color: transparent transparent transparent $sprout-blue;
                    }
                }
            }
            form {
                input {
                    margin-left: 5px;
                }
                button {
                    margin-left: 5px;
                    color: $sprout-blue;
                    background: none;
                    border: none;
                    &:hover {
                        color: $validation-font-green;
                    }
                    &:disabled {
                        color: gray;
                    }
                }
            }
        }
    }
}

.coachingLibrary-card {
    position: relative;
    width: 235px;
    height: 300px;
    margin-right: 40px;
    margin-bottom: 40px;
    box-sizing: border-box;
    box-shadow: 0 1px 5px rgba(0,0,0,.5);
    border: 0;
    margin-bottom: 20px;
    margin-right: 20px;
    float: left;
    line-height: 1.5em;
    overflow: hidden;
    text-decoration: none;
    img {
        float: none;
        width: 100%;
        // height: 88%;
    }
    &-content {
        background-color: white;
        border-top: 1px solid #c8c8c8;
        transition: all .4s ease-out;
        min-height: 70px;
        height: 152px;
        position: absolute;
        bottom: 0;
        padding: 5px 20px;
        text-align: left;
        width: 100%;
        strong {
            color: $sprout-blue;
        }
        h4 {
            color: $sprout-font-gray;
            margin: 5px 0 0 0;
            font-weight: $fontweight-regular;
        }
    }
    &-description {
        @include absolute-cover;
        width: 100%;
        height: 100%;
        color: white;
        background-color: $sprout-blue;
        padding: 0px 20px 20px;
        text-align: left;
        &-cta {
            @include flex-center-all;
            position: absolute;
            background-color: $sprout-blue;
            bottom: 20px;
            left: 0;
            right: 0;
            padding-top: 10px;
        }
        button {
            margin: 0 auto;
            border: 1px solid white;
            background-color: $sprout-blue;
            color: white;
            font-weight: $fontweight-semibold;
            padding: 10px 15px;
        }
    }
}

.participant-card {
    display: flex;
    position: relative;
    background-color: white;
    margin-bottom: 20px;
    border-radius: 10px;
    padding-left: 25px; // offest blue bar
    width: calc(100% - 30px);
    margin-right: 30px;
    &-info {
        padding: 10px 0;
        width: 100%;
        max-width: 265px;
        min-width: 265px;
        word-wrap: break-word;
        &-body {
            text-align: left;
            border-right: 1px solid $sprout-dark-gray-3;
            padding: 0 20px;
            strong {
                color: $sprout-dark-blue;
                margin: 0 0 5px 0;
            }
            p {
                color: $sprout-dark-blue;
                margin: 0 0 2px 0;
            }
        }
        &-select-user {
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 25px;
            background-color: $sprout-blue;
            padding: 5px;
            border-radius: 10px 0 0 10px;
            padding: 13px 0;
        }
        &-tabs {
            display: flex;
            flex-wrap: wrap;
            max-width: 200px;
            button {
                display: flex;
                // justify-content: center;
                align-items: center;
                width: 85px;
                height: 35px;
                margin: 5px 5px 5px 0;
                border-radius: 5px;
                background-color: $sprout-dark-gray-3;
                border: none;
                svg {
                    color: white;
                    margin-left: 2px;
                }
                small {
                    text-align: left;
                    margin-left: 5px;
                }
                &:hover, &.participant-card-info-tabs-active {
                    color: white;
                    background-color: $sprout-teal;
                }
            }
        }
    }
    &-content {
        padding: 10px 20px;
        width: 100%;
        &-header {
            display: flex;
            color: white;
            background-color: $sprout-teal;
            padding: 5px 10px;
            width: 100%;
            margin-bottom: 10px;
            strong {
                margin-left: 5px;
            }
        }
        &-body {
            margin-left: 10px;
            text-align: left;
            p {
                margin: 0 0 5px 0;
            }
        }
    }
    &-options {
        position: absolute;
        right: -30px;
        top: 10px;
        display: flex;
        flex-direction: column;
        button {
            max-width: 30px;
            margin-bottom: 5px;
        }
    }
}
