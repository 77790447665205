.notification-banner {
    position: fixed;
    top: 0;
    z-index: 7;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 50px;
    background-color: $r-secondary-2;
    &-content {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 90vw;
        p {
            margin: 0  0 0 15px;
        }
    }
    &-close {
        position: absolute;
        right: 50px;
    }
}

@media (max-width: 600px) {
    .notification-banner {
        justify-content: flex-start;
        &-content {
            margin-left: 2px;
            p {
                margin: 0  0 0 10px;
            }
        }
        &-close {
            right: 20px;
        }
    }
}
