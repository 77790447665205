.snapshot-quiz-wrap {
    display: flex;
    flex-direction: column;
    margin: 70px 0px 175px;
    border: 2px solid $light-grey;
}
.snapshot-quiz {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    height: 100%;
    width: 100%;

    &-arrow {
        display: flex;
        justify-content: space-between;
        width: 72%;
        margin: 10px 0;
        &-back {
            background: none;
            background-color: $primary-color;
            border: none;
            font-size: 16px;
            padding: 10px;
            color: white;
        }
        &-next {
            background: none;
            background-color: $primary-color;
            border: none;
            font-size: 16px;
            padding: 10px;
            color: white;
            &:disabled {
                background-color: grey;
            }
        }
        &-submit {
            background: $primary-color;
            border: none;
            color: white;
            padding: 5px 10px;
            font-size: 18px;
            &:hover {
                background-color: green;
            }
            &:disabled {
                background-color: $grey;
            }
        }
    }
    &-progress {
        display: flex;
        justify-content: flex-end;
        background-color: white;
        background-image: linear-gradient(to right, red , yellow, green);
        width: 80%;
        margin-bottom: 12px;
        border-radius: 1em;
        &-wrap {
            @include flex-content-center;
            flex-direction: column;
            background-color: $primary-color;
        }
        &-count {
            margin: 12px 0 -18px 0;
            z-index: 999;
            color: black;
        }
        &-inner {
            display: flex;
            background-color: white;
            border-radius: 1em;
            background: radial-gradient(circle at left, transparent 10px, white 1px, #FFFFFF 100%) right;
            background-repeat:no-repeat;
            p {
                margin: 9px 0px;
            }
        }
    }
    &-content {
        width: 90%;
        &-header {
            display: flex;
            flex-direction: column;
            text-align: left;
            padding: 20px;
            background-color: $very-light-grey;
            P {
                font-size: 15px;;
                margin: 0;
            }
            h3 {
                font-size: 20px;
                margin: 0 20px 5px 0;
            }
        }
        &-card {
            &-invisible {
                display: none;
            }
            &-survey {
                display: flex;
                flex-direction: column;
                align-items: center;
                &-question {
                    display: flex;
                    align-items: center;
                    width: 90%;
                    border-bottom: 1px solid black;
                    margin-top: 10px;
                    padding-bottom: 10px;
                    height: 60px;
                    p {
                        width: 100%;
                        margin:0
                    }
                }
                &-answers {
                    display: flex;
                    flex-direction: column;
                    width: 80%;
                    margin: 10px 0 20px 0;
                    &-option {
                        background-color: $primary-color;
                        color: white;
                        text-align: center;
                        margin: 5px 0;
                        padding: 5px;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}

.lms-snapshot {
    &-quiz-content {
        width: 100%;
    }
    &-question{
        &-wrapper {
            width: 80vw;
            display: flex;
            flex-direction: column; 
            justify-content: center; 
            align-content: center; 
            align-items: center;
        }
        &-text {
            font-size: 28px;
        }
    }
    &-answer {
        &-wrapper {
            width: 100%;
            margin-top: 20px;
        }
        &-container {
            background: linear-gradient(to right, #99CCCC 0%, #336699 100%); 
            width: 100%;
            display: flex;
            justify-content: space-between;
            p {
                margin: 0
            }
        }
        &-option {
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-right: 2px solid white;
            border-left: 2px solid white;
            border-top: none;
            border-bottom: none;
            background: none;
            color: white;
            font-size: 18px;
            padding: 5px;
            cursor: pointer;
            width: 100%;

            &-selected {
                color: white;
                background-color: #FCC12B;
                height: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-right: 2px solid white;
                border-left: 2px solid white;
                border-top: none;
                border-bottom: none;
                font-size: 18px;
                padding: 5px;
                cursor: pointer;
                width: 100%;
            }
        }
    }
    &-answer-labels {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: 10;
    }
    &-answer-label-text {
        width: 10%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
}
.snapshot-quiz-selected {
    background-color: green;
    color: white;
    text-align: center;
    margin: 5px 0;
    padding: 5px;
    cursor: pointer;
}
@media only screen and (max-width: 900px) {
    .snapshot-quiz-wrap {
        margin: 70px 0px 300px;
    }
    .snapshot-quiz {
        width: 95vw;
    }
}
@media only screen and (max-width: 600px) {
    .snapshot-quiz-wrap {
        border: none;
    }
    .snapshot-quiz {
        width: 100vw;
        &-content {
            &-header {
                text-align: center;
            }
        }
    }
    .lms-snapshot {
        &-quiz-content {
            width: 100%;
        }
        &-question{
            &-wrapper {
                width: 100vw;
            }
            &-text {
                font-size: 22px;
            }
        }
        &-answer {
            &-wrapper {
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0;
            }
            &-container {
                background: linear-gradient(to bottom, #99CCCC 0%, #336699 100%); 
                width: 75%;
                flex-direction: column;
                p {
                    font-size: 10px;
                    margin-top: 2px;
                }
            }
            &-option {
                display: flex;
                flex-direction: column;
                height: 35px;
                border-right: none;
                border-left: none;
                border-bottom:  5px solid white;
                font-size: 14px;
                width: auto;
                &-selected {
                    display: flex;
                    flex-direction: column;
                    height: 35px;
                    border-right: none;
                    border-left: none;
                    border-bottom:  5px solid white;
                    font-size: 14px;
                    width: auto;
                }
            }
        }
        &-answer-labels {
            display: none;
        }
    }
}
