.not-found-page-body {
    @include flex-content-center;
    flex-direction: column;
    width: 100vw;
    height: 100%;
}
.not-found-page-wrap {
    @include flex-content-center;
    flex-direction: column;
    width: 1180px;
    margin-top: 140px;
    margin-bottom: 70px;
}

.not-found-page-title{
    color: $primary-color;
    font-family: $font;
}

.not-found-text-option-title{
    color: $primary-color;
    font-family: $font;
}

.not-found-text-option-container{
    color: $primary-color;
    font-family: $font;
}

.not-found-text-option{
    margin: 20px;
}

.not-found-page-random-dog-image{
    margin-top: 20px;
    height: 400px;
    width: auto;
    padding: 10px;
    border: solid 2px #EFEFEF;
    border-radius: 5px;
}
