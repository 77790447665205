.img-fluid {
    // always take up the entire container and resize with it
    @include size(100%, auto);
}

.img-section {
    @include size(100%, auto);
    @include size-max(600px, 360px);
    @include breakpoint('lg'){
        @include size-max(unset);
    }
}

.img-icons-lg {
    @include size(50px);
    @include breakpoint('sm'){
        @include size(75px);
    }
    @include breakpoint('xl'){
        @include size(100px);
    }
}