.coordinator-zone {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    .header-semicircle {
        margin-bottom: 34px;
    }
}

.cz-form {
    &-file-access {
        @include flex-column;
        width: 200px;
        margin: 0 0 20px 0;
        border: 1px solid $sprout-dark-blue;
        padding: 20px;
        input {
            padding: 5px;
            margin: 10px 0;
        }
    }
    &-promo {
        @include flex-column;
        align-items: center;
        border: 1px solid $sprout-dark-blue;
        padding: 20px;
        label {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 0 0 10px;
            width: 100%;
            input {
                margin-left: 10px;
            }
        }
        &-question {
            cursor: pointer; 
            margin-left: 5px;
            padding: 2px;
            background-color: yellow;
        }
        &-buttons {
            display: flex;
            button {
                margin: 0 10px;
            }
        }
    }
    &-registration-links {
        @include flex-align-center-column;
        padding: 0 30px 30px 30px;
        &-search {
            flex: 1;
            width: 100%;
            max-width: 800px;
            input {
                width: 100%;
            }
        }
        &-radio {
            flex: 1;
            margin-bottom: 10px;
            label {
                display: flex;
                justify-content: space-between;
                margin: 0 10px 5px;
                width: 170px;
                input {
                    margin-left: 5px;
                }
            }
        }
    }
}

.cz-table {
    border-collapse: collapse;
    margin: 20px;
    width: calc(100% - 40px);
    tr:nth-child(even) {
        background-color: #eee;
    }
    th, td {
        text-align: left;
        padding: 10px 5px;
    }
    thead {
        border-bottom: 1px solid black;
    }
    tbody {
        tr {
            th {
                text-align: center;
            }
        }
        tr > th {
            background-color: $sprout-light-blue;
        }
    }
}

.file-access-list {
    list-style: none;
    text-align: left;
    padding: 0;
    margin: 0;
    width: 100%;
    &-category {
        background-color: $sprout-gray;
        padding: 10px 20px;
        margin: 20px;
        border-radius: 5px;
        width: calc(100% - 40px);
        &-header {
            display: flex;
            justify-content: space-between;
            h3 {
                margin: 20px 0 ;
            }
            button {
                background-color: transparent;
                padding: 0;
                margin: 0;
                border: none;
                outline: none;
            }
        }
        &-resources {
            list-style: none;
            text-align: left;
            padding: 0;
            &-item {
                background-color: white;
                padding: 10px;
                border-radius: 5px;
                border: 1px solid $sprout-blue;
                &:not(:last-child){
                    margin-bottom: 20px;
                }
                button {
                    background-color: transparent;
                    padding: 0;
                    margin: 0;
                    border: none;
                    outline: none;
                    color: $sprout-blue;
                }
            }

        }
    }
}

.cz-registration-link {
    @include flex-center-all;
    font-size: 17px;
    word-break: break-all;
    margin: 5px 5px 30px 5px;
    p {
        margin: 0;
        padding: 5px;
        color: $validation-font-red;
        background-color: $validation-background-red;
    }
}

.cz-landing-links {
    display: flex;
    a {
        margin: 0 20px;
    }
}
