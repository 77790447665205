.account-settings {
    display: flex;
    justify-content: center;
    width: 50vw;

    &-header {
        display: flex;
        align-items: center;
        h1 {
            margin-left: 15px;
        }
    }

    &-body {
        width: 50%;
        section {
            display: flex;
            border: 2px solid $light-grey;
            margin: 30px 0px;
            h3 {
                margin: 10px;
            }
        }
    }

    &-content {
        @include flex-space-between;
        width: 75%;
        flex-wrap: wrap;
        button {
            margin-right: 10px;
            background-color: $primary-color;
            color: white;
            border: none;
            padding: 5px;
        }
        p {
            margin-left: 5px;
            font-size: 12px;
            align-self: center;
        }
        &-header {
            background-color: $primary-color;
            color: white;
            width: 25%;
            text-align: left;
            border-right: 1px solid black;
        }

        &-info {
            display: flex;
            justify-content: space-between;
            width: 100%;
            text-align: left;
            margin-top: 5px;
            button {
                @include flex-content-center;
                color: white;
                border: 1px solid white;
                background: $primary-color;
                height: 30px;
                width: 70px;
                cursor: pointer;
                margin-right: 10px;
            }
            h6 {
                margin: 5px;
            }
            p {
                margin: 0px 0px 5px 5px;
            }
        }
    }
}

.scaleDown {
    transform: scale(.75)
}

@media (max-width : 480px) {
    .account-settings {
        &-body {
            width: 95%;
            section {
                flex-direction: column;
                margin: 0 0 30px 0;
                padding: 10px;
                h3 {
                    margin: 10px;
                }
            }
        }
    
        &-content {  
            @include flex-content-center;
            width: 96%;
            padding: 2%;
            &-header {
                width: 100%;
                text-align: center;
                border-right: none;
                border-bottom: 1px solid black;
            }
            &-info {
                display: flex;
                justify-content: space-between;
                width: 100%;
                button {
                    margin-right: 0px;
                }
            }
        }
    }
}