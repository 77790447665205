@keyframes rotate-keyframes {
    from {
        transform: rotate(-90deg);
    }
    to {
        transform: rotate(0deg);
    }
}

// Used in DropdownAnimate
@keyframes growDown {
    0% {
        transform: scaleY(0)
    }
    100% {
        transform: scaleY(1)
    }
}

// Used in DropdownAnimate
@keyframes growUp {
    0% {
        transform: scaleY(1);
    }
    100% {
        transform: scaleY(0);
        opacity: 0; 
        height: 0px;
    }
}