.highfives-carousel {
    @include flex-center-all;
    width: 100%;
    height: 60vh;
    overflow: auto
}

.highfives-carousel-wrap {
    flex-direction: column;
    justify-content: center;
    @include flex-center-all;
    position: fixed;
    background-color: white;
    padding:0;
    margin:0;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    z-index: 3;
}

.highfives-carousel__slides {
    margin: 0px;
    padding: 0px;
    width: 500px;
}

.highfives-carousel__slide {
    margin-right: auto;
    margin-left: auto;
    display: none;
    list-style-type: none;
    text-align: center;
    &--active {
        display: block;
    }
}

.highfives-carousel-button-toggle {
    position: fixed;
    top: 0;
    left: 0;
    background-color: $sprout-blue;
    color: white;
    width: 60px;
    height: 35px;
    border: 1px solid $sprout-blue;
    border-radius: 10px;
    font-size: 14px;
    font-family: $typeface-montserrat;
    font-weight: 600;
    margin: 5px;

    &:focus {
        outline: none;
    }
    &:hover {
        cursor: pointer;
    }
}

@media (max-width: 767px) {
    .highfives-carousel {
        height: auto;
    } 

    .highfives-carousel__slides {
        width: 90%;
    }
}
