.dashboard {
    font-family: $typeface-montserrat;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px 0;
    @include breakpoint('md') {
        @include flex-center-all;
        flex-direction: row;
    }
    a {
        text-decoration: none; 
        color: white;
    }
    img {
        margin-top: 10px;
        width: 90vw;
        margin-bottom: 10px;
        @include breakpoint('sm') {
            width: 450px;
            margin-bottom: 10px;
        }
        @include breakpoint('md') {
            width: 48vw;
        }
        @include breakpoint('lg') {
        width: 55vw;
        }
    }
    &-cards {
        @include flex-center-all;
        flex-direction: column;
        @include breakpoint('lg') {
            width: 40vw;
        }
    }
    &-card {
        @include flex-center-all;
        border-radius: 10px; 
        background-image: linear-gradient(to bottom, #6F8DC1, #2E5597);
        box-shadow: 4px 4px 8px #00000072;
        margin: 10px 0px 10px 0px;
        transition: 0.3s ease-in-out;
        height: 40px;
        width: 90vw;
        @include breakpoint('sm') {
            width: 375px;
            height: 40px;
        }
        @include breakpoint('lg') {
            width: 425px; 
            height: 50px;
        }
        @include breakpoint('xl') {
            width: 425px; 
            height: 50px;
            }
        &:hover {
            box-shadow: 5px 5px 10px #000000ab;
        }
        h3 {
            width: auto;
            font-size: 16px;
            @include breakpoint('lg') {
                font-size: 18px;
            }
        }
    }
}

.dashboard-error-wrapper{
    margin-top: 5%;
    font-weight: 500;
    font-size: 1.3em;
    width: 70%;
}

.dashboard-contact-link{
    color: $sprout-blue;
}
