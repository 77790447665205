.awards {
	display: flex;
	flex-direction: column;
	min-height: calc(100vh - $footer-height);
	margin: 0;
	background-color: $sprout-gray-2;
}

// Awards List Page
.awards-list-wrap {
	display: flex;
	flex-direction: column-reverse;
	justify-content: space-evenly;
	@include breakpoint('lg') {
		flex-direction: row;
	}
}

.awards-bar {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 25px;
	&-overview {
		background-color: white;
		display: flex;
		padding: 10px;
		margin-top: 16px;
		div {
			max-width: 100px;
			margin: 0 10px;
			span {
				color: $sprout-light-blue;
			}
			p {
				margin: 0;
			}
		}
	}
	@include breakpoint('lg') {
		margin: 30px 25px 25px;
		&-overview {
			margin-top: 29px;
		}
	}
}

.awards-list {
	flex: 1;
	display: flex;
	flex-direction: column;
	margin: 25px;
	&-views {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		margin-bottom: 11px;
		a {
			margin: 5px 10px;
		}
	}

	@include breakpoint('lg') {
		max-width: 800px;
	}
}

.speech-bubble {
	box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
	margin: 1rem;
	padding: 1rem;
	margin-bottom: 80px;
	position: relative;
	font-size: 1.2rem;
	font-family: $typeface-montserrat;
	font-weight: $fontweight-regular;
	text-align: left;
	background: white;
	color: black;
	header {
		@include flex-wrap;
		h2 {
			margin: 0 6px 8px 0;
			font-size: 1.1rem;
		}
	}
  button {
    background-color: white;
    color: $sprout-font-gray;
    border-width: 1px;
    border-style: solid;
    border-color: white;
    padding: 5px 10px;
    font-size: 14px;
  }
	&::before {
		content: '\A';
		border-style: solid;
		border-width: 12px 12px;
		position: absolute;
		right: auto;
		bottom: -24px;
		border-color: white white transparent transparent;
	}
	blockquote {
		font-size: 1rem;
		margin: 0 6px 0 0;
	}
	&-comments {
		cursor: pointer;
		margin-right: 15px;
		&:hover {
			color: goldenrod;
		}
	}
	&-addComment {
		cursor: pointer;
		&:hover {
			color: goldenrod;
		}
	}
	@include breakpoint('sm') {
		padding: 1.5rem 2rem 1.5rem 1rem;
		.speech-bubble-wrap {
			flex-direction: row;
			img {
				margin: 0 10px 0 0;
				align-self: flex-start;
			}
		}
	}
}

.speech-bubble cite {
	color: $sprout-font-gray;
	position: absolute;
	text-align: left;
	bottom: -2.75rem;
	left: 4.5rem;
	font-size: 1rem;
	font-family: $typeface-montserrat;
	font-weight: $fontweight-semibold;
	letter-spacing: 0.5px;
	width: 200px;
}

.speech-bubble-wrap {
	display: flex;
	flex-direction: column;
	margin-bottom: 10px;
	@include breakpoint('sm') {
		flex-direction: row;
	}
}

.speech-bubble-icon {
	width: 100px;
	height: 100px;
	align-self: center;
	margin: 0 0 10px 0;
	color: $sprout-light-blue;
	@include breakpoint('sm') {
		margin: 0 10px 0 0;
		align-self: flex-start;
	}
}

.award-comment {
	@include flex-column;
	margin-top: 10px;
	margin-bottom: 10px;
	padding: 4px;
	border-radius: 5px;
	font-family: $typeface-calibri;
	font-size: 15px;
	font-weight: 400;
	color: $sprout-font-gray;
	text-align: left;
	p {
		margin: 0 0 10px 0;
		font-size: 16px;
	}
}

.award-give-comment {
  display: flex;
  flex-direction: column;
  button {
    margin-top: 10px;
    align-self: center;
  }
}

.award-highfive {
  &.speech-bubble {
    background: $a2b-blue-bg;
    h2 {
      color: $a2b-blue;
    }
    &::before {
      border-color: $a2b-blue-bg-shadow $a2b-blue-bg-shadow transparent
        transparent;
    }
  }
  .award-comment {
    background: $a2b-blue-bg-shadow;
  }

  button {
    border-color: $a2b-blue-bg-shadow;
    &:hover, &.active {
      background-color: $a2b-blue-bg-shadow;
    }
  }
}

.award-valueCoin {
  &.speech-bubble {
    background: $a2b-orange-bg;
    h2 {
      color: $a2b-orange;
    }
    &::before {
      border-color: $a2b-orange-bg-shadow $a2b-orange-bg-shadow transparent
        transparent;
    }
  }
  .award-comment {
    background: $a2b-orange-bg-shadow;
  }

  button {
    border-color: $a2b-orange-bg-shadow;
    &:hover, &.active {
      background-color: $a2b-orange-bg-shadow;
    }
  }
}

// Select Rewards Page
.awards-nominations {
	display: flex;
	flex-direction: column;
	margin: 10px 0;
	background-color: white;
	padding: 10px;
	border-radius: 20px;
	box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
	@include breakpoint('lg') {
		flex-direction: row;
		padding: 50px;
	}
	&-wrap {
		display: flex;
		align-items: center;
		justify-content: center;
		flex: 1;
		padding: 20px 5px;
		background-color: $sprout-gray-2;
		background: linear-gradient(
			180deg,
			rgba(225, 238, 251, 1) 0%,
			rgba(186, 214, 254, 1) 50%,
			#fdfdfd 50%,
			#fdfdfd 100%
		);
		@include breakpoint('lg') {
			padding: 20px 5vw;
		}
	}
	section {
		display: flex;
		flex-direction: column;
		flex: 1;
		margin-bottom: 20px;
		padding: 20px;
		text-align: left;
		border-radius: 5%;
		@include breakpoint('lg') {
			min-height: 450px;
			margin: 0 10px;
			padding: 30px;
		}

		h2 {
			margin: 0 0 10px 0;
		}

		p {
			margin: 0 0 4px 0;
		}

		ul {
			margin-top: 0;
			text-align: left;
			padding: 0 0 0 20px;
		}

		a {
			align-self: center;
			justify-self: flex-end;
			margin-top: 20px;
			@include breakpoint('lg') {
				margin-top: auto;
			}
		}
	}

	&-highfive {
		h2 {
			color: $a2b-blue;
		}
		background-color: $a2b-blue-bg;
		background: linear-gradient(
			180deg,
			$a2b-blue-bg 60%,
			rgba(255, 255, 255, 1) 100%
		);
	}
	&-value {
		h2 {
			color: $a2b-orange;
		}
		background-color: $a2b-orange-bg;
		background: linear-gradient(
			180deg,
			$a2b-orange-bg 60%,
			rgba(255, 255, 255, 1) 100%
		);
	}
	&-bouncehit {
		h2 {
			color: $a2b-purple;
		}
		background-color: $a2b-purple-bg;
		background: linear-gradient(
			180deg,
			$a2b-purple-bg 60%,
			rgba(255, 255, 255, 1) 100%
		);
	}
}

// Forms
.awards-form {
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 800px;
	margin: 10px 0;
	background-color: white;
	border-radius: 20px;
	box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
	padding: 35px 30px;
	text-align: left;
	&-back {
		position: absolute;
		color: black;
		top: 10px;
		right: 20px;
		text-decoration: none;
		&:hover {
			color: darkred;
		}
	}
	&-message {
		display: flex;
		flex-direction: column;
		margin: 35px 0;
		textarea {
			margin-top: 8px;
			padding: 10px;
		}
	}
	&-error {
		position: absolute;
		bottom: 0;
		align-self: center;
		margin: 0px auto 5px auto;
		text-align: center;
		color: red;
	}
	&-success {
		position: absolute;
		bottom: 0;
		align-self: center;
		margin: 0px auto 5px auto;
		text-align: center;
		color: green;
	}
	button {
		align-self: center;
		margin-bottom: 10px;
		@include breakpoint('md') {
			margin-bottom: 0;
		}
	}
	textarea {
		min-height: 150px;
	}
}

.awards-form-coins {
	margin-top: 35px;
	&-select {
		display: flex;
		flex-wrap: wrap;
		label {
			margin: 5px 0 0 0 !important;
		}
		/* HIDE RADIO */
		[type='radio'] {
			position: absolute;
			opacity: 0;
			width: 0;
			height: 0;
		}

		/* IMAGE STYLES */
		[type='radio'] + img {
			width: 100px;
			height: 100px;
			margin: 5px;
			cursor: pointer;
		}

		/* CHECKED STYLES */
		[type='radio']:checked + img {
			outline: 3px solid gold;
			border-radius: 50%;
		}
	}
}
