.error-warning{
	color: $errorRed
}

.under-construction-ie {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	background-image: url(../../Assets/images/UnderConstruction.jpg);
	background-color: #f1f3f2;
	background-position: center; 
	background-repeat: no-repeat;
	background-size: contain;
	height: 70vh;
	width: 80vw;
	p {
		font-family: $whitney-medium;
		font-size: 22px;
	}
}

input.error,
select.error,
textarea.error {
	border-color: $errorRed;
	&:valid {
		border-color: inherit;
	}
}

.violator-text {
	color: $errorRed
}

.order-did-not-process {
    width: 75%;
    display: flex;
    text-align: center;
    &-wrap {
		display: flex;
		justify-content: center;
		background-color: white;
    }
}

//Search bars
.search-input {
	box-sizing: border-box;
	width: 100%;
	background-position: 14px 12px;
	background-repeat: no-repeat;
	font-size: 18px;
	z-index: 1;
	border: 2px solid #b7b7b7;
}

.search-input:focus {outline: 3px solid #ddd;}

.dropdown {
	position: relative;
	display: inline-block;
	width: 100%;
	button {
		background-color: $primary-color;
		border-color: $primary-color;
		font-size: 14px;
		color: white;
		border: none;
		&:hover {
			cursor: pointer;
		}
		&:focus {
			outline: none;
		}
	}
}

.dropdown-content {
	position: absolute;
	background-color: #f6f6f6;
	box-shadow: -5px 5px 14px rgba(0, 0, 0, 0.2);
	overflow: auto;
	width: 100%;
	z-index: 2;
	overflow: hidden;
}

.global-list-wrapper {
	list-style: none;
	margin: 0;
	overflow-x: hidden;
	overflow-y: scroll;
	background-color: white;
	max-height: 205px;
	padding: 5px;
	width: 100%;
	li {
		font-size: 18px;
		color: $dark-grey;
		text-align: left;
		&:hover {
			background-color: #C7C7C7;
			cursor: pointer;
		}
	}
}

@media (max-width: 768px) {
    .under-construction-ie {
		height: 80vh;
		width: 100vw;
		p {
			font-family: $whitney-medium;
			font-size: 22px;
			padding: 20px;
		}
	}
}
