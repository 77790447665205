.navbar-header-body {
    position: fixed;
    top: 62px;
    left: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100vw;
    z-index: 2;
}

.navbar-snapshot {
    fill: white;
    stroke: white;
    stroke-width: 3;
}

.virtual-delivery-nav {
    @include flex-content-center;
    width: 98vw;
    height: 75px;
    margin-top: 40px;
    background-color: #6edbd5;
    color: white;
    padding: 0 1vw;
    img {
        height: 50px;
    }
    p {
        font-family: $whitney-semibold; 
        font-size: 22px; 
        margin: 12px;
    }
    button {
        font-size: 14px;
        background-color: #f5c945;
        height: 36px;
        border: none;
        padding: 0px 16px;
        margin-left: 15px;
        cursor: pointer;
        a {
            text-decoration: none;
            color: white
        }
    }
}

.nav-wrap {
    @include flex-content-center;
    position: fixed;
    left: 0;
    width: 100vw;
    background: $primary-color;
    font-weight: 400;
    font-size: 18px;
    z-index: 10;
    &.icons {
        padding: 5px;
        svg {
            max-height: 24px;
        }
    }
}

.nav {
    @include flex-space-between;
    width: 950px;
    height: 40px;
    margin: 0;
    padding: 0;
    list-style: none;

    &-item-active {
        border-bottom: solid 2px white;
    }
    
    &-item {
        display: flex;
        height: 38px;
        margin: 0;
        border-bottom: solid 2px transparent;
        transition: ease-in-out 0.2s;

        &:hover {
            border-bottom: solid 2px white;
        }
        a {
            @include flex-content-center;
            flex-direction: column;
            text-decoration: none;
            color: white;
            height: 40px;
            padding: 0 10px;
            font-size: 18px;
            font-family: $whitney-book;
            line-height: 40px;
        }
    }

    &-icons {
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        height: 38px;
        min-width: 104px;
        margin: 0;
        font-size: 18px;
        font-family: $whitney-book;
        svg {
            height: auto;
            width: auto;
        }
    }
}

.nav-hamburger {
    display: none;
    &-icon {
        display: none;
    }
}

@media (max-width: 900px) {
    .nav {
        width: 100%;
    }
}

@media(max-width: 830px) {
    .nav-icons {
        min-width: initial;
    }
}

@media (max-width: 650px) {
    .nav-wrap {
        display: none;
    }

    .virtual-delivery-nav {
        flex-direction: column;
        width: 100vw;
        height: 160px;
        margin-top: 0;

        p { 
            margin: 5px 12px;
        }
        
        img {
            display: none;
        }

        button {
            margin-left: 0px;
            height: 30px;
            padding: 0px 12px;
            cursor: pointer;
        }
    }
}