.participant-portal-homepage {
	display: flex;
	flex-direction: column;
	background-color: $sprout-gray-2;
	&-features {
		display: flex;
		flex-direction: column;
		height: 100%; 
		flex: 1;
		padding: 10px 10px 75px 10px;
		// hover on the below class names should match inline colors for svg
		&-resources {
			strong { color: black; }
			p { color: $sprout-font-gray; }
			&:hover {
				strong, p { color: $blue-semi-circle; }
			}
		}
		&-preferences {
			strong { color: black; }
			p { color: $sprout-font-gray; }
			&:hover {
				strong, p { color: $sprout-extra-dark-gold; }
			}
		}
		&-snapshots {
			strong { color: black; }
			p { color: $sprout-font-gray; }
			&:hover {
				strong, p { color: $red-semi-circle; }
			}
		}
		&-participants {
			strong { color: black; }
			p { color: $sprout-font-gray; }
			&:hover {
				strong, p { color: $teal-semi-circle; }
			}
		}
		header {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			padding-bottom: 30px;
			border-bottom: 2px solid $sprout-dark-gray;
			h2 {
				margin: 10px 0;
				color: black;
			}
			p {
				max-width: 460px;
				margin: 0;
				color: $sprout-font-gray;
			}
		}
		section {
			display: flex;
			justify-content: center;
			flex-wrap: wrap;
			a {
				display: flex;
				flex-direction: column;
				align-items: center;
				text-decoration: none;
				width: 175px;
				margin: 25px 5vw;
				cursor: pointer;
				transition: all .2s ease-in-out;
				svg {
					width: 100px;
					height: 100px;
				}
				strong {
					margin: 10px 0 5px 0;
				}
				p {
					margin: 0;
				}
				&:hover {
					transform: scale(1.1);
				}
			}
		}
	}
}
