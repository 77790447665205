.snapshot-header {
    display: flex;
    align-items: center;
    h1 {
        margin-left: 15px;
    }
}

.snapshot-home {
    margin-top: 55px;
    width: 65%;
    &-header {
        display: flex;
        margin-bottom: 10px;
        align-items: center;
        h2 {
            margin: 0;
        }
        svg path {
            fill: $primary-color;
        }
    }

    &-body {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        section {
            display: flex;
            flex-direction: column;
            width: 45%;
            border: 1px solid black;
            margin-bottom: 20px;

            header {
                display: flex;
                padding: 0 5%;
                align-items: center;
                background-color: $primary-color;
                width: 100%;
                height: 40px;
                color: white;
            }
        }

        &-content {
            display: flex;
            flex-direction: column;
            align-self: flex-start;
            justify-content: space-between;
            padding: 5%;
            text-align: left;
            height: 100%;
            p {
                margin-top: 0;
            }

            &-snapshotlist {
                display: flex;
                flex-direction: column;
                a {
                    text-decoration: none;
                    margin-bottom: 5px;
                    border-radius: 2px;
                    padding: 2px;
                    color: $primary-color;
                    background-color: white;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }

            button {
                display: flex;
                align-content: center;
                justify-content: center;
                width: 200px;
                padding: 5px 10px;
                margin-top: 15px;
                background-color: #d9534f;
                color: white;
                border: 1px solid white;
            }
        }
    }
}

.snapshot-survey {
    width: 95vw;

    &-back-link {
        color: $primary-color;
        margin-bottom: 20px;
    }

    &-header {
        @include flex-space-between;
        padding: 0 15px;
        background-color: $very-light-grey;
        p {
            color: $primary-color;
        }
        &-icon {
            svg {
                background-color: $primary-color;
            }
        }
        h3 {
            margin: 0 5px;
        }
        &-title {
            display: flex;
            align-items: center;
            margin: 5px 0;
        }
    }
    aside {
        display: flex;
        background-color: $primary-color;
        color: white;
        padding: 0 15px;
        margin-bottom: 10px;
    }

    &-form {
        form {
            min-height: 30vh;
        }
        table {
            width: 100%;
            border-collapse: collapse;
            empty-cells: hide;
            
            td, th {
                border: 1px solid $very-light-grey;
                height: 30px;
            }
            tr:nth-child(even) {
                background-color: #ddebff;
            }

            thead {
                th {
                    max-width: 20px;
                    font-size: 16px;
                    font-weight: 300;
                }
            }

            tbody {
                th {
                    width: 30vw;
                }
                p {
                    display: flex;
                    text-align: left;
                    flex-wrap: wrap;
                    margin: 0;
                    padding: 0px;
                    font-weight: 300;
                }
                textarea {
                    width: 90%;
                    resize: none;
                    height: 75px;
                    border: 1px solid $light-grey;
                } 
            }
        }
        table tr td:first-child {
            max-width: 130px;
        }
        button {
            width: auto;
            padding: 10px;
            border: 1px solid white;
            margin: 20px 0;
            color: white;
            background-color: $bright-yellow;
            font-size: 16px;
            cursor: pointer;
        }
    }

    &-success {
        @include flex-content-center;
        flex-direction: column;
        text-align: center;
        margin: 20px 0;
        h2 {
            color: $primary-color;
            width: 1000px;
        }
        p {
            margin: 10px;
            width: 1000px;
        }
        button {
            margin-top: 10px;
            background-color: $primary-color;
            color: white;
            font-size: 16px;
            padding: 5px;
            &:hover {
                cursor: pointer;
            }
        }
    }
    &-progress {
        width: 20vw;
        background-color: #eee;
        border-radius: .3em;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
        &-wrap {
            display: flex;
            justify-content: center;
            margin: 30px 0 -35px 0;
        }
        &-inner {
            background-color: $primary-color;
            color: #eee;
            padding: 5px 0;
            border-radius: .3em;
        }
    }
}

.snapshot-survey-navigation {
    display: flex;
    justify-content: space-between;
    
    &-button, button {
        margin: 0 0 10px 0;
        &:hover {
            background-color: goldenrod;
        }
        &:disabled {
            background-color: grey;
        }
    }
}

.snapshot-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 2px solid $dark-grey;
    background-image: linear-gradient(to top, #193F80, $primary-color, #476AA5);
    border-radius: 4px;
    margin: 5px 0px;
    padding: 10px;
    width: 290px;
    height: 290px;
    &-wrap { 
        width: 970px;
        margin-bottom: 20px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    }
    &-header {
        color: $dark-grey;
        font-family: $whitney-medium;
        font-size: 30px;
    }
    &-link {
        color: white;
        font-size: 20px;
        font-family: $whitney-medium;
    }
    &-cannot-view {
        color: white;
        font-size: 20px;
        font-family: $whitney-medium;
    }
    &-count {
        color: white;
        font-family: $whitney-light;
        margin: 10px 0px;
    }
    &-error {
        color: $errorRed;
        font-family: $whitney-light;
        margin: 5px 0px;
    }
    &-feedback-request {
        color: white;
    }
    &-feedback-button {
        align-self: center;
        width: 150px;
        margin-top: 20px;
        padding: 5px;
        border: 1px solid white;
        color: white;
        background-color: $primary-color;
        &:hover {
            color: $primary-color ;
            background-color: white;
        }
    }
}

@media(max-width: 1000px) {
    .snapshot-home {
        &-header {
            @include flex-content-center;
        }
        &-body {
            @include flex-content-center;
            flex-direction: column;
            flex-wrap: nowrap;
            section {
                width: 100%;
            }
            &-content {
                padding: 2% 5%;
            }
        }
    }

    .snapshot-survey {
        width: 100vw;
        aside {
            p {
                margin: 6px;
                font-size: 14px;
            }
        }
        &-form {
            &-textbox {
                display: none;
            }
            table, thead, tbody, th, td, tr {
                display: block;
            }
            table {
                thead {
                    tr {
                        display: none
                    }
                }
                tbody {
                    th {
                        width: 100%;
                        font-size: unset;                    
                        padding: 0 0 25px 0;
                        border: none;
                    }

                    p {               
                        padding: 0;
                        width: 92vw;
                    }

                    tr {
                        margin-bottom: 15px;
                        padding: 10px
                    }

                    td {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        width: 100vw;
                        padding: 5px 0; 
                        border: none;
                        height: auto;

                        textarea {
                            width: 100%;
                            height: 150px;
                        }
                    }
                }
            }        
            &-question {
                td:nth-of-type(1):after { content: "Strongly Disagree"; }
                td:nth-of-type(2):after { content: "Disagree"; }
                td:nth-of-type(3):after { content: "Somewhat Disagree"; }
                td:nth-of-type(4):after { content: "Neither Agree nor Disagree"; }
                td:nth-of-type(5):after { content: "Somwhat Agree"; }
                td:nth-of-type(6):after { content: "Agree"; }
                td:nth-of-type(7):after { content: "Strongly Agree"; } 
            }
            &-question31 {
                td:nth-of-type(1):after { content: "1"; }
                td:nth-of-type(2):after { content: "2"; }
                td:nth-of-type(3):after { content: "3"; }
                td:nth-of-type(4):after { content: "4"; }
                td:nth-of-type(5):after { content: "5"; }
                td:nth-of-type(6):after { content: "6"; }
                td:nth-of-type(7):after { content: "7"; } 
                td:nth-of-type(8):after { content: "8"; }
                td:nth-of-type(9):after { content: "9"; }
                td:nth-of-type(10):after { content: "10"; } 
            }
            &-textbox {
                max-width: 92vw;
                &:after {
                    display: none;
                }
            }
        }
        &-success {
            h2 {
                width: auto;
                margin: 0px 10px 15px 10px;
            }
            p {
                width: auto;
            }
        }
        &-progress {
            width: 50vw;
        }
    }
    .snapshot-card {
        &-wrap { 
            width: 650px;
            grid-template-columns: repeat(2, 1fr);
        }
    }
    .snapshot-survey-navigation {
        display: flex;
        justify-content: space-between;
        width: 95vw;
        margin: 0 2.5vw;
        &-button {
            &:hover {
                background-color: goldenrod;
            }
            &:disabled {
                background-color: grey;
            }
        }
    }
}

@media(max-width: 650px) {
    .snapshot-home {
        margin-top: 0px;
        width: 85%;
    }

    .snapshot-survey {
        aside {
            display: none;
        }
    }
    .snapshot-card {
        &-wrap { 
            width: 100vw;
            grid-template-columns: repeat(1, 1fr);
        }
    }
}