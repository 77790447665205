.participant-list-search {
    &-form {
        display: flex;
        justify-content: space-between;
        &-bar {
            width: 48%;
        }
    }
    &-header {
        color: $dark-grey;
        font-size: 32px;
        text-align: left;
        margin: 0px;
        &-wrap {
            @include flex-space-between;
            margin: 0px 0px 30px 0px;
            width: 85vw;
            max-width: 1366px;
        }
    }
    &-description {
        text-align: left;
        font-size: 18px;
        font-family: $whitney-semibold;
        color: $primary-color;
        margin-top: 10px;
    }
    &-return-to-session {
        color: $primary-color;
    }
    &-invite-toggle {
        &:hover {
            cursor: pointer;
            text-decoration: underline;
        }
        color: $primary-color;
        font-size: 16px;
        font-family: $whitney-medium;
    }
    &-clear {
        display: flex;
        justify-content: flex-end;
        margin-top: 2px;
        &-search {
            &:hover {
                cursor: pointer;
                text-decoration: underline;
            }
            color: $primary-color;
            font-size: 16px;
            font-family: $whitney-medium;
            text-align: right;
            width: 100px;
        }
    }
}

.next-and-back-button {
    @include flex-space-between;
    width: 85vw;
    max-width: 1366px;
    button {
        width: 100px;
        height: 35px;
        margin: 0px 4px;
        font-family: $whitney-book;
        background-color: $primary-color;
        color: white;
        font-size: 1.1em;
        transition: 0.2s ease-in-out;
        border: none;
        &:hover {
            cursor: pointer;
            background-color: #193F80;
        }
        &:focus {
            outline: none;
        }
    }
}

.company-list-search-main {
    position: absolute;
    margin-left: 20px;
    margin-top: -21px;
    width: 100%;
}

.participants-list {
    display: grid;
    grid-template-columns: 40px .75fr 1.25fr 1fr .5fr .8fr;
    grid-template-rows: 1fr 25px;
    margin: 10px 0px;
    height: 85px;
    width: 100%;
    justify-self: center;
    box-shadow: -5px 5px 16px #00000018;
    input {
        margin-left: 10px;
        display: flex;
        align-self: center;
    }
    &-participant {
        display: flex;
        align-self: center;
        color: #707070;
        span {
            display: flex;
            align-self: center;
            font-family: $whitney-bold;
            text-align: left;
        }
        div {
            font-size: 14px;
            color: $primary-color;
        }
        button {
            background-color: $primary-color;
            color: white;
            font-size: 14px;
            padding: 4px 8px;
            border: none;
            outline: none;
            &:hover {
                cursor: pointer;
            }
        }
        &-bursts {
            display: flex;
            align-items: center;
            button {
                margin: 0 5px;
            }
            p {
                margin: 0;
            }
        }
    }
    &-buttons {
        display: flex;
        justify-content: flex-end;
        height: 100%;
    }
    &-session {
        @include flex-content-center;
        grid-column: 1/7;
        border-top: 1px solid $primary-color;
        color: $primary-color;
        font-family: $whitney-semibold;
        font-size: 14px;
    }
    &-button {
        @include flex-content-center;
        font-size: 20px;
        margin: 0px 1px;
        color: white;
        border: none;
        width: 40px;
        height: 100%;
        cursor: pointer;
        &:focus {
            outline: none;
        }
        &.activate {
            background-color: $green;
            &:hover {
                background-color: #11681C;
            }
        }
        &.reset {
            background-color: $secondary-color;
            &:hover {
                background-color: #3977AB;
            }
        }
        &.edit {
            background-color: $primary-color;
            &:hover {
                background-color: #193F80;
            }
        }
        &.remove {
            background-color: $purple;
            &:hover {
                background-color: #6C195C;
            }
        }
        &.delete {
            background-color: $errorRed;
            &:hover {
                background-color: #D72315;
            }
        }
    }
    &-view-snapshots {
        text-align: left;
        align-self: center;
        color: $primary-color;
        font-size: 13px;
    }
    label {
        margin-right: 3px;
        color: $primary-color;
        width: 90px;
        text-align: left;
        font-family: $whitney-bold;
    }
    &-invite-cards-wrap {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: auto;
        grid-gap: 10px;
        border: 2px solid $primary-color;
        max-height: 430px;
        padding: 10px;
        overflow: scroll;
        &.session-details {
            width: 83.6vw;
            max-width: 1346px;
        }
    }
    &-invite-card {
        display: grid;
        grid-template-columns: 30px 1fr 30px;
        text-align: left;
        box-shadow: -5px 5px 14px rgba(0, 0, 0, 0.2);
        height: 96px;
        border: 2px solid transparent;
        transition: .3 s ease-in-out;
        &.cursor-pointer {
            &:hover {
                cursor: pointer;
            }
        }
        &-name {
            font-size: 22px;
            font-family: $whitney-semibold;
            color: $primary-color;
        }
        &-email {
            font-size: 18px;
            color: $dark-grey
        }
        &-burst {
            font-size: 18px;
            margin-top: 5px;
            color: $dark-grey;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            p {
                margin: 0 0 0 0;
                cursor: default;
            }
            button {
                background-color: $primary-color;
                color: white;
                font-size: 14px;
                padding: 4px 8px;
                border: none;
                outline: none;
                margin: 0px 8px;
                cursor: pointer;
                
                &:disabled {
                    background-color: gray;
                    cursor: default;
                }
            }
        }
        &-remove {
            @include flex-self-center;
            font-size: 22px;
            color: red;
            &:hover {
                color: #C60000;
                cursor: pointer;
            }
        }
        &-add {
            @include flex-self-center;
            font-size: 25px;
            color: green;
            &:hover {
                color: #006400;
                cursor: pointer;
            }
        }
        &.selected {
            border: 2px solid green;
        }
        &.new {
            margin-bottom: 10px;
        }
        div {
            @include flex-content-center;
            flex-direction: column;
        }
    }
}

.invite-existing {
    &-user-count {
        font-size: 18px;
        margin-top: 8px;
        text-align: right;
        font-family: $whitney-semibold;
        color: $primary-color;
    }
}

.invite-new-participant {
    &-wrap {
        display: flex;
        justify-content: center;
        height: 460px;
    }
    &-form, &-cards {
        width: 50%;
        margin: 0px 5px;
    }
    &-form {
        @include flex-content-center;
        box-shadow: -5px 5px 14px rgba(0, 0, 0, 0.2);
        border: 2px solid $primary-color;
        flex-direction: column;
        input {
            width: 70%;
            margin: 20px;
            font-size: 20px;
            outline: none;
            border: none;
            border-bottom: 2px solid $primary-color;
            transition: .3s ease-in-out;
            color: $primary-color;
            padding-bottom: 5px;
            &::placeholder {
                color: $dark-grey;
            }
            &:focus {
                border-bottom: 2px solid $bright-yellow;
                box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.15);
            }
        }
    }
    &-cards {
        display: flex;
        flex-direction: column;
        box-shadow: -5px 5px 14px rgba(0, 0, 0, 0.2);
        border: 2px solid $primary-color;
        width: 440px;
        padding: 10px;
        overflow: scroll;
        &.empty {
            @include flex-content-center;
        }
        &-none {
            font-size: 22px;
            font-family: $whitney-semibold;
            color: $primary-color;
        }
    }
    &-submit {
        min-height: 400px;
        display: flex;
        justify-content: center;
    }
}

.invite-participant-review-header {
    font-size: 28px;
    margin: 0px 0px 6px 0px;
    color: $primary-color;
}

.coaching-users-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 85vw;
    max-width: 1366px;
    margin-top: 10px;
}

.add-participant {
    width: 400px;
    height: 26px;
    font-size: 18px;
    padding-left: 7px;
    border: 1px solid $light-grey;
    
    &-company-search {
        display: flex;
        flex-direction: column;
    }

    &-form {
        display: flex;
        flex-direction: column;
        width: 525px;
        margin-top: 30px;
        padding: 20px;
        text-align: left;
        box-shadow: -5px 5px 22px #00000029;
        label {
            font-weight: bold;
            font-family: $whitney-medium;
            color: $primary-color;
            font-size: 20px;
        }
        &-fields {
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;
        }
        &-button {
            display: flex;
            justify-content: center;
            width: 525px;
            button {
                color: white;
                width: 120px;
                height: 35px;
                font-size: 16px;
                font-family: $whitney-medium;
                background-color: $primary-color;
                border: 1px solid $primary-color;
                border: 1px solid white;
                transition: 0.3s ease-in-out;
                &:focus {
                    outline: none;
                }
                &:hover {
                    cursor: pointer;
                    background-color: #193F80;
                    border: 1px solid $primary-color;
                }
            }
        }
    }
}

@media(max-width: 1365px) {
    .participants-list {
        display: flex;
        flex-direction: column;
        width: 600px;
        height: auto;
        padding: 15px;
    }
    .participants-list-participant {
        font-size: 1.2em;
        margin: 5px 0px;
        span {
            font-size: 1.25em;            
        }
        button {
            font-size: 1em;
            margin-bottom: 10px;
        }
        div {
            font-size: 1em;
            margin-bottom: 10px;
        }
        &-bursts {
            button {
                margin-bottom: 0;
            }
        }
    }
    .participants-list-buttons {
        justify-content: center;
        height: 50px;
    }
    .participants-list-button {
        margin: 0px 5px;
    }
    .participants-list-view-snapshots {
        margin-bottom: 20px;
    }
}

@media(max-width: 630px) {
    .participants-list-participant {
        font-size: 1.1em;
        span {
            font-size: 1.3em;            
        }
        button {
            font-size: .9em;
        }
    }
    .participants-list {
        width: 90vw;
    }
    .coaching-users-wrap {
        width: 90vw;
    }
    .participants-list-buttons {
        justify-content: center;
        height: 40px;
    }
    .next-and-back-button {        
        width: 95vw;
    }
    .participant-list-search {
        &-header {
            &-wrap {
                
                width: 100%;
            }
        }
    }
    .add-participant-form {
        width: 90vw;
    }
}

@media(max-width: 555px) {
    .add-participant {
        width: 100%;
    }
    .add-participant-company-search {
        width: 100%;
    }
    .add-participant-form {
        label {
            display: none;
        }
        &-button {
            width: 95%;
        }
        &-fields {
            justify-content: center;
            width: 95%;
        }
    }
}

@media(max-width: 455px) {
    .participants-list {
        width: 90vw;
    }
    .participant-list-search {
        &-header {
            font-size: 24px;
            margin-left: 5px;
        }
    }
    .participant-list-search-invite-toggle {
        margin-right: 5px;
    }
    .coaching-users-wrap {
        width: 100vw;
    }
}

@media(max-width: 330px) {
    .participant-list-search {
        &-header {
            font-size: 20px;
        }
    }
}