// includes filter button on mobile
.participants-sorting {
    @include flex-between;
    background-color: white;
    border-bottom: 1px solid gray;
    form {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 5px 0 5px 10px;
        border-right: 1px solid gray;
        width: 100%;
        label {
            @include flex-between-center;
            flex-wrap: wrap;
            width: 100%;
            max-width: 210px;
            select {
                color: $sprout-dark-blue;
                margin-left: 5px;
            }
        }
    }
    &-filters {
        border: none;
        width: 100px;
        color: $sprout-dark-blue;
        background-color: white;
        font-size: 16px;
        cursor: pointer;
        // FA icon
        svg {
            font-size: 10px;
            margin-left: 5px;
        }
    }
    @include breakpoint('lg') {
        border: none;
        form {
            flex-direction: row;
            align-items: flex-end;
            justify-content: flex-end;
            border: none;
            label {
                width: auto;
                max-width: unset;
                margin-right: 30px;
                select {
                    color: $sprout-dark-blue;
                    margin-left: 10px;
                }
            }
        }
    }
}

.participants-content-wrap {
    display: flex;
    flex-direction: column;
    @include breakpoint('lg') {
        flex-direction: row;
    }
}

// select multiple and users list. Card styles are in respective card file.
.participants-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 5px;
    width: calc(100vw - 30px);
    &-buttons {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-family: $typeface-calibri;
        button, a {
            width: 100%;
            color: white;
            border: 1px solid $sprout-dark-gray-2;
            margin-bottom: 5px;
            background-color: $sprout-dark-gray-4;
            padding: 5px 20px;
            text-decoration: none;
            font-size: 1rem;
            &:hover, &.participants-content-button-active {
                background-color: $gold-2;
                color: $sprout-font-gray;
            }
        }
    }
    &-multiple {
        width: 100%;
        h2 {
            margin: 0 0 0 0;
        }
        ul {
            list-style: none;
            padding: 0;
            width: 100%;
        }
        &-user {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: white;
            padding: 5px;
            border-radius: 5px;
            margin-bottom: 5px;
            span {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
            }
            p {
                margin: 0 8px 0 0;
            }
            button {
                border: none;
                background-color: white;
                &:hover {
                    color: $validation-font-red;
                }
            }
        }
        &-actions {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-top: 10px;
            select, button {
                min-height: 30px;
            }
            button {
                height: 30px;
                padding: 0 6px;
                background-color: white;
                border: none;
                color: $sprout-blue;
                border-radius: 0 5px 5px 0;
                &:hover:not([disabled]) {
                    background-color: $sprout-blue;
                    color: white
                }
                &:disabled {
                    background-color: gray;
                    color: white;
                }
            }
        }
    }
    &-users-list {
        width: 100%;
        max-width: 1000px;
        margin-top: 20px;
        &-add {
            border-radius: 50%;
            color: white;
            background-color: $sprout-blue;
            border: 1px solid $sprout-blue;
            height: 50px;
            width: 50px;
            margin-top: 20px;
            svg {
                @include size(75%);
            }
            &:hover {
                color: $sprout-blue;
                background-color: white;
            }
        }
    }
    @include breakpoint('sm') {
        padding: 20px;
        &-buttons {
            flex-direction: row;
            justify-content: center;
            width: 100%;
            max-width: 620px;
            button, a {
                flex: .33;
                border: 1px solid $sprout-dark-gray-2;
                padding: 5px 10px;
                &:nth-child(1){
                    border-radius: 8px 0 0 8px;
                }
                &:nth-child(3){
                    border-radius: 0 8px 8px 0;
                }
            }
        }
    }
}

// Filters and Searches
.participants-refining-hidden {
    bottom: -100%;
    height: 0px;
    overflow: hidden;
}

.participants-refining {
    position: fixed;
    bottom: 0;
    border-radius: 15px 15px 0 0;
    background-color: $sprout-gray-2;
    color: $sprout-dark-blue;
    z-index: 2;
    width: 100vw;
    padding: 10px;
    overflow: hidden;
    transition: all .5s;
    header {
        width: 100%;
        border-bottom: 1px solid $sprout-gray-2;
        margin: 10px 0 5px;
        h3 {
            margin: 0;
            text-transform: uppercase;
        }
    }
    &-close {
        color: $sprout-dark-blue;
        border: none;
        background-color: $sprout-gray-2;
        font-weight: $fontweight-medium;
        margin: 0 10px 5px 10px;
    }
    &-search {
        @include flex-center;
        flex-wrap: wrap-reverse;
        padding: 10px 0px;
        margin-bottom: 10px;
        border-bottom: 1px solid $sprout-gray-2;
        &-bar {
            display: flex;
            background-color: white;
            padding: 5px;
            border-radius: 10px;
            margin-bottom: 5px;
            // width: 100%;
            &-input, button {
                background-color: transparent;
                border: none;
                outline: none;
            }
            svg {
                font-size: 12px;
            }
        }
    }
    &-filters {
        @include flex-center;
        flex-direction: column;
        h2 {
            margin: 0;
        }
        button {
            margin-top: 10px;
            align-self: center;
            justify-self: center;
        }
        &-list {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            &-section {
                display: flex;
                flex-direction: column;
                flex: 1;
                width: 100%;
                max-width: 300px;
                text-align: left;
                padding-right: 10px;
                margin-bottom: 10px;
                label {
                    @include flex-between;
                    width: calc(100% - 15px);
                    margin: 3px 0 3px 10px;
                }
            }
        }
    }
    &-tags {
        text-align: left;
        padding-right: 10px;
        max-width: 300px;
        margin: 0 auto;
        margin-bottom: 10px;
        header {
            @include flex-between-center;
            button {
                background: none;
                border: none;
                padding: 0;
                color: $sprout-dark-blue;
                &:hover {
                    color: $sprout-gold;
                }
            }
        }
        select {
            width: 100%;
            margin: 5px 0;
            padding: 2px 5px;
        }
    }
    @include breakpoint('lg') {
        position: relative;
        width: 20%;
        min-width: 250px;
        // max-height: 600px;
        border-radius: 0;
        &-filters {
            text-align: left;
            button {
                align-self: center;
                border-radius: 10px;
            }
        }
    }
}
