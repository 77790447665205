.side-panel-wrap {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    background-color: $grey;
    top: 0;
    right: 0;
    position: fixed;
    color: white;
    text-decoration: none;
    a { 
        text-decoration-line: none;
    }
}

.side-panel{
    &-close {
        position: absolute;
        right: 0;
    }
    &-content {
        display: flex;
        flex-direction: column;
        width: 90%;
        margin: 7% 5% 5% 5%;
        text-align: left;
        h5 {
            margin: 10px 0px 5px 0px;
        }
        p {
            margin: 5px 0px;
        }
    }
    &-nav{
        display: flex;
        flex-direction: column;
        &-website {
            color: white;
            font-size: 18px;
            margin: 4px 0;
            font-family: $whitney-semibold;
            width: 92%;
            &-selected {
                nav {
                    color: $bright-yellow;
                }
            }
        }
        &-item {
            border-left: 1px solid  $bright-yellow;
            margin: 3px 0;
            list-style: none;
            text-decoration-line: none;
            padding: 2px;           
            width: 130px;;
            color: white;
            font-size: 16px;
            &-selected {
                text-indent: 15px;
                nav {
                    margin-left: 0;
                    color: black;
                    background-color: white;  
                    border-left: 10px solid $bright-yellow;
                }
            }
        }
    }
}

.side-panel-footer {
    display: flex;
    .side-panel-footer-portal {
        @include flex-content-center;
        margin: 5%;
        color: white;
        border: none;
        height: 25px;
        width: 90px;
        background-color: $bright-yellow;
    }
}

.side-navbar {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-left: 15px;
}

@media (max-width: 900px) {
    .side-panel-wrap {
        width: 50vw;
        height: 100vh;
    }
}

@media (max-width: 450px) {
    .side-panel-wrap {
        width: 70vw;
        height: 100vh;
    }
}