// Sprout
.salmon-button {
    background-color: $sprout-salmon;
    border: none;
    margin: 0px;
    padding: 12px;
    color: white;
    text-decoration: none;
    text-transform: uppercase;
    width: auto;
    font-size: 18px;
}

.salmon-button-small {
    background-color: $sprout-salmon;
    border: none;
    margin: 0px;
    padding: 12px 9px;
    color: white;
    text-decoration: none;
    text-transform: uppercase;
    width: auto;
    font-size: 16px;
}

// Redesign
@mixin rounded-btn {
    padding: 13px 40px;
    border-radius: 26px;
    @media (max-width: 600px) {
        width: 275px;
    }
}

@mixin rounded-btn-icon {
    padding: 11px 23px 13px 23px;
    border-radius: 26px;
    span {
        display: flex;
        align-items: center;
        justify-content: center;
        width: auto;
        svg {
            padding-left: 10px;
            font-size: 20px;
        }
    }
    @media (max-width: 600px) {
        width: 275px;
    }
}

.btn-outlined-dark {
    @include rounded-btn;
    background: none;
    color: white;
    border: 2px solid white;
    &:hover, :focus {
        color: $r-primary-1;
        background: white;
    }
}

.btn-outlined-light {
    @include rounded-btn;
    background: none;
    border: 2px solid $r-primary-1;
    color: $r-primary-1;
    &:hover, :focus {
        background: $r-primary-1;
        color: white;
    }
}

.btn-contained-default {
    @include rounded-btn;
    background-color: $r-primary-3;
    border: 2px solid $r-primary-3;
    color: white;
    &:hover, :focus {
        background: $r-primary-1;
        border: 2px solid $r-primary-1;
    }
}

.btn-contained-white {
    @include rounded-btn;
    background-color: white;
    border: 2px solid white;
    color: $r-primary-3;
    &:hover, :focus {
        color: white;
        background: $r-primary-1;
        border: 2px solid $r-primary-1;
    }
}

.btn-contained-icon {
    @include rounded-btn-icon;
    background-color: $r-primary-3;
    border: 2px solid $r-primary-3;
    color: white;
    &:hover, :focus {
        background: $r-primary-1;
        border: 2px solid $r-primary-1;
    }
}

// Communities
button {
    cursor: pointer;
    &:disabled {
        cursor: default;
    }
}

.accept-button {
    background-color: $bright-yellow;
    color: white;
    border: none;
    padding: 8px 10px;
    &:hover {
        background-color: goldenrod;
    }
}

.rounded-primary {
    background-color: $primary-color;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 8px 10px;
    margin: 5px;
    &:hover {
        background-color: goldenrod;
    }
    &.active {
        background-color: goldenrod;
    }
}

.blue-button {
    background-color: $primary-color;
    color: white;
    border: 1px solid $primary-color;
    padding: 5px 10px;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
    &:hover {
        background-color: white;
        color: $primary-color;
        border: 1px solid $primary-color;
    }
    &:disabled {
        background-color: $light-grey;
        color: white;
        border: 1px solid $light-grey;
        cursor: default
    }
    &-comments {
        font-size: 14px;
        font-family: $whitney-medium;
        background-color: $primary-color;
        color: white;
        border: 1px solid $primary-color;
        padding: 5px;
        &:hover {
            background-color: white;
            color: $primary-color;
            border: 1px solid $primary-color;
        }
    }
    &-addcomments {
        font-size: 14px;
        font-family: $whitney-medium;
        background-color: $primary-color;
        color: white;
        border: 1px solid $primary-color;
        padding: 5px;
        &:hover {
            background-color: white;
            color: $primary-color;
            border: 1px solid $primary-color;
        }
    }
}

.gold-button {
    font-size: 14px;
    font-family: $whitney-medium;
    border: solid 1px #f5c945;
    background-color: #f5c945;
    color: #fff;
    padding: 5px 10px;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
    &:hover {
        border: solid 1px $bright-yellow-alt;
        background: $bright-yellow-alt;
    }
    &:disabled {
        background-color: $light-grey;
        color: white;
        border: 1px solid $light-grey;
        cursor: default
    }
}

.review-button {
    border: none;
    &-true {
        background-color: $green;
        color: white;
        border: 2px solid $green;
        padding: 4px 10px;
    }
    &-false {
        background-color: white;
        color: $primary-color;
        border: 2px solid $primary-color;
        padding: 4px 10px;
        &:hover {
            background-color: $green;
            color: white;
            border: 2px solid $green;
        }
    }
}

.pagination-buttons {
    display: flex;
    justify-content: space-between;
    width: 121px;
    margin: 10px 0;
    button {
        height: 30px;
        width: 60px;
        background-color: white;
        border: none;
        font-size: 12px;
        &:hover:not([disabled]) {
            background-color: $very-light-grey;
            color: $secondary-color;
            font-weight: bold;
            border: 1px solid white;
            cursor: pointer;
        }
    }
    &-newer {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    }
    &-older{
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }
}


.react-add-to-calendar__wrapper {
    width: 200px;
    margin-top: 10px;
    padding: 5px;
    box-shadow: 2px 4px 5px 0px rgba(90, 89, 89, 0.75);
    background-color: $ultra-light-grey;
}

.react-add-to-calendar__button {
    i { 
        margin-right: 10px; 
    }
    cursor: pointer;
}

.react-add-to-calendar__dropdown {
    width: 210px;
    box-shadow: 2px 4px 5px 0px rgba(90, 89, 89, 0.75);
    background-color: $ultra-light-grey;
    ul {
        margin: 0;
        padding: 5px 10px;
        list-style: none;
        border-top: 1px solid $primary-color;
        li {
            padding-bottom: 5px;
            &:hover {
                font-weight: bold;
            }
            a {
                color: $primary-color;
                text-decoration: none;
                i { 
                    margin-right: 8px; 
                }
            }
        }
    }
}