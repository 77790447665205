.five-phases-management { 
    p {
        text-align: left;
        font-family: $typeface-montserrat;
    }
    ul {
        text-align: left;
        font-family: $typeface-montserrat;
    }
    h2 {
        font-family: $typeface-montserrat;
    }
    
    &-hero {
        @include flex-space-around;
        width: 800px;
        margin-top: 50px;
        margin-bottom: 50px;
        &-text {
            width: 100%;
        }
        
        h3 {
            font-family: $typeface-montserrat;
            font-weight: $fontweight-light;
        }
        @include breakpoint('lg'){
            max-width: 940px;
        }
        @include breakpoint('xl'){
            max-width: 1260px;
        }
    }
}

.a2bLandingPageImage{
    width:65vh;
    height:55vh;
}

.five-phases {
    @include flex-align-start-column;
    width: 100%;
    @include breakpoint('sm'){
        padding: 0 5vw;
    }
    h2 {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        font-family: $typeface-montserrat;
    }
    &-manage-steps-img {
        width: 340px;
        padding: 0 20px;
        height: auto;
    }
    &-initiation {
        margin-bottom: 40px;
        h2 {

            border-bottom: solid 2px $sprout-dark-blue;
            color: $sprout-dark-blue;
        }
    }
    &-planning {
        margin-bottom: 40px;
        h2 {  
            border-bottom: solid 2px $gold-2;
            color: $gold-2;
        }
        img {
            width: 500px;
            height: auto;
        }
    }
    &-execution {

        margin-bottom: 40px;
        h2 {
            border-bottom: solid 2px $sprout-execution-purple;
            color: $sprout-execution-purple;
        }
    }
    &-performance {
        margin-bottom: 40px;
        h2 {
            border-bottom: solid 2px $sprout-performance-green;
            color: $sprout-performance-green;
        }
    }
    &-closure {
        margin-bottom: 40px;
        h2 {
            border-bottom: solid 2px $sprout-closure-red;
            color: $sprout-closure-red;
        }
    }
}

@media (max-width: 700px) {
    .five-phases-management {
        &-hero {
            flex-direction: column-reverse;
            justify-content: center;
            align-items: center;

            h3 {
                margin: 5px;
                font-weight: 300;
            }
        }
    }
}

@media (max-width : 480px) {
    .content {
        background-color: white;
        margin: 1%;
        p {
            padding: 5%;
            margin: 2%;
        }
    }
    
    .five-phases {
        width: 90vw;

        h2 {
            width: 90vw;
            justify-content: center;
            font-size: 20px;
            padding: 2% 0;
            color: white;
        }

        &-initiation{
            margin: 0px;
            background-color: $sprout-dark-blue;
        }

        &-planning{
            margin: 0px;
            background-color: $gold-2;
            img {
                width: 90%;
                margin: 2% 0;
            }
        }

        &-execution{
            margin: 0px;
            background-color: $sprout-execution-purple;
        }

        &-performance{
            margin: 0px;
            background-color: $sprout-performance-green;
        }

        &-closure{
            margin: 0px 0px 25% 0px;
            background-color: $sprout-closure-red;
        }
    }

    .a2bLandingPageImage{
        width:53vh;
        height:45vh;
    }
    .a2bLandingPageText{
        margin-left: 45px;
        width: 80%;
    }
}
