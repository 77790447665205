.home-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 60px 0px 0 0;
    width: 100vw;
    &-virtual {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100vw;
        margin: 175px 0px 0 0;
    }
}

.component-container-banner {
    &-open {
        margin-top: 141px;
    }
    &-closed {
        margin-top: 91px;
    }
}

.loader {
    border: 8px solid #f3f3f3;
    border-radius: 50%;
    border-top: 8px solid $primary-color;
    width: 15px;
    height: 15px;
    &.Coaching {
        border-top: 8px solid #C60303;
    }
    &.IOCDF {
        border-top: 8px solid #860FA2;
    }
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
}

select, select:before, select:after, select:focus, select:focus-within {
    border-radius: 0;
    border: 0;
    outline: 1px solid $light-grey;
    outline-offset: -1px;
}

@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@media(max-width: 770px) {
    .home-wrap-virtual {
        margin-top: 188px;
    }
    select, select:before, select:after, select:focus, select:focus-within {
        border-radius: 0;
        border: 0;
        outline: none;
        outline-offset: none;
    }
}

@media(max-width: 769px) {
    .home-wrap {
        &-virtual {
            margin-top: 176px;
        }
    }
    .component-container-banner {
        &-open {
            margin-top: 130px;
        }
        &-closed {
            margin-top: 80px;
        }
    }
}

@media(max-width: 480px) {
    .desktop-only {
        display: none;
    }
}

@media print {
    body {
        -webkit-print-color-adjust: exact;
    }
    
    .main-header, .footer-wrap, .registrations-event-details-header-image, .printButton, .print-snapshot {
        display: none !important;
    }

    .home-wrap, .home-wrap-virtual .snapshot-report {
        margin: 0;
    }

    @page { 
        margin: 15px 0; 
        size: auto;
    }

    .pageBreak {
        break-after: always;
    }
    .breakBefore {
        break-before: always;
    }
}