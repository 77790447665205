.prework-grow {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw; 
    max-width: 1600px;
    background-color: $sprout-gray-2;
    text-align: left;
    padding-bottom: 50px;
    &-section {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0 10px;
        width: 100%;
        padding-bottom: 20px;
        margin: 30px 50px 0 50px;
        h2 {
            margin: 0 0 10px 0;
        }
        ul, p {
            max-width: 900px;
            margin: 0 0 8px 0;
        }
        li {
            margin: 8px 0;
        }
        .resources-list {
            margin: 0;
            padding: 0;
        }
        a, button {
            margin-top: 10px;
        }
        @include breakpoint('md'){
            max-width: 620px;
            padding: 0 34px;
        }
        @include breakpoint('lg'){
            max-width: 940px;
        }
        @include breakpoint('xl'){
            max-width: 1260px;
        }
    }
}