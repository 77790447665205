// Sprout Redesign
.sprout-footer {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: $sprout-dark-blue;
    width: 100%;
    max-width: $large-breakpoint;
    color: white;
    // height: 20.89vw;
    height: 250px;
    text-align: left;
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }
    a, p, address {
        color: white;
        text-decoration: none;
        font-style: normal;
        margin: 0;
    }

    &-copywrite {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: black;
        height: 39px;
        padding: 0 47px;
        font-size: 14px;
    }

    &-content {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-self: center;
        padding: 47px 62px 33px 55px;
        height: 100%;

        &-nav {
            display: flex;
            justify-content: flex-start;
            flex-direction: column;
            &-logo {
                width: 17.07vw;
                height: 4.15vw;
                max-height: 58px;
                max-width: 239px;
                &-img {
                    margin-bottom: 35px;
                }
            }
            &-links {
                display: flex;
                flex-wrap: wrap;
                width: 24.9vw;
                height: 3.76vw;
                max-width: 348px;
                max-height: 52px;
                padding-top: 35px;
                & > * {
                    flex: 0 0 33.3333%;
                }
                &-item {
                    // line-height: 25px;
                    &-active {
                        color: $sprout-red;
                        // line-height: 25px;
                    }
                }
            }
        }

        &-contact {
            display: flex;
            justify-content: flex-start;
            flex-direction: column;
            h2 {
                margin: 0 0 35px 0;
            }
            &-info {
                display: flex;
                width: 29.5vw;
                height: 3.76vw;
                max-width: 413px;
                max-height: 52px;
                address, div {
                    flex: 1;
                }
                div {
                    display: flex;
                    flex-direction: column;
                }
            }
        }

        &-social {
            // position: absolute;
            // right: 20px;
            display: flex;
            li {
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: $sprout-teal;
                width: 2.1vw;
                height: 2.07vw;
                max-width: 29px;
                max-height: 29px;
                margin: 0 5px;
                a, svg {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                }
                svg {
                    max-width: 18px;
                    max-height: 18px;
                }
            }
        }
    }
}

.footer {
    @include flex-space-between;
    flex-direction: column;
    min-height: 320px;
    color: white;
    background-color: $r-grayscale-2;
    text-align: left;
    padding: 0 50px;
    width: 100vw;
    p, a {
        color: white;
        text-decoration: none;
    }
    &-content {
        display: flex;
        justify-content: space-between;
        padding-top: 46px;
        width: 100%;
        max-width: 1100px;
        &-section {
            display: flex;
            flex-direction: column;
            h4 {
                margin: 0 0 27px 0;
            }
            p { 
                margin: 0;
            }
            span {
                margin-bottom: 28px;
            }
            a {
                margin-bottom: 18px;
            }
        }
    }
    &-copywrite {
        // @include whitney-book;
        width: 100%;
        max-width: 1100px;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0px;
        margin: 0;
        padding-bottom: 24px;
    }
    &-logo {
        width: 275px;
        height: 40px;
        margin: 0;
    }
    &-icons {
        display: flex;
        justify-content: space-between;
        width: 93px;
        height: 17px;
    }
    &-link {
        // @include whitney-book;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0px;
    }
}

@media(max-width : $mid-breakpoint) {
    .sprout-footer {
        height: auto;
        max-height: unset;
        max-width: unset;
        &-copywrite {
            padding: 0 12px;
            font-size: 12px;
        }
        &-content {
            display: flex;
            flex-wrap: wrap;
            width: 90vw;
            padding: 47px 5vw 33px 5vw;
            height: auto;
            &-nav {
                justify-self: flex-end;
                &-links {
                    margin: 30px 0 15px 0;
                    width: 300px;
                    height: auto;
                }
                &-logo {
                    width: 200px;
                    height: 48.63px;
                    margin-bottom: 20px !important;
                }
            }
            &-contact {
                margin-bottom: 15px;
                justify-items: flex-end;
                h2 {
                    margin: 20px 0;
                }
                &-info {
                    width: 100%;
                    height: auto;
                }
            }
            &-social {
                position: relative;
                margin-top: 15px !important;
                max-width: 200px;
                li {
                    width: 25px;
                    height: 25px;
                    a {
                        font-size: 18px;
                    }
                }
            }
        }
    }

    .footer {
        padding: 0 33px 0 50px;
        &-content {
            justify-content: flex-start;
            flex-wrap: wrap;
            padding-top: 27px;
            &-section {
                width: 49%;
                h4 {
                    margin: 0 0 -2px 0;
                }
                a {
                    margin: 10px 0 0 0;
                }
            }
        }
        &-copywrite {
            margin: 0;
            padding-bottom: 8px;
        }
        &-link {
            // @include whitney-book;
            font-size: 14px;
        }
        .footer-desktop-only {
            display: none;
        }
    }
}

@media (max-width: 425px) {
    .footer {
        min-height: 400px;
    }
}
