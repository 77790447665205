.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: $grey;
	-webkit-transition: .4s;
	transition: .4s;
	&:before{
		position: absolute;
		content: "";
		height: 26px;
		width: 26px;
		left: 4px;
		bottom: 4px;
		background-color: white;
		-webkit-transition: .4s;
		transition: .4s;
	}
	&-wrap {
		position: relative;
		display: inline-block;
		min-width: 60px;
		width: 60px;
		height: 34px;
		margin: 0px 10px;
		margin: 5px;
		input {
			opacity: 0;
			width: 0;
			height: 0;
		}
	}
}

input:checked + .slider {
	background-color: green;
}

input:focus + .slider {
	box-shadow: 0 0 1px $primary-color;
}

input:checked + .slider:before {
	-webkit-transform: translateX(26px);
	-ms-transform: translateX(26px);
	transform: translateX(26px);
}

.slider.round {
    border-radius: 34px;
    &:before {
        border-radius: 50%;
    }
}

// Currently unused in Session Filters
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    margin: 0px 10px;
}

.switch input { 
	opacity: 0;
	width: 0;
	height: 0;
}
