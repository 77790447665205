.section-public {
    aside {
        position: relative;
        align-self: center;
        object-fit: contain;
        object-position: top;
        margin-bottom: 20px;
    }

    &-content {
        display: flex;
        flex-direction: column;
        text-align: left;
        // justify-content: flex-end;
        width: 100%;
        h2, h3 {
            margin: 0;
            color: $sprout-blue;
        }
        strong {
            margin: 15px 0 0px 0;
        }
        p {
            margin: 10px 0 5px 0;
        }
        ul {
            margin: 5px 0;
            padding-left: 22px;
            li {
                margin-bottom: 10px;
            }
        }
        button, a {
            align-self: flex-start;
            margin-top: 10px;
        }
        &-icons {
            display: flex;
            justify-content: space-evenly;
            background-color: none;
            margin: 20px 0;
        }
    }

    @include breakpoint('md'){
        &-content {
            h2, h3 {
                margin: 0 0 10px 0;
            }
            p, strong {
                margin: 0 0 8px 0;
            }
            span {
                p {
                    margin: 0;
                }
            }
            ul {
                margin: 0 0 0px 0;
                padding: 0 0 0 17px;
                li {
                    padding: 2px 0;
                }
            }
            button, a {
                margin: 10px 10px 0 0;
            }
            &-icons {
                img {
                    margin:  20px 0;
                }
            }
        }
    }

    @include breakpoint('lg'){
        &.reversed {
            flex-direction: row-reverse;
        }
        aside {
            margin: 0 10px;
            justify-content: flex-end;
            align-self: flex-start;
            justify-items: flex-start;

            img {
                @include size-max(unset);
            }
        }
        &-content {
            margin: 0 10px;
            z-index: 1;
        }
    }
    
    @include breakpoint('xl'){
        aside {
            margin: 0 34px;;
        }
        &-content {
            margin: 0 34px;
            p, strong {
                margin: 0 0 10px 0;
            }
            span {
                p {
                    margin: 0;
                }
            }
            ul {
                margin: 0 0 0px 0;
                padding: 0 0 0 17px;
                li {
                    padding: 2px 0;
                }
            }
            button, a {
                margin: 10px 10px 0 0;
            }
        }
    }
}


.section-with-semicircle {
    @include padding-section('noheader');
    aside {
        img {
            @include size-max(600px, 360px);
        }
    }

    &.teal-on-white {
        svg { fill: $teal-semi-circle; }
        aside { background-color: $teal-semi-circle; }
        // h2, h3 { color: black; }
    }
    &.all-teal {
        background-color: $sprout-teal;
        svg { fill: $teal-semi-circle; }
        aside { background-color: $teal-semi-circle; }
        h2, h3 { color: black; }
    }
    &.all-white {
        svg { fill: $sprout-gray; }
        aside { background-color: $sprout-gray; }
        // h2, h3 { color: black; }
    }
    &.all-blue {
        background-color: $sprout-blue;
        color: white;
        svg { fill: $blue-semi-circle; }
        aside { background-color: $blue-semi-circle; }
        h2, h3 { color: white; }
    }
    &.all-yellow {
        background-color: $sprout-gold;
        svg { fill: $orange-semi-circle; }
        aside { background-color: $orange-semi-circle; }
        h2, h3 { color: black; }
    }
    &.all-gray {
        background-color: $sprout-gray;
        svg { fill: $sprout-dark-gray-2; }
        aside { background-color: $sprout-dark-gray-2; }
        // h2, h3 { color: black; }
    }
    &.all-red {
        background-color: $sprout-dark-red;
        color: white;
        svg { fill: $red-semi-circle; }
        aside { background-color: $red-semi-circle; }
        h2, h3 { color: white; }
    }
    &.all-orange {
        background-color: $sprout-orange;
        color: white;
        svg { fill: $orange-semi-circle; }
        aside { background-color: $orange-semi-circle; }
        h2, h3 { color: white; }
    }

    @include breakpoint('lg'){
        aside {
            img {
                @include size-max(unset);
            }
        }
        &.reversed {
            .semicircle-in-sections {
                // subtract all of the margins and padding to the left
                left: calc(-100% - 64px);
            }
        }
    }
    
    @include breakpoint('xl'){
        &.reversed {
            .semicircle-in-sections {
                // subtract all of the margins and padding to the left
                left: calc(-100% - 136px);
            }
        }
    }
}


.section-subsection {
    @include padding-section('subsection');
    img {
        @include size-max(700px, auto);
    }
}

.section-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    border-bottom: 1px solid $sprout-dark-gray;
    margin: auto;
    margin-bottom: 20px;
    padding-top: 20px;
    h2 {
        color: $sprout-blue;
        margin: 0 0 20px 0;
    }

    @include breakpoint('md'){
        margin-bottom: 34px;
        padding-top: 34px;
        p {
            margin: 5px 0;
        }
    }
}
