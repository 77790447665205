.content-gate {
    @include flex-content-center;
    min-height: 100vh;
    min-width: 100vw;
    z-index: 4;
    &::before {
        content: ' ';
        position: fixed;
        top: 0;
        left: 0;
        width: 100%; height: 100%;
        background-image: url('../../../Assets/images/Coaching-Workbook.png');
        background-repeat: no-repeat, repeat;
        background-color: #cccccc;
        background-position: center;
        background-size: contain;
        z-index: 3;
        filter: blur(5px) grayscale(100);
    }
    

    header {
        margin-bottom: 15px;
        color: $primary-color;
        font-size: 22px;
    }

    form {
        display: flex;
        flex-direction: column;
        width: 500px;
        padding: 50px;
        border-radius: 5px;
        background-color: white;
        z-index: 3;
        input, button {
            padding: 5px;
            margin: 5px;
        }

        label {
            text-align: left;
            font-size: 12px;
        }

        button {
            margin-top: 15px;
            background-color: $primary-color;
            color: white;
            border: 2px solid $primary-color;
            &:hover {
                background-color: white;
                color: $primary-color;
            }
            &:disabled {
                background-color: $grey;
                border: 2px solid $grey;
                color: white;
            }
        }
    }
}