.pagination-numbers {
    @include flex-center-all;
    padding: 10px;
    flex-direction: column;
    &-bar {
        display: flex;
        background-color: white;
        border: 1px solid $sprout-gray-2;
        border-radius: 10px;
        width: 100%;
        max-width: 400px;
        button {
            flex: 1;
            background-color: white;
        }
        &-item {
            @include size-max(40px);
            border: none;
            &-active {
                @include size-max(40px);
                border: 1px solid black;
            }
        }
        &-nav {
            border: none;
            padding: 5px 0;
            margin: 5px 0;
            &:first-child {
                border-right: 1px solid $sprout-gray-2;
            }
            &:last-child {
                border-left: 1px solid $sprout-gray-2;
            }
        }
    }
    &-progress {
        margin: 10px 0;
    }
    .pagination-arrow {
        color: $sprout-blue;
        margin: 2px;
        border: none;
    }
    .pagination-arrow:hover {
        color: $sprout-dark-blue;
    }
    aside {
        color: $sprout-blue;
        font-size: 12px;
    }
}
