// All PDF styling is done inline

.lms-snapshots {
    display: flex;
    flex-direction: column;
    align-items: center;
    &-list {
        list-style: none;
        margin: 0;
        padding: 20px 5vw;
        text-align: left;
        &-empty {
            // Same width as button
            width: 120px;
        }
        .snapshot-valid {
            color: $validation-font-green;
            background-color: $validation-background-green;
            border: 1px solid $validation-font-green;
        }
        .snapshot-invalid {
            color: $validation-font-red;
            background-color: $validation-background-red;
            border: 1px solid $validation-font-red;
        }
        li {
            background-color: $very-light-grey;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 10px 0;
            height: 60px;
            width: 500px;
            strong {
                display: flex;
                flex-direction: column;
                justify-content: center;
                height: 100%;
                width: 90px;
                padding: 0 10px;
                time {
                    font-weight: normal;
                    &:nth-child(1) {
                        font-weight: bold;
                        font-size: 20px;
                    }
                }
            }

            a {
                text-decoration: none;
                height: 100%;
            }
            button {
                width: 120px;
                height: 100%;
                margin: 0;
                padding: 0;
                border: none;
                background-color: $r-primary-4;
                &:hover {
                    background-color: $r-primary-2;
                    color: white;
                }
            }
        }
    }
}