/* -------------------------------- 
Typography
-------------------------------- */
// element and class styles for line height and font sizes
// colors and font weights should be set in classes

html {
    // Base font size - sets rem
    font-size: 16px;
}

body {
    font-family: $typeface-montserrat;
}

nav, button {
    font-family: $typeface-calibri;
}

.text-hero {
    @include font-size($hero-fs);
    font-weight: $fontweight-semibold;
}

h1, .h1 {
    @include font-size($h1-fs);
}

h2, .h2 {
    @include font-size($h2-fs);
    font-weight: $fontweight-semibold;
}

h3, .h3, .text-header-lg {
    @include font-size($h3-fs);
}

h4, .h4, .text-header, .text-title {
    @include font-size($h4-fs);
}

.text-header, .text-header-lg {
    font-weight: $fontweight-semibold;
}

.text-title {
    font-weight: $fontweight-regular;
}

.text-subheader {
    @include font-size($body-large-fs);
    font-weight: $fontweight-semibold;
}

.text-body-lg {
    @include font-size($body-large-fs);
}

.text-body, input {
    @include font-size($body-fs);
    font-weight: $fontweight-regular;
}

.text-caption, .text-body-sm {
    @include font-size($caption-fs);
}

small, .text-small {
    @include font-size($small-fs);
}

// a {
//     color: $color-link;
//     &:visited {
//         color: $color-link-visited;
//     }
// }

b, strong {
    font-weight: bold;
}

/* utility classes */
.truncate {
    // truncate text if it exceeds parent
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
