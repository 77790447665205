.warning-modal {
    @include flex-content-center;
    z-index: 5;
    position: fixed;
    top: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(128, 128, 128, 0.63);

    &-close {
        text-align: right;
        font-size: 20px;
        margin-right: 4px;
        &:hover {
            color: goldenrod;
        }
    }

    &-content {
        background-color: white;
        border-radius: 4px;
        border: 4px solid $primary-color;
        padding: 10px;
        h3 {
            margin-top: -20px;
        }
    }
}

// Used on Pulse edit employees, future update to replace main-modal
.modal {
    display: flex;
    justify-content: center;
    width: 50%;
    background-color: white;
    border: 1px solid $primary-color;
    outline: none;
    max-height: 80vh;
    &-overlay {
        @include flex-content-center;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #00000030;
        z-index: 3;
        backdrop-filter: blur(3px);
    }
    &-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        header {
            position: relative;
            top: -20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 80%;
            padding: 15px 10px;
            background-color: $primary-color;
            color: white;
            h3 {
                text-align: center;
                font-size: 24px;
                width: 100%;
                margin: 0px;
            }
            svg {
                position: absolute;
                right: 10px;
                cursor: pointer;
                border-radius: 50%;
                &:hover {
                    background-color: $validation-font-red;
                }
            }
        }
        form {
            display: flex;
            flex-direction: column;
            padding: 5px;
            width: 75%;
            margin: -15px 0 5px 0;
            label {
                display: flex;
                justify-content: space-between;
                width: 100%;
                font-size: 16px;
                padding: 10px;
                &:nth-child(even) {
                    background-color: $ultra-light-grey;
                }
                input, textarea, select, option {
                    display: flex;
                    justify-content: flex-end;
                    width: 50%;
                    border: 1px solid #cccccc;
                    margin: 0em;
                    padding: 2px;
                    -webkit-border-radius: 3px;
                    -moz-border-radius: 3px;
                    border-radius: 3px;
                    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
                    -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
                    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
                    -webkit-transition: border linear 0.2s, box-shadow linear 0.2s;
                    -moz-transition: border linear 0.2s, box-shadow linear 0.2s;
                    -ms-transition: border linear 0.2s, box-shadow linear 0.2s;
                    -o-transition: border linear 0.2s, box-shadow linear 0.2s;
                    transition: border linear 0.2s, box-shadow linear 0.2s;
                    outline: none;
                    -ms-box-sizing:content-box;
                    -moz-box-sizing:content-box;
                    -webkit-box-sizing:content-box; 
                    box-sizing:content-box;
                }
                input[type="checkbox"] {
                    width: auto;
                }
            }

            button {
                margin: 10px 0 0 0;
                border: 1px solid white;;
                background-color: $primary-color;
                color: white;
                padding: 5px 10px;
                align-self: center;
                font-size: 1em;
                transition: 0.3s ease-in-out;
                cursor: pointer;
                &:focus {
                    outline: none;
                }
                &:disabled {
                    background-color: $grey;
                }
                &:hover {
                    background-color: $secondary-color;
                }
            }
        }
    }
}

.close-icon-wrapper{
    display: flex;
    justify-content: flex-end;
    width: 112%;
}

.close-icon{
    margin-top: 4%;
    cursor: pointer;
}

.modalStyle {
    display: flex; 
    justify-content: center;
}

.edit-user-modal {
    @include flex-content-center;
    width: 25%;
    border: none;
    outline: none;
    background-color: $primary-color;
    color: white;
    form {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 0;
        input {
            margin-bottom: 10px;
            height: 20px;
            font-size: 16px;
        }
        button {
            align-self: center;
            margin: 20px 0px;
            border: 2px solid white;
            background-color: $primary-color;
            color: white;
            padding: 3px;
            width: 150px;
            font-size: 1em;
            transition: 0.3s ease-in-out;
            font-family: $whitney-book;
            &:focus {
                outline: none;
            }
            &:hover {
                cursor: pointer;
                color: $primary-color;
                background-color: white;
            }
        }
    }
    &-overlay {
        @include flex-content-center;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #00000030;
        z-index: 2;
        backdrop-filter: blur(3px);
    }
    &-body{
        width: 75%;
        &-buttons {
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            margin-bottom: 20px;
            button {
                background-color: $primary-color;
                border: 2px solid white;
                color: white;
                width: 75px;
                height: 25px;
                padding: 2px;        
            }
        }
    }
    h3{
        margin-top: 0px;
        margin-bottom: 20px;
    }
}

.main-modal {
    display: flex;
    justify-content: center;
    width: 45%;
    background-color: white;
    border: 1px solid $primary-color;
    outline: none;
    color: black;
    max-height: 80vh;
    input {
        width: 220px;
    }
    textarea {
        width: 70%;
    }
    h3 {
        text-align: center;
        color: #2E5597;
        margin-top: 0px;
        margin-bottom: 20px;
    }
    form {
        display: flex;
        flex-direction: column;
        align-content: center;
        width: 100%;
        margin: 0;
        padding: 5px;
        max-height: 63vh;
        overflow-y: auto;
        overflow-x: visible;
        div {
            @include flex-space-between;
            width: 80%;
            margin-bottom: 10px;
        }
        label {
            display: flex;
            justify-content: space-between;
            margin: 5px;
        }
        input {
            height: 20px;
            font-size: 16px;
        }
        select {
            width: 220px;
        }
        button {
            margin: 20px 0 0 0;
            border: none;
            background-color: $primary-color;
            color: white;
            padding: 5px;
            align-self: center;
            font-size: 1em;
            transition: 0.3s ease-in-out;
            font-family: $whitney-book;
            cursor: pointer;
            &:focus {
                outline: none;
            }
            &:disabled {
                background-color: $grey;
            }
            &:active:hover {
                background-color: $secondary-color;
            }
        }
    }
    &-overlay {
        @include flex-content-center;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #00000030;
        z-index: 2;
        backdrop-filter: blur(3px);
    }
    &-body{
        width: 75%;
        margin-bottom: 5px;
        &-buttons {
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            margin-bottom: 20px;
            button {
                background-color: $primary-color;
                border: 2px solid white;
                color: white;
                width: 75px;
                height: 25px;
                padding: 2px;  
                cursor: pointer;      
            }
        }
    }
}

.snapshot-emailChips {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 70%;
    &-items {
        display: flex;
        border: 1px solid $primary-color;
        align-items: center;
        padding: 2px;
        margin: 3px;
        p {
            margin: 0 4px;
        }
    }
}

.snapshot-email-modal {
    width: 65%;
    // input {
    //     width: 95%;
    // }
}

.modal-email-sent-notification {
    height: 200px;
    display: flex;
    align-items: center;
    flex-direction: column;
    header {
        color: $primary-color;
        font-size: 2.2vw;
        margin: 16px;
    }
    p {
        color: $dark-grey;
        font-size: .9vw;
    }
}

@media (max-width: 1200px) {
    .edit-user-modal {
        width: 45%;
    }
    .modal-email-sent-notification {       
        header {
            font-size: 30px;
        }
        p {
            color: $dark-grey;
            font-size: 14px;
        }
    }
}
@media (max-width: 800px) {
    .snapshot-email-modal, .snapshot-emailChips {
        width: 90%;
    }
}
@media (max-width: 650px) {
    .edit-user-modal, .main-modal {
        width: 70%;
        margin-top: 20px;
        input, label, textarea {
            width: 100%;
        }
        form {
            max-height: 63vh;
            overflow-x: auto;
            div {
                flex-direction: column;
                width: 100%;
            }
        }
    }
    .snapshot-email-modal, .snapshot-emailChips {
        width: 95%;
    }
}

@media (max-width: 330px) {
    .edit-user-modal, .main-modal {
        width: 85%;
    }
}

// replace with variables when merged with web development branch
@media (max-width: 600px) {
    .modal {
        width: 90%;
        &-content {
            form {
                width: 100%;
                padding: 0;
                label {
                    width: 100%;
                    padding: 10px 5px;
                }
            }
        }
    }
}
