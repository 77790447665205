.preferences-content {
    width: 100%;
    padding: 20px;
    background-color: white;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: left;
    border: 1px solid $sprout-dark-gray;
    @include breakpoint('sm'){
        width: 80%;
        padding: 50px 20px;
        margin: 30px auto;
    }
    section {
        width: 100%;
        max-width: 500px;
        padding-bottom: 10px;
        &:not(:last-child) {
            margin-bottom: 20px;
            border-bottom: 1px solid gray;
        }
        header {
            display: flex;
            justify-content: space-between;
            h2, button {
                margin: 0 0 10px;
            }
            button {
                border: none;
                background-color: white;
                color: $sprout-blue;
                &:hover {
                    color: $sprout-extra-dark-gold;
                }
            }
        }
        p {
            margin: 0;
        }

        label {
            flex: 1;
            display: flex;
            flex-direction: column;
            // justify-content: space-between;
            margin-bottom: 5px;
        }
        button {
            align-self: flex-start;
        }
    }
    &-deactivate {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 500px;
        padding: 20px;
        margin-top: 10px;
        background-color: $sprout-gray-2;
        text-align: left;
        transition: all 100ms ease-in;
        strong {
            color: $sprout-blue;
            padding-bottom: 10px;
        }
        &:hover {
            background-color: $validation-background-red;
            color: $validation-font-red;
            strong {
                color: $validation-font-red;
            }
        }
    }
}
