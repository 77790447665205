// extend classed
.button-basic  {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 100ms ease-in;
    margin: 0px;
    padding: 8px 10px;
    text-decoration: none;
    text-transform: uppercase;
    width: auto;
    min-width: 100px;
    font-size: 1rem;
    border: none;
    &:disabled {
        color: white;
        background-color: $sprout-dark-gray-4;
        border: none;
        pointer-events: none;
    }
}

.btn-basic {    
    text-align: center;
    transition: all 100ms ease-in;
    padding: 8px 15px;
    text-decoration: none;
    text-transform: uppercase;
    width: auto;
    min-width: 100px;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    &:disabled {
        background-color: white;
        border: 1px solid gray;
        color: gray;
        pointer-events: none;
    }
}

.button-square {
    @include size(30px);
    @include flex-center-all;
}

// specific classes
.salmon-button {
    background-color: $sprout-salmon;
    border: none;
    margin: 0px;
    padding: 12px;
    color: white;
    text-decoration: none;
    text-transform: uppercase;
    width: auto;
    font-size: 1.125rem;
}

.salmon-button-small {
    background-color: $sprout-salmon;
    border: none;
    margin: 0px;
    padding: 12px 9px;
    color: white;
    text-decoration: none;
    text-transform: uppercase;
    width: auto;
    font-size: 1rem;
}

.btn-white {
    @extend .btn-basic;
    background-color: white;
    border: 1px solid #246ad7;
    color: #246ad7;
    &:hover {
        background-color: #ecf3ff;
    }
}

.btn-blue {
    @extend .btn-basic;
    background-color: white;
    border: 1px solid #246ad7;
    color: #246ad7;
    &:hover {
        background-color: #ecf3ff;
    }
    &.active {
        background-color: #246ad7;
        color: white;
    }
}

.btn-orange {
    @extend .btn-basic;
    background-color: white;
    border: 1px solid $a2b-orange;
    color: $a2b-orange;
    &:hover {
        background-color: $a2b-orange-bg;
    }
}

.button-blue {
    @extend .button-basic;
    background-color: $sprout-blue;
    border: 1px solid $sprout-blue;
    color: white;
    &:hover {
        background-color: #ecf3ff;
    }
    &.active {
        background-color: #246ad7;
        color: white;
    }
}

.button-gold {
    @extend .button-basic;
    background-color: $sprout-extra-dark-gold;
    color: white;
    &:hover {
        background-color: $sprout-gold;
        color: $sprout-font-gray;
    }
}

.button-validation {
    @extend .button-basic;
    background-color: $sprout-blue;
    color: white;
    border: 1px solid $sprout-dark-blue;
    &:hover {
        background-color: $sprout-dark-blue;
    }
    &.button-validation-true {
        background-color: $validation-background-green;
        color: $validation-font-green;
        border: 1px solid $validation-font-green;
    }
    &.button-validation-false {
        background-color: $validation-background-red;
        color: $validation-font-red;
        border: 1px solid $validation-font-red;
    }
}

.button-sq-blue {
    @extend .button-square;
    background-color: $sprout-blue;
    color: white;
    border: 1px solid $sprout-dark-blue;
    border-left: none;
    &:hover {
        background-color: $sprout-dark-blue;
        border: 1px solid $sprout-dark-blue;
        border-left: none;
    }
}

.button-sq-yellow {
    @extend .button-square;
    background-color: $sprout-gold;
    color: $sprout-font-gray;
    border: 1px solid $sprout-extra-dark-gold;
    border-left: none;
    &:hover {
        background-color: $sprout-extra-dark-gold;
        color: white;
        border: 1px solid $sprout-extra-dark-gold;
        border-left: none;
    }
}

.button-sq-red {
    @extend .button-square;
    background-color: $validation-background-red;
    color: $validation-font-red;
    border: 1px solid $validation-font-red;
    border-left: none;
    &:hover {
        background-color: $validation-font-red;
        color: white;
        border: 1px solid $validation-font-red;
        border-left: none;
    }
}

.button-sq-green {
    @extend .button-square;
    background-color: $validation-background-green;
    color: $validation-font-green;
    border: 1px solid $validation-font-green;
    border-left: none;
    &:hover {
        background-color: $validation-font-green;
        color: white;
        border: 1px solid $validation-font-green;
        border-left: none;
    }
}
