.cookie-banner {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    height: 144px;
    bottom: 0;
    margin: 0;
    padding: 4px;
    width: 100%;
    background-color: $r-grayscale-2;
    z-index: 7;
    &-content {
        display: flex;
        align-items: center;
        color: white;
        text-align: left;
        padding: 10px;
        svg {
            margin-bottom: 48px;
        }
        section {
            max-width: 453px;
            width: 100%;
            margin: 0 10px;
            h5, p, a {
                margin: 0;
            }
            a {
                color: white;
                margin-left: 4px;
            }
        }
        &-buttons {
            display: flex;
            flex-direction: column;
            button {
                margin: 5px 0;
            }
        }
    }
}

@media (max-width: 769px) {
    .cookie-banner {
        height: 240px;

        &-content {
            flex-direction: column;
            align-items: center;
            text-align: center;
            padding-top: 20px;
            .body-2 {
                font-size: 1em;
            }
            svg {
                display: none;
            }
            &-buttons {
                margin-top: 20px;
            }
        }
    }
}