.popup {
    position: relative;
    top: 100px;
    margin: 100px auto 0 auto;
    max-width: 500px;
    border: none;
    outline: none;
}

.popup-overlay {
    position: fixed;
    top: 0;
    inset-inline-end: 0;
    bottom: 0;
    inset-inline-start: 0;
    z-index: 1000;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.247);
}

.popup-confirm {
    position: relative;
    background-color: #fff;
    background-clip: padding-box;
    border: none;
    border-radius: 8px;
    box-shadow: 0 6px 16px 0 rgb(0 0 0 / 8%), 0 3px 6px -4px rgb(0 0 0 / 12%), 0 9px 28px 8px rgb(0 0 0 / 5%);
    padding: 20px 24px;
    &-close {
        position: absolute;
        top: 17px;
        inset-inline-end: 17px;
        z-index: 1010;
        padding: 0;
        color: rgba(0,0,0,.45);
        font-weight: 600;
        line-height: 1;
        text-decoration: none;
        background: 0 0;
        border-radius: 4px;
        width: 22px;
        height: 22px;
        border: none;
        outline: none;
        cursor: pointer;
        transition: color .2s,background-color .2s;
    }
    &-header {
        color: rgba(0,0,0,.88);
        background: #fff;
        border-radius: 8px 8px 0 0;
        margin: 0 0 8px 0;
    }
    &-body {
        font-size: 14px;
        line-height: 1.5714285714285714;
        word-wrap: break-word;
    }
    &-footer {
        text-align: end;
        background: 0 0;
        margin-top: 12px;
        button {
            cursor: pointer;
            transition: all .2s cubic-bezier(.645,.045,.355,1);
            user-select: none;
            touch-action: manipulation;
            line-height: 1.5714285714285714;
            outline: none;
            position: relative;
            display: inline-block;
            font-weight: 400;
            white-space: nowrap;
            text-align: center;
            background-image: none;
            font-size: 14px;
            height: 32px;
            padding: 4px 15px;
            border-radius: 6px;
            margin-left: 8px;
        }
    }
}

.popup-button {
    &-white {
        border: 1px solid #d9d9d9;
        color: rgba(0,0,0,.88);
        background-color: #fff;
        box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
        &:hover {
            border: 1px solid $sprout-hyperlink;
            color: $sprout-hyperlink;
        }
    }
    &-blue {
        border: 1px solid $sprout-hyperlink;
        color: #fff;
        background-color: $sprout-hyperlink;
        box-shadow: 0 2px 0 rgb(5 145 255 / 10%);
        &:hover {
            border: 1px solid $sprout-blue;
            background-color: $sprout-blue;
        }
    }
    &-yellow {
        border: 1px solid $sprout-dark-gold;
        color: #fff;
        background-color: $sprout-dark-gold;
        box-shadow: 0 2px 0 rgb(5 145 255 / 10%);
        &:hover {
            border: 1px solid $sprout-extra-dark-gold;
            background-color: $sprout-extra-dark-gold;
        }
    }
}
