.form-checklist {
    display: flex;
    flex-direction: column;
    label {
        display: flex;
        flex-direction: row !important;
        justify-content: space-between;
        margin-bottom: 5px;
    }
}

.form-row {
    display: flex;
    flex-direction: column;
    label {
        display: flex;
        flex-direction: column;
        font-weight: $fontweight-semibold;
        input {
            margin: 5px 0 10px 0;
        }
    }
    @include breakpoint("sm") {
        flex-direction: row;
    }
}

.form-actions {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    button {
        margin-bottom: 10px;
    }
    @include breakpoint("sm") {
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        button {
            margin-bottom: 0;
        }
    }
}

.form-message {
    margin-top: 10px;
    &-success {
        color: $validation-font-green;
        background-color: $validation-background-green;
    }
    &-error {
        color: $validation-font-red;
        background-color: $validation-background-red;
    }
}
// give highfives
.highfive-form {
    width: 100%;
    header {
        @include flex-between-center;
        text-align: left;
        padding-bottom: 10px;
        margin: 10px 0;
        border-bottom: 2px solid $sprout-blue;
        h3 {
            margin: 5px 0 2px 0;
        }
    }
    form {
        @include flex-column;
        text-align: left;
    }
    textarea {
        width: 99.3%;
        min-height: 75px;
        border: $sprout-blue 1px solid;
        font: $fontweight-regular;
    }
    &-recipients {
        @include flex-column;
        border-bottom: 2px solid $sprout-blue;
        margin-bottom: 20px;
        article {
            flex-direction: column;
            margin: 5px;
            width: 159vw;
            @include breakpoint('md') {
                @include flex-between;
                flex-direction: row;
                margin: 0;
                width: 100%;
            }
        }
        ul {
            @include flex-wrap;
            list-style: none;
            padding: 0;
            margin: 5px 0;
            li {
                @include flex-center;
                background-color: $sprout-blue;
                border: solid 1px $sprout-dark-blue;
                color: white;
                margin: 2px;
                padding: 5px 10px;
                p {
                    color: $sprout-red;
                    font-family: $fontweight-semibold;
                    margin: 0 0 0 5px;
                }
            }
        }
        h4 {
            margin: 5px 0 10px;
        }
        p {
            margin: 5px 0;
        }
        &-internal {
            @include flex-column;
            width: 45%;
            padding: 5px;
            label {
                @include flex-between;
                margin-bottom: 5px;
            }
            h4 {
                background-color: $sprout-blue;
                border: solid 1px $sprout-dark-blue;
                color: white;
                padding: 5px 0;
                padding-right: 15px;
                padding-left: 5px;
                margin: 5px 0;
            }
        }
        &-external {
            @include flex-column;
            width: 45%;
            padding: 5px;
            label {
                @include flex-between;
                margin-bottom: 5px;
            }
            h4 {
                background-color: $sprout-blue;
                border: solid 1px $sprout-dark-blue;
                color: white;
                padding: 5px 0;
                padding-right: 15px;
                padding-left: 5px;
                margin-bottom: 0;
            }
            &-fields {
                padding: 5px;
            }
        }
    }
    &-values {
        &-items {
            @include flex-wrap;
            justify-content: space-evenly;
            img {
                display: block;
                max-width: 127px;
                max-height: 71px;
                width: auto;
                height: auto;
                margin: 5px;
                cursor: pointer;
                -webkit-filter: grayscale(100%);
                filter: grayscale(100%);
                &:hover {
                    -webkit-filter: grayscale(0%);
                    filter: grayscale(0%);
                }
            }
            &-selected {
                img {
                    -webkit-filter: grayscale(0%);
                    filter: grayscale(0%);
                }
            }
        }
    }
    button {
        border: 1px solid $sprout-dark-blue;
        color: white;
        background-color: $sprout-blue;
        width: 150px;
        height: 30px;
        margin: 25px 9px;
        cursor: pointer;
        &:hover:enabled {
            border: 1px solid $sprout-dark-blue;
            color: $sprout-dark-blue;
            background-color: white;
            cursor: pointer;
        }
    }
}

.opportunity-form {
    @include flex-align-center-column;
    padding: 34px;
    width: 100%;
    gap: 30px;
    button {
        align-self: center;
    }
    &-loading {
        margin: 0;
        padding: 20px;
        justify-self: center;
        align-self: center;
    }
    &-error {
        margin: 0;
        padding: 20px;
        color: $validation-font-red;
        background-color: $validation-background-red;
    }
    &-submit {
        margin: 0;
        padding: 20px;
        color: $validation-font-green;
        background-color: $validation-background-green;
    }
    section {
        @include flex-column;
        position: relative;
        width: 100%;
        max-width: 600px;
        margin-bottom: 20px;
        padding: 15px 15px 10px;
        text-align: left;
        border: 2px solid $sprout-dark-gray-3;
        header {
            position: absolute;
            top: -18px;
            left: 15px;
            padding: 6px 10px;
            background-color: white;
            font-size: 18px;
        }
        label {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 10px;
            margin-bottom: 5px;
            font-size: 14px;
            font-weight: 600;
            input {
                width: 100%;
                height: 30px;
                border: 1px solid $sprout-dark-gray-3;
                border-radius: 3px;
                margin-top: 2px;
            }
        }
        label:has(input[type='checkbox']) {
            flex-direction: row;
            justify-content: flex-start;
            input {
                width: 13px;
                height: 13px;
                margin-left: 5px;
            }
        }
    }
}

.submission-message {
    margin-top: 40px;  
    font-size: 18px;
    color: $validation-font-green;
    font-weight: 700;
}

.opportunity-message {
    font-size: 18px;
    font-weight: 600;
    margin-top: 50px;
}
