$max-width: 1600px !default;
$header-height-sm: 70px;
$header-height-lg: 80px;
$footer-height: 250px;

/// Breakpoints map
$breakpoints: (
    'sm': 600px,
    'md': 769px,
    'lg': 960px,
    'xl': 1200px,
    'max': 1600px
);

/// Relative or absolute URL where all assets are served from
///   $base-url: 'http://cdn.example.com/assets/';
$base-url: '/assets/' !default;

// Sprout Redesign
$sprout-gold: rgb(253, 199, 67);
$gold-2: rgb(246, 201, 90);
$sprout-dark-gold: rgb(252, 187, 24);
$sprout-extra-dark-gold: rgb(211, 151, 0);
$sprout-orange: rgb(251, 155, 18);
$sprout-red: rgb(237, 73, 68);
$sprout-salmon: rgb(236, 82, 88);
$sprout-dark-red: rgb(183, 37, 37);
$sprout-dark-blue: #102d5d;
$sprout-blue: rgb(49, 98, 150);
$sprout-light-blue: rgb(88, 146, 196);
$blue-gray: rgb(176, 193, 212);
$slate-gray: rgb(102, 124, 148);
$light-blue: rgb(194, 217, 242);
$sprout-hyperlink: rgb(0, 147, 201);
$sprout-teal: rgb(131, 206, 199);
$sprout-gray: rgb(245, 245, 245);
$sprout-gray-2: rgb(235, 235, 235);
$sprout-dark-gray: rgb(198, 198, 197);
$sprout-dark-gray-2: rgb(214, 214, 214);
$sprout-dark-gray-3: rgb(166, 166, 166);
$sprout-dark-gray-4: rgb(127, 127, 127);
$sprout-font-gray: rgb(60, 60, 59);
$sprout-execution-purple: rgb(108, 93, 117);
$sprout-performance-green: rgb(92, 134, 104);
$sprout-closure-red: rgb(129, 59, 71);
$iod-gradient: linear-gradient(120deg, #00D8BC, #2E5597);
$ioc-gradient: linear-gradient(120deg, #EABE03, #C60303);
$iob-gradient: linear-gradient(120deg, #A3CE11, #369802);
$iof-gradient: linear-gradient(120deg, #C90C3F, #860FA2);

$blue-semi-circle: rgb(35, 76, 128);
$orange-semi-circle: rgb(250, 138, 11);
$red-semi-circle: rgb(138, 33, 33);
$teal-semi-circle: rgb(99, 186, 177);

$validation-font-green: #006100;
$validation-background-green: #C6EFCE;
$validation-font-red: #9C0006;
$validation-background-red: #FFC7CE;

$a2b-blue: #0466f0;
$a2b-blue-bg: #eff6fe;
$a2b-blue-bg-shadow: #bedcff;
$a2b-orange: #f58229;
$a2b-orange-bg: #fcf6ee;
$a2b-orange-bg-shadow: #ffe2bc;
$a2b-purple: #ba308a;
$a2b-purple-bg: #fef3fc;


// breakpoints
$large-breakpoint: 1600px;
$mid-breakpoint: 900px;
$small-breakpoint: 600px;

/* -------------------------------- 
Fonts
-------------------------------- */
// font-familys
$typeface-montserrat: "Montserrat", Helvetica, Arial, sans-serif;
$typeface-calibri: "Calibri", Helvetica, Arial, sans-serif;
// font-weights
$fontweight-light: 300;
$fontweight-regular: 400;
$fontweight-medium: 500;
$fontweight-semibold: 600;
$fontweight-bold: 700;
$fontweight-extra-bold: 800;
$fontweight-black: 900;
// font-sizes
$hero-fs: (
    null  : (2.5rem, 1.25em),
    'sm' : 3rem,
    'md' : (3.75rem, 1.05em)
);
$h1-fs: (
    null  : (2rem, 1.25em),
    'md' : (2.5rem, 1.125em),
    'lg': (3rem, 1.05em)
);
$h2-fs: (
    null  : (1.625rem, 1.154em),
    'md' : (2rem, 1.25em),
    'lg': 2.25rem
);
$h3-fs: (
    null  : (1.375rem, 1.14em),
    'md' : (1.5rem, 1.25em),
    'lg': 1.75rem
);
$h4-fs: (
    null  : (1.25rem, 1.11em),
    'md' : (1.25rem, 1.22em)
);
$body-large-fs: (
    null  : (1.125rem, 1.375em),
    'md' : 1.125rem,
    'lg': 1.125rem
);
$body-fs: (
    null  : (1rem, 1.25em),
    'md' : (1rem, 1.375em)
);
$caption-fs: (
    null  : .875rem,
    'sm' : .875rem,
    'md' : .875rem,
    'lg': (.875rem, 1.25em)
);
$small-fs: (
    null  : .75rem,
    'sm' : .75rem,
    'md' : .75rem,
    'lg': .75rem
);
