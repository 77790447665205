.semicircle-in-sections {
    // dont display until on larger desktop views
    display: none;
    @include breakpoint('lg'){
        display: block;
        position: absolute;
        right: auto;
        top: 0px;
        right: -30%;
        height: 100%;
        z-index: 0;

        &.teal-on-white {
            svg { fill: $teal-semi-circle; }
            aside { background-color: $teal-semi-circle; }
        }
        &.all-teal {
            background-color: $sprout-teal;
            svg { fill: $teal-semi-circle; }
            aside { background-color: $teal-semi-circle; }
        }
        &.all-white {
            svg { fill: $sprout-gray; }
            aside { background-color: $sprout-gray; }
        }
        &.all-blue {
            background-color: $sprout-blue;
            color: white;
            svg { fill: $blue-semi-circle; }
            aside { background-color: $blue-semi-circle; }
        }
        &.all-yellow {
            background-color: $sprout-gold;
            svg { fill: $orange-semi-circle; }
            aside { background-color: $orange-semi-circle; }
        }
        &.all-gray {
            background-color: $sprout-gray;
            svg { fill: $sprout-dark-gray-2; }
            aside { background-color: $sprout-dark-gray-2; }
        }
        &.all-red {
            background-color: $sprout-dark-red;
            color: white;
            svg { fill: $red-semi-circle; }
            aside { background-color: $red-semi-circle; }
        }
        &.all-orange {
            background-color: $sprout-orange;
            color: white;
            svg { fill: $orange-semi-circle; }
            aside { background-color: $orange-semi-circle; }
        }
    }
}