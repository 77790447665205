// label with span tag and input within
.labeled-input-border {
    position: relative;
    margin-bottom: 20px;

    span {
        color: $sprout-blue;
        position: absolute;
        left: 10px;
        top: -6px;
        padding: 0 5px;
        background-color: white;
    }

    input, textarea {
        padding: 10px;
        width: 100%;
        max-width: 100%;
        min-height: 20px;
    }

    textarea {
        resize: none;
        // min-height: 300px; 
        height: 100%;
        height: 150px;
        overflow: auto;
    }
}
