.blockquote-gray {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 30px 10px;
    blockquote {
        background-color: $sprout-dark-gray-2;
        font-style: normal;
        margin: 0;
        padding: 20px 10px;
    }
    
    @include breakpoint('md'){
        padding: 50px 34px;
        blockquote {
            padding: 47px 40px;
        }
    }
}


.blockquote-large {
    font-weight: bold;
    font-style: italic;
    display: flex;
    justify-content: center;
    margin: 30px 10px;
    &-quote {
        width: 100%;
        max-width: 600px;
        margin: 20px 20px 10px 20px;
    }
    &-quote-left {
        align-self: flex-start;
        color: $sprout-blue;
        font-size: 52px;
    }
    &-quote-right {
        align-self: flex-end;
        color: $sprout-blue;
        font-size: 52px;
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
    }
}