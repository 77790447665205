.modal-tags {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 10px;
    max-width: 800px;
    &-list {
        margin: 0;
        padding: 0;
        li {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            margin-bottom: 8px;
            width: 100%;
            padding: 5px;
            p, input {
                margin: 0;
                max-width: calc(100% - 42px);
            }
            button {
                width: 20px;
                border: none;
                background-color: transparent;
                padding: 0;
            }
            &:nth-child(even) {
                background-color: $sprout-gray-2;
            }
        }
    }
    form {
        width: 100%;
        padding: 5px;
        margin: 0;
        border-top: 1px solid $sprout-gray-2;
        label {
            padding: 10px 0 5px 0;
        }
    }
}

.modal-snapshot {
    display: flex;
    justify-content: center;
    width: 80%;
    background-color: rgb(255, 255, 255);
    border: 1px solid ;
    outline: none;
    color: black;
    // height: 85%;
    border-radius: 5px;
    margin-top: $header-height-sm;

    &-body {
        width: 100%;
        height: 100%;
        header {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            position: relative;
            width: 100%;
            background-color: $sprout-dark-gray-4;
            color: white;
            padding: 8px 20px;
            h3 {
                margin: 0;
            }
            button {
                position: absolute;
                right: 8px;
                top: 8px;
                outline: none;
                border: none;
                background: none;
                color: white;
            }
        }
        
        form {
            display: flex;
            flex-direction: column;
            max-height: 90%;
            padding: 20px;
        }
        .recipient-container {
            padding: .5em;
            margin-bottom: 1em;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            font-size: 12px;
            .recipient-item {
                display: inline-block;
                padding: 4px 5px;
                border-radius: 0px;
                margin: 2px;
                margin-top: -5px;
            }
            .recipient-input {
                flex-grow: 1;
                border: none;
                outline: none;
            }
            .recipient-close {
                height: 20px;
                width: 10px;
                color: $sprout-dark-gray-4;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                font-size: 16px;
                cursor: pointer;
            }
        }
        &-send {
            span {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                small {
                    margin: 15px 0 0 0;
                }
            }
            @include breakpoint('sm'){
                span {
                    flex-direction: row;
                    align-items: center;
                    small {
                        margin: 0 0 0 10px;
                    }
                }
            }
        }
    }
}

.modal-opportunity {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    width: 60%;
    background-color: rgb(255, 255, 255);
    border: 3px solid $sprout-blue;
    height: 60%;
    border-radius: 5px;
    margin-top: 150px;
    margin-left: 270px;
    p {
        flex-direction: column;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $validation-font-green;
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 20px;
        margin-top: 5px;
    }
    a {
        margin-top: 20px;
        display: block;
        width: 115px;
        height: 30px;
        background: $sprout-blue;
        padding: 6px;
        text-align: center;
        border-radius: 5px;
        color: white;
        font-weight: 600;
        text-decoration: none;
    }
}
